import styled from '@emotion/styled';

import theme from 'src/styles/theme';
import { APP } from 'src/styles/variables';
import IconButton from '@components/buttons/icon-button/IconButton';
import TextButton from '@components/buttons/text-button/TextButton';

export const TabsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
  margin-left: 1.875rem;
  height: 100%;
`;

export const TabItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props =>
    props.isActive
      ? props.theme.palette.common.black
      : APP.PALETTE.GREY.TERTIARY_COLOR};
  border-bottom: ${props =>
    props.isActive
      ? `6px solid ${props.theme.palette.secondary.main}`
      : 'none'};
  font-weight: ${APP.FONT.FONT_WEIGHT.BUTTON};
  font-size: 1rem;
  font-family: ${APP.FONT.FONT_FAMILY.PRIMARY};

  &:focus-visible {
    outline: none;
    border-bottom: ${props =>
      props.isActive
        ? `6px solid ${props.theme.palette.secondary.main}`
        : 'none'};
  }

  &:active {
    outline: none;
    border-bottom: ${props =>
      props.isActive
        ? `6px solid ${props.theme.palette.secondary.main}`
        : 'none'};
  }

  &:hover {
    outline: none;
    /* border-bottom: ${`6px solid ${theme.palette.secondary.main}`}; */
    color: ${theme.palette.common.black};
    background-color: ${props =>
      props.isActive ? 'none' : APP.PALETTE.GREY.LIGHT_GREY};
  }

  position: relative;
`;

export const TabItemText = styled.div<{ isActive: boolean }>`
  position: relative;
  top: ${props => (props.isActive ? `3px` : '0')};
`;

export const OptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

export const FilterIconButton = styled(IconButton)`
  padding: 0.125rem;
  cursor: pointer;
`;

export const BulkEditTextButton = styled(TextButton)`
  background-color: ${APP.PALETTE.SECONDARY.MAIN};
  border-color: ${APP.PALETTE.SECONDARY.MAIN};

  &:hover {
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
    border-color: ${APP.PALETTE.SECONDARY.MAIN};
  }
`;

export const SettingsTabMenuBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const RefreshButtonStyledDiv = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

export const RefreshButtonStyled = styled(TextButton)<{ disabled: boolean }>`
  background-color: ${APP.PALETTE.SECONDARY.MAIN};
  border-color: ${APP.PALETTE.SECONDARY.MAIN};

  &:hover {
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
    border-color: ${APP.PALETTE.SECONDARY.MAIN};
  }

  &.Mui-disabled {
    background-color: ${APP.PALETTE.SECONDARY.MAIN};
    pointer-events: auto;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;
