import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  EmailSettingsSearchAndFilterPayloadType,
  EmailSettingsStateType,
  SetIsBulkEditNeededPayloadType,
  SetIsEmailSettingsBulkEditButtonClickedPayloadType,
  SetIsEmailSettingsFilterButtonClickedPayloadType,
} from './EmailSettingsTypes';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';

const initialState: EmailSettingsStateType = {
  emailSettingsSearchAndFilterOptions: {},
  emailSettingsTableName:
    AdminConstants.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE_NAME_WITHOUT_FILTER,
  isBulkEditNeeded: false,
  isEmailSettingsFilterButtonClicked: false,
  isEmailSettingsBulkEditButtonClicked: false,
  updateReprocessDetails: {},
};

const EmailSettingsSlice = createSlice({
  initialState,
  name: 'emailSettings',
  reducers: {
    setEmailSettingsSearchAndFilterValues(
      state,
      action: PayloadAction<EmailSettingsSearchAndFilterPayloadType>,
    ) {
      state.emailSettingsSearchAndFilterOptions = action.payload.data;
    },
    changeEmailSettingsTableName(state) {
      state.emailSettingsTableName = generateRandomTableName(
        AdminConstants.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE_WITH_FILTERS,
      );
    },
    setIsBulkEditNeeded(
      state,
      action: PayloadAction<SetIsBulkEditNeededPayloadType>,
    ) {
      state.isBulkEditNeeded = action.payload.isBulkEditNeededPayload;
    },
    setIsEmailSettingsFilterButtonClicked(
      state,
      action: PayloadAction<SetIsEmailSettingsFilterButtonClickedPayloadType>,
    ) {
      state.isEmailSettingsFilterButtonClicked =
        action.payload.isEmailSettingsFilterButtonClickedPayload;
    },
    setIsEmailSettingsBulkEditButtonClicked(
      state,
      action: PayloadAction<SetIsEmailSettingsBulkEditButtonClickedPayloadType>,
    ) {
      state.isEmailSettingsBulkEditButtonClicked =
        action.payload.isEmailSettingsBulkEditButtonClickedPayload;
    },
    updateReprocessDetails(
      state,
      action: PayloadAction<{
        rowId: string;
        isReprocessing?: boolean;
        reprocessedAt?: string;
        status?: string;
      }>,
    ) {
      const { rowId, isReprocessing, reprocessedAt, status } = action.payload;

      if (!state.updateReprocessDetails) {
        state.updateReprocessDetails = {};
      }
      if (!state.updateReprocessDetails[rowId]) {
        state.updateReprocessDetails[rowId] = {};
      }
      if (isReprocessing !== undefined) {
        state.updateReprocessDetails[rowId].isReprocessing = isReprocessing;
      }
      if (reprocessedAt !== undefined) {
        state.updateReprocessDetails[rowId].reprocessedAt = reprocessedAt;
      }
      if (status !== undefined) {
        state.updateReprocessDetails[rowId].status = status;
      }
    },
  },
});

export const {
  setEmailSettingsSearchAndFilterValues,
  changeEmailSettingsTableName,
  setIsBulkEditNeeded,
  setIsEmailSettingsFilterButtonClicked,
  setIsEmailSettingsBulkEditButtonClicked,
  updateReprocessDetails,
} = EmailSettingsSlice.actions;

export default EmailSettingsSlice.reducer;
