import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { t } from 'i18next';
import { ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SettingsTabListItemType } from 'src/types/adminTypes';
import {
  BulkEditTextButton,
  FilterIconButton,
  OptionButtonContainer,
  RefreshButtonStyled,
  RefreshButtonStyledDiv,
  SettingsTabMenuBarContainer,
  TabItem,
  TabItemText,
  TabsContainer,
} from './SettingsStyle';
import { produce } from 'immer';
import { isProductionEnvironment } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import {
  setIsEmailSettingsBulkEditButtonClicked,
  setIsEmailSettingsFilterButtonClicked,
} from '@modules/home/state/email-settings/EmailSettingsSlice';
import {
  SetIsEmailSettingsBulkEditButtonClickedPayloadType,
  SetIsEmailSettingsFilterButtonClickedPayloadType,
} from '@modules/home/state/email-settings/EmailSettingsTypes';
import Filter from '@assets/images/Filter.svg';
import { changeAutomationSettingsTableName } from '@modules/home/state/automation-settings/AutomationSettingsSlice';

type SettingsStateType = {
  settingsTabList: SettingsTabListItemType[];
};

const Settings = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isBulkEditNeeded } = useSelector(
    (reduxState: RootState) => reduxState.emailSettings,
  );

  const { isAutomationSettingsDataLoading } = useSelector(
    (reduxState: RootState) => reduxState.automationSettings,
  );

  const isEmailSettingsDetailsScreen = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return pathParts.includes(
      AdminConstants.EMAIL_SETTINGS.EMAIL_SETTING_DETAILS.PATHNAME,
    );
  };

  const emailSettingsTabName = t('ADMIN.SETTINGS_TAB.EMAIL_SETTINGS.TAB_NAME');
  const automationSettingsTabName = t(
    'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.TAB_NAME',
  );

  const isEmailSettingsTab =
    location.pathname ===
    PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
      .EmailSettingsTable.FullPath;

  const isAutomationSettingsTab =
    location.pathname ===
    PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
      .AutomationSettings.FullPath;

  const settingsTabList: SettingsTabListItemType[] = [
    ...(!isProductionEnvironment()
      ? []
      : [
          {
            name: emailSettingsTabName,
            isActive:
              location.pathname ===
              PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
                .EmailSettingsTable.FullPath,
            navigationURL:
              PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
                .EmailSettingsTable.Path,
          },
        ]),
    {
      name: automationSettingsTabName,
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .AutomationSettings.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .AutomationSettings.Path,
    },
  ];

  const initialSettingsState: SettingsStateType = {
    settingsTabList: settingsTabList,
  };

  const [settingsState, setSettingsState] = useState(initialSettingsState);

  const setCurrentActiveTab = (tabItemName: string) => {
    const newSettingsTabList = settingsTabList.map(
      (settingsTabListItem: SettingsTabListItemType) => ({
        ...settingsTabListItem,
        isActive:
          settingsTabListItem.name.toLowerCase() === tabItemName.toLowerCase(),
      }),
    );
    setSettingsState(
      produce(draft => {
        draft.settingsTabList = newSettingsTabList;
      }),
    );
  };

  useEffect(() => {
    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .EmailSettingsTable.Path,
      )
    ) {
      setCurrentActiveTab(emailSettingsTabName);
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .AutomationSettings.Path,
      )
    ) {
      setCurrentActiveTab(automationSettingsTabName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderSettingsTabListItem = (
    settingsTabListItem: SettingsTabListItemType,
    index: number,
  ) => (
    <TabItem
      key={`${settingsTabListItem.name}-${index}`}
      isActive={settingsTabListItem.isActive}
      onClick={() => {
        setCurrentActiveTab(settingsTabListItem.name);
        navigate(settingsTabListItem.navigationURL, {
          replace: true,
        });
      }}
    >
      <TabItemText isActive={settingsTabListItem.isActive}>
        {settingsTabListItem.name}
      </TabItemText>
    </TabItem>
  );

  const handleEmailSettingsFilterButtonClick = () => {
    const setIsEmailSettingsFilterButtonClickedPayload: SetIsEmailSettingsFilterButtonClickedPayloadType =
      {
        isEmailSettingsFilterButtonClickedPayload: true,
      };
    dispatch(
      setIsEmailSettingsFilterButtonClicked(
        setIsEmailSettingsFilterButtonClickedPayload,
      ),
    );
  };

  const handleEmailSettingsBulkEditButtonClick = () => {
    const setIsEmailSettingsBulkEditButtonClickedPayload: SetIsEmailSettingsBulkEditButtonClickedPayloadType =
      {
        isEmailSettingsBulkEditButtonClickedPayload: true,
      };
    dispatch(
      setIsEmailSettingsBulkEditButtonClicked(
        setIsEmailSettingsBulkEditButtonClickedPayload,
      ),
    );
  };

  const handleRefreshButtonClick = () => {
    dispatch(changeAutomationSettingsTableName());
  };

  const renderEmailSettingsTopBarRightSection = () => {
    return (
      <OptionButtonContainer>
        {!isBulkEditNeeded ? (
          <FilterIconButton onClick={handleEmailSettingsFilterButtonClick}>
            <img src={Filter} height={36} width={36} alt="..." />
          </FilterIconButton>
        ) : (
          <BulkEditTextButton
            variant="contained"
            onClick={handleEmailSettingsBulkEditButtonClick}
          >
            {t('PRIVATE.EMAILS.EDIT_EMAILS')}
          </BulkEditTextButton>
        )}
      </OptionButtonContainer>
    );
  };

  const renderRefreshButton = () => {
    const isRefreshButtonDisabled = isAutomationSettingsDataLoading;
    const refreshButtonText = isRefreshButtonDisabled
      ? 'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.REFRESHING'
      : 'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.REFRESH';

    return (
      <RefreshButtonStyledDiv>
        <RefreshButtonStyled
          disabled={isRefreshButtonDisabled}
          variant="contained"
          onClick={
            !isRefreshButtonDisabled ? handleRefreshButtonClick : undefined
          }
        >
          {t(refreshButtonText)}
        </RefreshButtonStyled>
      </RefreshButtonStyledDiv>
    );
  };

  const renderSettingsTabsMenu = () => (
    <SettingsTabMenuBarContainer>
      <TabsContainer>
        {settingsState.settingsTabList.map((settingsTabListItem, index) =>
          renderSettingsTabListItem(settingsTabListItem, index),
        )}
      </TabsContainer>
      {isEmailSettingsTab ? renderEmailSettingsTopBarRightSection() : null}
      {isAutomationSettingsTab ? renderRefreshButton() : null}
    </SettingsTabMenuBarContainer>
  );

  const renderSelectedTabBody = () => <Outlet />;

  return (
    <>
      {!isEmailSettingsDetailsScreen() ? (
        <TopBarWrapperGrid
          height="5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          {renderSettingsTabsMenu()}
        </TopBarWrapperGrid>
      ) : null}
      {renderSelectedTabBody()}
    </>
  );
};

export default Settings;
