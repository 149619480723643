import ServiceRequest from 'src/services/ServiceRequest';

import {
  getAllFlagReasonsUrl,
  getAllStatesUrl,
  getAllTaskStatusesUrl,
  getAllTaskTypesUrl,
} from './RefDataApiEndPoints';

import {
  GetAllFlagReasonsResponseData,
  GetAllStatesQueryParam,
  GetAllStatesResDto,
  GetAllTaskStatusesQueryParam,
  GetAllTaskStatusesResponseData,
  GetAllTaskTypesQueryParam,
  GetAllTaskTypesResponseData,
} from './RefDataServiceMapper';

import { CancelTokenSource } from 'axios';

export const getAllStates = async (
  data?: GetAllStatesQueryParam,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllStatesResDto> => {
  return await ServiceRequest.get(
    getAllStatesUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const getAllTaskTypes = async (
  data?: GetAllTaskTypesQueryParam,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllTaskTypesResponseData> => {
  const defaultData = {
    includeNonEmailTypes: true,
    ...data,
  };
  return await ServiceRequest.get(
    getAllTaskTypesUrl,
    {
      payload: defaultData,
    },
    cancelTokenSource,
  );
};

export const getAllTaskStatuses = async (
  data?: GetAllTaskStatusesQueryParam,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllTaskStatusesResponseData> => {
  return await ServiceRequest.get(
    getAllTaskStatusesUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const getAllFlagReasons =
  async (): Promise<GetAllFlagReasonsResponseData> => {
    return await ServiceRequest.get(getAllFlagReasonsUrl);
  };
