import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import {
  MRT_RowData as MRTRowData,
  MRT_SortingState as MRTSortingState,
  MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { Box, Tooltip } from '@mui/material';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getErrorMessage } from '@utils/ErrorUtils';
import { ERROR_CONSTANTS } from '@constants/global-constants/constants';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { NoDataFoundDivStyled } from 'src/styles/common/CommonStyles';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import Loader from '@components/loaders/Loader';
import {
  ActionButtonsContainer,
  AutomationPauseButtonStyled,
  OverlayLoaderContainerForTaskTypeAutomation,
  ReprocessButtonStyled,
  InProgressButtonStyled,
  RowPauseStatusDetailsStyleDiv,
  RowStyleDiv,
  TypeCellSubTextStyle,
  LastReprocessedDetailsStyleDiv,
  ReprocessValuesStyleDiv,
  PausedChangedAtDetailsStyleDiv,
  PausedChangedAtValuesStyleDiv,
  ReprocessedAtValuesStyleDiv,
} from './AutomationSettingsStyle';
import { getAllTaskTypes } from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import { reprocessAutomatedTask } from 'src/services/service-handlers/private/tasks-handlers/TasksPrivateService';
import { updateTaskTypeAutomationPause } from 'src/services/service-handlers/private/task-type-handlers/TaskTypePrivateService';
import {
  changeAutomationSettingsTableName,
  setIsAutomationSettingsDataLoading,
  setIsRefetchingNeeded,
} from '@modules/home/state/automation-settings/AutomationSettingsSlice';
import AutomationPauseConfirmationDialog from './automation-pause-confirmation-dialog/AutomationPauseConfirmationDialog';
import { GetAllTaskTypesQueryParam } from 'src/services/service-handlers/private/ref-data-handlers/RefDataServiceMapper';
import ReprocessTasksConfirmationDialog from './reprocess-tasks-confirmation-dialog/ReprocessTasksConfirmationDialog';
import { updateReprocessDetails } from '@modules/home/state/email-settings/EmailSettingsSlice';
import axios, { CancelTokenSource } from 'axios';
import { formatDateAndTimeWithTimeZone } from '@utils/miscellaneousUtils';
import { SetIsAutomationSettingsDataLoadingPayloadType } from '@modules/home/state/automation-settings/AutomationSettingsTypes';
interface IAutomationSettingsTableState {
  isLoading: boolean;
  hoveredRow: string;
  disableHoverEffect: boolean;
  totalRowCount: number;
  networkErrorState: boolean;
  isReprocessingTasksInProgress: boolean;
  isAutomationTaskConfirmationDialogOpen: boolean;
  isReprocessTasksConfirmationDialogOpen: boolean;
  clickedRowTaskTypeId: string;
  isPausedTask: boolean;
  isAutomationInProgress: boolean;
  rowTaskType: string;
  isTaskTypeDetailsLoading: boolean;
}

const initialAutomationSettingsTableState: IAutomationSettingsTableState = {
  isLoading: true,
  hoveredRow: '',
  disableHoverEffect: false,
  totalRowCount: 0,
  networkErrorState: false,
  isReprocessingTasksInProgress: false,
  isAutomationTaskConfirmationDialogOpen: false,
  isReprocessTasksConfirmationDialogOpen: false,
  clickedRowTaskTypeId: '',
  isPausedTask: false,
  isAutomationInProgress: false,
  rowTaskType: '',
  isTaskTypeDetailsLoading: false,
};

interface IAutomationSettingsProps {
  isUserPortal?: boolean;
}

interface TaskTypeRow {
  taskCategory: string;
  taskType: string;
  taskTypeId: string;
  isPaused: boolean;
  isReprocessing: boolean;
  lastReprocessDetails: {
    status: string;
    reprocessedAt: string;
  };
  pauseUpdatedOn: string;
}

const AutomationSettings = (props: IAutomationSettingsProps): ReactElement => {
  const { isUserPortal = false } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [automationSettingsTableState, setAutomationSettingsTableState] =
    useState<IAutomationSettingsTableState>(
      initialAutomationSettingsTableState,
    );

  const dispatch = useDispatch();

  const {
    isLoading,
    totalRowCount,
    disableHoverEffect,
    networkErrorState,
    isReprocessingTasksInProgress,
    isAutomationTaskConfirmationDialogOpen,
    isReprocessTasksConfirmationDialogOpen,
    clickedRowTaskTypeId,
    isPausedTask,
    isAutomationInProgress,
    rowTaskType,
  } = automationSettingsTableState;

  const { automationSettingsTableName, isRefetchingNeeded } = useSelector(
    (state: RootState) => state.automationSettings,
  );

  // API region starts

  const cancelTokenSourceForTableData = useRef<CancelTokenSource | null>(null);

  const fetchData = async (
    pageParam: number,
    fetchSize: number,
    sorting?: MRTSortingState,
  ) => {
    cancelTokenSourceForTableData.current = axios.CancelToken.source();

    try {
      setAutomationSettingsTableState(
        produce(draft => {
          draft.isLoading = true;
        }),
      );

      const payload: GetAllTaskTypesQueryParam = {
        includeNonEmailTypes: true,
        isAutomated: true,
      };

      const taskTypesWithCategoryResponse = await getAllTaskTypes(
        payload,
        cancelTokenSourceForTableData.current,
      );
      const taskTypeWithCategoryData =
        taskTypesWithCategoryResponse?.data?.rows ?? [];

      const dataRows: TaskTypeRow[] = [];
      taskTypeWithCategoryData.forEach(taskTypeWithCategoryDataListItem => {
        taskTypeWithCategoryDataListItem?.taskTypes?.forEach(taskTypeList => {
          dataRows.push({
            taskCategory: taskTypeWithCategoryDataListItem.categoryLabel,
            taskType: taskTypeList.taskLabel,
            taskTypeId: taskTypeList.taskId,
            isPaused: taskTypeList.isPaused,
            isReprocessing: taskTypeList.isReprocessing,
            lastReprocessDetails: {
              reprocessedAt: taskTypeList?.lastReprocessDetails?.reprocessedAt,
              status: taskTypeList?.lastReprocessDetails?.status,
            },
            pauseUpdatedOn: taskTypeList.pauseUpdatedOn,
          });
        });
      });

      setAutomationSettingsTableState(
        produce(draft => {
          draft.totalRowCount = dataRows.length;
          draft.isLoading = false;
          draft.networkErrorState = false;
        }),
      );
      return dataRows;
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      if (e.code === ERROR_CONSTANTS.ERR_NETWORK || e.statusCode === 500) {
        setAutomationSettingsTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setAutomationSettingsTableState(
        produce(draft => {
          draft.totalRowCount = 0;
          draft.isLoading = false;
        }),
      );
    } finally {
      cancelTokenSourceForTableData.current = null;
    }
  };

  useEffect(() => {
    const payload: SetIsAutomationSettingsDataLoadingPayloadType = {
      isAutomationSettingsDataLoadingPayload: isLoading,
    };
    dispatch(setIsAutomationSettingsDataLoading(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const cancelTokenSourceForTaskTypeByIdRef = useRef<CancelTokenSource | null>(
    null,
  );

  const fetchTaskTypeDetailsById = async () => {
    cancelTokenSourceForTaskTypeByIdRef.current = axios.CancelToken.source();
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isTaskTypeDetailsLoading = true;
      }),
    );
    try {
      const payload: GetAllTaskTypesQueryParam = {
        includeNonEmailTypes: true,
        isAutomated: true,
        taskTypeId: clickedRowTaskTypeId,
      };

      const taskTypesWithCategoryResponse = await getAllTaskTypes(
        payload,
        cancelTokenSourceForTaskTypeByIdRef.current,
      );
      const taskTypeWithCategoryData =
        taskTypesWithCategoryResponse?.data?.rows ?? [];

      const formattedTaskTypeDataRows = taskTypeWithCategoryData
        .map(row => {
          if (row.taskTypes) {
            const taskTypeData = row.taskTypes.find(
              task => task.taskId === clickedRowTaskTypeId,
            );
            if (taskTypeData) {
              return {
                taskCategory: row.categoryLabel || '',
                taskType: taskTypeData.taskType,
                taskTypeId: taskTypeData.taskId,
                isPaused: taskTypeData.isPaused,
                isReprocessing: taskTypeData.isReprocessing,
                lastReprocessDetails: {
                  reprocessedAt:
                    taskTypeData?.lastReprocessDetails?.reprocessedAt,
                  status: taskTypeData?.lastReprocessDetails?.status,
                },
                pauseUpdatedOn: taskTypeData.pauseUpdatedOn,
              };
            }
          }
          return null;
        })
        .filter(row => row !== null);

      const taskTypeDataRow: TaskTypeRow = formattedTaskTypeDataRows[0] || {
        taskCategory: '',
        taskType: '',
        taskTypeId: '',
        isPaused: false,
        isReprocessing: false,
        lastReprocessDetails: {
          status: '',
          reprocessedAt: '',
        },
        pauseUpdatedOn: '',
      };

      if (taskTypeDataRow) {
        const updatedReprocessDetails = {
          rowId: clickedRowTaskTypeId,
          isReprocessing: taskTypeDataRow?.isReprocessing,
          reprocessedAt: taskTypeDataRow?.lastReprocessDetails?.reprocessedAt,
          status: taskTypeDataRow?.lastReprocessDetails?.status,
        };

        dispatch(updateReprocessDetails(updatedReprocessDetails));
      }
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      if (e.code === ERROR_CONSTANTS.ERR_NETWORK || e.statusCode === 500) {
        setAutomationSettingsTableState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      setAutomationSettingsTableState(
        produce(draft => {
          draft.totalRowCount = 0;
          draft.isTaskTypeDetailsLoading = false;
        }),
      );
    } finally {
      cancelTokenSourceForTaskTypeByIdRef.current = null;
    }
  };

  const handleAutomationPauseSubmitButtonClick = async () => {
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isAutomationInProgress = true;
      }),
    );
    try {
      const res = await updateTaskTypeAutomationPause(clickedRowTaskTypeId);
      enqueueSnackbar(res.data.message, SnackBarConfig.getSuccess());
      setAutomationSettingsTableState(
        produce(draft => {
          draft.isAutomationTaskConfirmationDialogOpen = false;
          draft.clickedRowTaskTypeId = '';
        }),
      );
      dispatch(setIsRefetchingNeeded({ isRefetchingNeededPayload: true }));
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setAutomationSettingsTableState(
        produce(draft => {
          draft.isAutomationInProgress = false;
        }),
      );
    }
  };

  const handleReprocessTasksSubmitButtonClick = async () => {
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isReprocessingTasksInProgress = true;
        draft.isReprocessTasksConfirmationDialogOpen = false;
      }),
    );
    dispatch(
      updateReprocessDetails({
        rowId: clickedRowTaskTypeId,
        isReprocessing: true,
      }),
    );
    try {
      const res = await reprocessAutomatedTask(clickedRowTaskTypeId);
      enqueueSnackbar(res?.data?.message, SnackBarConfig.getSuccess());
      setAutomationSettingsTableState(
        produce(draft => {
          draft.clickedRowTaskTypeId = '';
        }),
      );
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setAutomationSettingsTableState(
        produce(draft => {
          draft.isReprocessingTasksInProgress = false;
        }),
      );
      dispatch(
        updateReprocessDetails({
          rowId: clickedRowTaskTypeId,
          isReprocessing: false,
        }),
      );
      await fetchTaskTypeDetailsById();
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForTableData.current) {
        cancelTokenSourceForTableData.current.cancel();
      }
      if (cancelTokenSourceForTaskTypeByIdRef.current) {
        cancelTokenSourceForTaskTypeByIdRef.current.cancel();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API region ends

  useEffect(() => {
    if (isRefetchingNeeded) {
      dispatch(setIsRefetchingNeeded({ isRefetchingNeededPayload: false }));
      dispatch(changeAutomationSettingsTableName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchingNeeded]);

  const renderHeaderWithTooltip = (column: any) => {
    return (
      <Tooltip title={`${column.columnDef.header}`}>
        <div>{column.columnDef.header}</div>
      </Tooltip>
    );
  };

  const renderAutomationTaskStatusCellValue = (rowData: {
    isPaused: boolean;
    pauseUpdatedOn: Date;
  }) => {
    const { isPaused, pauseUpdatedOn } = rowData;
    const formattedPauseUpdatedOn = pauseUpdatedOn
      ? formatDateAndTimeWithTimeZone(pauseUpdatedOn)
      : '-';

    return (
      <RowPauseStatusDetailsStyleDiv>
        <RowStyleDiv>
          {isPaused
            ? t(
                'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.PAUSE',
              )
            : t(
                'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.UN_PAUSE',
              )}
        </RowStyleDiv>
        <PausedChangedAtDetailsStyleDiv>
          <PausedChangedAtValuesStyleDiv>
            {formattedPauseUpdatedOn}
          </PausedChangedAtValuesStyleDiv>
        </PausedChangedAtDetailsStyleDiv>
      </RowPauseStatusDetailsStyleDiv>
    );
  };

  const renderTaskTypes = (rowData: {
    taskCategory: string;
    taskType: string;
  }) => {
    const { taskCategory, taskType } = rowData;
    return (
      <div>
        <RowStyleDiv>{taskType}</RowStyleDiv>
        <TypeCellSubTextStyle>
          {taskCategory.length > 0 ? `(${taskCategory})` : ''}
        </TypeCellSubTextStyle>
      </div>
    );
  };

  // automation pause region starts

  const handleAutomationPauseConfirmBackDialogCancelClick = () => {
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isAutomationTaskConfirmationDialogOpen = false;
        draft.clickedRowTaskTypeId = '';
        draft.rowTaskType = '';
      }),
    );
  };

  const renderTaskPauseConfirmationDialog = () => {
    if (isAutomationTaskConfirmationDialogOpen) {
      return (
        <AutomationPauseConfirmationDialog
          isDialogOpen={isAutomationTaskConfirmationDialogOpen}
          onSubmitCloseDialog={handleAutomationPauseSubmitButtonClick}
          onCancel={handleAutomationPauseConfirmBackDialogCancelClick}
          isPaused={isPausedTask}
          isAutomationInProgress={isAutomationInProgress}
          taskType={rowTaskType}
        />
      );
    }
    return null;
  };

  const handleTaskTypeAutomationPauseButtonClick = (
    taskTypeId: string,
    isPaused: boolean,
    taskType: string,
  ) => {
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isAutomationTaskConfirmationDialogOpen = true;
        draft.clickedRowTaskTypeId = taskTypeId;
        draft.isPausedTask = isPaused;
        draft.rowTaskType = taskType;
      }),
    );
  };

  const renderTaskAutomationActionButton = ({
    isPaused,
    taskTypeId,
    taskType,
    isReprocessing,
  }: {
    isPaused: boolean;
    taskTypeId: string;
    taskType: string;
    isReprocessing: boolean;
  }) => {
    return (
      <div>
        <AutomationPauseButtonStyled
          variant="outlined"
          onClick={() =>
            handleTaskTypeAutomationPauseButtonClick(
              taskTypeId,
              isPaused,
              taskType,
            )
          }
          disabled={isReprocessing}
        >
          {isPaused ? (
            <span>
              {t(
                'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.UNPAUSE_TASK_BUTTON',
              )}
            </span>
          ) : (
            <span>
              {t(
                'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.PAUSE_TASK_BUTTON',
              )}
            </span>
          )}
        </AutomationPauseButtonStyled>
      </div>
    );
  };

  // automation pause region ends

  // reprocess tasks region starts

  const handleReprocessTasksConfirmBackDialogCancelClick = () => {
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isReprocessTasksConfirmationDialogOpen = false;
        draft.clickedRowTaskTypeId = '';
        draft.rowTaskType = '';
      }),
    );
  };

  const renderReprocessTasksConfirmationDialog = () => {
    if (isReprocessTasksConfirmationDialogOpen) {
      return (
        <ReprocessTasksConfirmationDialog
          isDialogOpen={isReprocessTasksConfirmationDialogOpen}
          onSubmitCloseDialog={handleReprocessTasksSubmitButtonClick}
          onCancel={handleReprocessTasksConfirmBackDialogCancelClick}
          isReprocessingTasksInProgress={isReprocessingTasksInProgress}
          taskType={rowTaskType}
        />
      );
    }
    return null;
  };

  const handleReprocessButtonClick = (
    taskTypeId: string,
    isPaused: boolean,
    taskType: string,
  ) => {
    setAutomationSettingsTableState(
      produce(draft => {
        draft.isReprocessTasksConfirmationDialogOpen = true;
        draft.clickedRowTaskTypeId = taskTypeId;
        draft.rowTaskType = taskType;
      }),
    );
  };

  const renderReprocessButton = ({
    isPaused,
    taskTypeId,
    taskType,
    isReprocessing,
  }: {
    isPaused: boolean;
    taskTypeId: string;
    taskType: string;
    isReprocessing?: boolean;
  }) =>
    isReprocessing ? (
      <InProgressButtonStyled disabled={isReprocessing} variant="outlined">
        <span>
          {t(
            'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.IN_PROGRESS',
          )}
        </span>
      </InProgressButtonStyled>
    ) : (
      <ReprocessButtonStyled
        disabled={isPaused}
        variant="outlined"
        onClick={() =>
          handleReprocessButtonClick(taskTypeId, isPaused, taskType)
        }
      >
        <span>
          {t(
            'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.RE_PROCESS',
          )}
        </span>
      </ReprocessButtonStyled>
    );

  const renderReprocessButtonWithToolTip = ({
    isPaused,
    taskTypeId,
    taskType,
    isReprocessing,
  }: {
    isPaused: boolean;
    taskTypeId: string;
    taskType: string;
    isReprocessing: boolean;
  }) => {
    let toolTipText = '';

    if (isPaused) {
      toolTipText = t(
        'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.RE_PROCESS_ERROR_FOR_PAUSED_TASK_TYPES',
      );
    } else if (isReprocessing) {
      toolTipText = t(
        'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.RE_PROCESS_IN_PROGRESS_FOR_PAUSED_TASK_TYPES',
      );
    }

    if (isPaused || isReprocessing) {
      return (
        <Tooltip title={toolTipText}>
          {renderReprocessButton({
            isPaused,
            taskTypeId,
            taskType,
            isReprocessing,
          })}
        </Tooltip>
      );
    } else {
      return renderReprocessButton({
        isPaused,
        taskTypeId,
        taskType,
        isReprocessing,
      });
    }
  };

  const renderTaskAutomationActionWithReprocessButton = ({
    taskTypeId,
    isPaused,
    taskType,
    isReprocessing,
  }: {
    taskTypeId: string;
    isPaused: boolean;
    taskType: string;
    isReprocessing: boolean;
  }) => {
    return (
      <ActionButtonsContainer>
        {renderTaskAutomationActionButton({
          isPaused,
          taskTypeId,
          taskType,
          isReprocessing,
        })}
        {renderReprocessButtonWithToolTip({
          isPaused,
          taskTypeId,
          taskType,
          isReprocessing,
        })}
      </ActionButtonsContainer>
    );
  };

  const renderLastReprocessDetails = ({
    reprocessedAt,
    status,
  }: {
    reprocessedAt: Date;
    status: string;
  }) => {
    const formattedDate = reprocessedAt
      ? formatDateAndTimeWithTimeZone(reprocessedAt)
      : '-';

    return (
      <div>
        <LastReprocessedDetailsStyleDiv>
          <ReprocessValuesStyleDiv>{status ?? '-'}</ReprocessValuesStyleDiv>
        </LastReprocessedDetailsStyleDiv>

        <LastReprocessedDetailsStyleDiv>
          <ReprocessedAtValuesStyleDiv>
            {formattedDate}
          </ReprocessedAtValuesStyleDiv>
        </LastReprocessedDetailsStyleDiv>
      </div>
    );
  };

  const columnsData: MRTColumnDef<MRTRowData>[] = useMemo(
    () => {
      const baseColumns: MRTColumnDef<MRTRowData>[] = [
        {
          accessorKey:
            AdminConstants.AUTOMATION_SETTINGS.COLUMN_ACCESSOR_KEY.TASK_TYPE,
          header: t(
            'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.TASK_TYPES',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            taskCategory: row?.taskCategory ?? '',
            taskType: row?.taskType ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderTaskTypes(renderedCellValue),
          grow: true,
          enableSorting: false,
        },
        {
          accessorKey:
            AdminConstants.AUTOMATION_SETTINGS.COLUMN_ACCESSOR_KEY.IS_PAUSED,
          header: t(
            'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.TASK_AUTOMATION_STATUS',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            isPaused: row?.isPaused,
            pauseUpdatedOn: row?.pauseUpdatedOn ?? '',
          }),
          Cell: ({ renderedCellValue }: any) =>
            renderAutomationTaskStatusCellValue(renderedCellValue),
          grow: true,
          enableSorting: false,
        },
        {
          accessorKey:
            AdminConstants.AUTOMATION_SETTINGS.COLUMN_ACCESSOR_KEY
              .TASK_AUTOMATED_ACTION,
          header: t(
            'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.TASK_AUTOMATION_ACTION',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            isPaused: row?.isPaused,
            taskTypeId: row?.taskTypeId ?? '',
            taskType: row?.taskType ?? '',
            isReprocessing: row?.isReprocessing ?? '',
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderTaskAutomationActionWithReprocessButton(renderedCellValue),
          grow: true,
          enableSorting: false,
        },
        {
          accessorKey:
            AdminConstants.AUTOMATION_SETTINGS.COLUMN_ACCESSOR_KEY
              .LAST_REPROCESS_STATUS,
          header: t(
            'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.LAST_REPROCESS_STATUS',
          ),
          Header: ({ column }: any) => renderHeaderWithTooltip(column),
          accessorFn: row => ({
            reprocessedAt: row?.lastReprocessDetails.reprocessedAt,
            status: row?.lastReprocessDetails.status,
          }),
          Cell: ({ renderedCellValue, row }: any) =>
            renderLastReprocessDetails(renderedCellValue),
          grow: true,
          enableSorting: false,
        },
      ];
      return baseColumns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Box>
      {isAutomationInProgress ? (
        <OverlayLoaderContainerForTaskTypeAutomation>
          <Loader size={40} />
        </OverlayLoaderContainerForTaskTypeAutomation>
      ) : null}
      {renderTaskPauseConfirmationDialog()}
      {renderReprocessTasksConfirmationDialog()}
      {networkErrorState ? (
        <NoDataFoundDivStyled>{t('NO_DATA_FOUND')}</NoDataFoundDivStyled>
      ) : (
        <ServerSideDataGrid
          isUserPortal={isUserPortal}
          fetchDataFnQueryKeyName={automationSettingsTableName}
          fetchDataFn={fetchData}
          dataFetchSize={
            AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT
          }
          totalRowsCount={totalRowCount}
          columns={columnsData}
          state={{ isLoading }}
          enableBottomToolbar={false}
          muiTableBodyRowProps={({ row }) => {
            return {
              onMouseEnter: () => {
                if (disableHoverEffect) return;
                setAutomationSettingsTableState(
                  produce(draft => {
                    draft.hoveredRow = row?.original?.taskTypeId || '';
                  }),
                );
              },
              onMouseLeave: () => {
                if (disableHoverEffect) return;
                setAutomationSettingsTableState(
                  produce(draft => {
                    draft.hoveredRow = ' ';
                  }),
                );
              },
            };
          }}
          muiCircularProgressProps={{
            color: 'secondary',
            thickness: 5,
            size: 55,
          }}
          muiSkeletonProps={{
            animation: 'pulse',
            height: 28,
          }}
          enableTableFooter={false}
          getRowId={row => row?.original?.taskId ?? ''}
          layoutMode="grid"
        />
      )}
    </Box>
  );
};

export default AutomationSettings;
