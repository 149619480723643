import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import useTranslation from 'i18next';
import { produce } from 'immer';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table';

import {
  EmailSettingsCellGrid,
  EmailSettingsBodyWrapperGrid,
  EmailSettingsBodyTypography,
  EmailSettingsSubjectTypography,
  ClassificationGroupedDropdownGrid,
  StateDropdownGrid,
  DataNotFoundDiv,
} from './EmailSettingsTableStyle';

import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { ERROR_CONSTANTS } from '@constants/global-constants/constants';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import GroupedSelectInput from '@components/select-input/GroupedSelectInput';
import SelectInput from '@components/select-input/SelectInput';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import BulkEditDialog, {
  IBulkEditProps,
} from '@components/dialog/bulk-update-dialog/BulkEditDialog';
import { executeAfterTimeout, formatDateTime } from '@modules/home/utils/Utils';
import {
  changeEmailSettingsTableName,
  setEmailSettingsSearchAndFilterValues,
  setIsBulkEditNeeded,
  setIsEmailSettingsBulkEditButtonClicked,
  setIsEmailSettingsFilterButtonClicked,
} from '@modules/home/state/email-settings/EmailSettingsSlice';
import { getErrorMessage } from '@utils/ErrorUtils';
import { RootState } from 'src/store/Store';

import {
  fetchAllEmailSettingsList,
  updateEmailSettingsDetail,
} from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import { GetAllEmailSettingsParams } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import {
  getAllStates,
  getAllTaskTypes,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import { GetAllStatesDataRow } from 'src/services/service-handlers/private/ref-data-handlers/RefDataServiceMapper';
import SearchAndFilterDialog, {
  ISearchAndFilterDialogProps,
} from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import { EMAIL_SETTINGS_SCREEN_SEARCH_AND_FILTER_OPTIONS_CONFIG } from './EmailSettingsSearchAndFilterConfiguration';
import {
  SetIsBulkEditNeededPayloadType,
  SetIsEmailSettingsBulkEditButtonClickedPayloadType,
  SetIsEmailSettingsFilterButtonClickedPayloadType,
} from '@modules/home/state/email-settings/EmailSettingsTypes';
import axios, { CancelTokenSource } from 'axios';

type EmailSettingsColumnTypes = {
  id: string;
  fromEmail: string;
  subject: string;
  body: string;
  receivedAt: string;
};

interface IEmailSettingsState {
  isEmailSettingsListLoading: boolean;
  isCategoryDropdownLoading: boolean;
  categoryDropdownValue: string;
  isStateOrNSADropdownLoading: boolean;
  stateOrNSADropdownValue: string;
  categoryDropdownOptionsList: any[];
  stateOrNSADropdownOptionsList: any[];
  totalRowCount: number;
  shouldDialogOpen: boolean;
  filterOptions: {
    searchKey: string;
    searchValue: string;
    noStateSelectionCheckboxSelection: boolean | string;
    noClassificationCheckboxSelection: boolean | string;
  };
  shouldBulkEditDialogOpen: boolean;
  bulkUpdateEmailIds: Array<string>;
  networkErrorState: boolean;
}

const stateOrNSADropdownOptionsFormatter = (
  rowDataArray: GetAllStatesDataRow[],
) => {
  return rowDataArray.map(rowItem => {
    const obj: any = {};
    obj.optionId = rowItem.id;
    obj.optionText = rowItem.label;
    return obj;
  });
};

const categoryDropdownOptionsFormatter = (formattedFlatArray: any[]) => {
  return formattedFlatArray.map(flatItem => {
    const finalObj: any = {
      optionId: '',
      optionText: '',
      listHeader: '',
    };

    if (flatItem.categoryId) {
      finalObj.optionId = flatItem.categoryId;
      finalObj.optionText = null;
      finalObj.listHeader = flatItem.categoryLabel;
      return finalObj;
    } else {
      finalObj.optionId = flatItem.taskId;
      finalObj.optionText = flatItem.taskLabel;
      finalObj.listHeader = flatItem.categoryLabel;
      return finalObj;
    }
  });
};

const EmailSettings = (): ReactElement => {
  const [emailSettingsState, setEmailSettingsState] =
    useState<IEmailSettingsState>({
      isEmailSettingsListLoading: true,
      isCategoryDropdownLoading: true,
      categoryDropdownValue: '',
      isStateOrNSADropdownLoading: true,
      stateOrNSADropdownValue: '',
      categoryDropdownOptionsList: [],
      stateOrNSADropdownOptionsList: [],
      totalRowCount: 0,
      shouldDialogOpen: false,
      filterOptions: {
        searchKey: '',
        searchValue: '',
        noStateSelectionCheckboxSelection: false,
        noClassificationCheckboxSelection: false,
      },
      shouldBulkEditDialogOpen: false,
      bulkUpdateEmailIds: [],
      networkErrorState: false,
    });

  const { t } = useTranslation;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({});
  const [listOpen, setListOpen] = useState(false);

  const {
    emailSettingsSearchAndFilterOptions,
    emailSettingsTableName,
    isEmailSettingsBulkEditButtonClicked,
    isEmailSettingsFilterButtonClicked,
  } = useSelector((state: RootState) => state.emailSettings);

  const dispatch = useDispatch();

  let categoryDropdownOptions: any[] = [];
  let stateOrNSADropdownOptions: any[] = [];

  const handleEmailSettingsRowClick = (emailID = '-1') => {
    if (!listOpen) {
      const emailDetailsNavigationURL =
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes.EmailSettingsDetails.FullPath.replace(
          ':emailID',
          emailID,
        );
      navigate(emailDetailsNavigationURL);
    }
  };

  const updateEmailDetails = async (
    dropdownValue: string | HTMLInputElement | undefined,
    rowData: any,
  ) => {
    const payload: any = {
      taskTypeId: '',
      stateId: '',
    };

    const isStateOrNSADropdownSelected = categoryDropdownOptions.find(
      obj => obj.optionText === dropdownValue,
    )
      ? false
      : true;

    if (!isStateOrNSADropdownSelected) {
      categoryDropdownOptions.map((categoryOptionItem: any) => {
        if (categoryOptionItem.optionText === dropdownValue) {
          payload.taskTypeId = categoryOptionItem.optionId;
          delete payload.stateId;
        }
      });
    } else {
      stateOrNSADropdownOptions.map((stateOrNSAOptionItem: any) => {
        if (stateOrNSAOptionItem.optionText === dropdownValue) {
          if (dropdownValue === AdminConstants.USERS.NSA) {
            payload.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
            delete payload.taskTypeId;
            delete payload.stateId;
          } else {
            payload.stateNsa = AdminConstants.USERS.STATE.toLowerCase();
            payload.stateId = stateOrNSAOptionItem.optionId;
            delete payload.taskTypeId;
          }
        }
      });
    }

    try {
      const finalPayload = {
        pathParam: rowData.id,
        bodyParams: payload,
      };
      const response = await updateEmailSettingsDetail(finalPayload);
      const message = response.data.message;
      enqueueSnackbar(message, SnackBarConfig.getSuccess());
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const handleSelectChange = (selectedValue: boolean) => {
    setListOpen(Boolean(selectedValue));
  };

  const handleDropdownChange = (
    dropdownSelectedValue: string | HTMLInputElement | undefined,
    row: object,
  ) => {
    updateEmailDetails(dropdownSelectedValue, row);
  };

  const renderDropdownInput = (row: any, isGrouped = false) => {
    if (isGrouped) {
      let renderValue;
      categoryDropdownOptions.map(categoryDropdownItem => {
        if (categoryDropdownItem.optionId === row?.taskTypeId) {
          renderValue = categoryDropdownItem.optionText;
        }
      });
      return (
        <GroupedSelectInput
          groupedFilterOptions={categoryDropdownOptions}
          preSelectedValue={renderValue ?? ''}
          handleOptionSelect={data => handleDropdownChange(data, row)}
          onSelectChange={handleSelectChange}
        />
      );
    } else {
      let renderValue;
      stateOrNSADropdownOptions.map(stateOrNSADropdownItem => {
        if (stateOrNSADropdownItem.optionId === row?.stateId) {
          renderValue = stateOrNSADropdownItem.optionText;
        } else if (
          row?.stateId === null &&
          row?.stateNsa &&
          row?.stateNsa.toLowerCase() === AdminConstants.USERS.NSA.toLowerCase()
        ) {
          renderValue = AdminConstants.USERS.NSA;
        }
      });

      return (
        <SelectInput
          dropdownOptions={stateOrNSADropdownOptions}
          preSelectedValue={renderValue ?? ''}
          isgroupingstylingneeded={'yes'}
          showSelectedOption={true}
          isDefaultValuePresent={true}
          handleChange={data => handleDropdownChange(data, row)}
          onSelectChange={handleSelectChange}
        />
      );
    }
  };

  const cancelTokenSourceForTableData = useRef<CancelTokenSource | null>(null);

  const fetchData = async (pageParam: number, fetchSize: number) => {
    cancelTokenSourceForTableData.current = axios.CancelToken.source();

    try {
      const currentOffset = pageParam * fetchSize;
      const payload: GetAllEmailSettingsParams = {
        limit: 50,
        offset: currentOffset,
      };

      if (emailSettingsSearchAndFilterOptions?.searchValue) {
        payload.searchKey =
          emailSettingsSearchAndFilterOptions.searchKey as string;
        payload.searchValue =
          emailSettingsSearchAndFilterOptions.searchValue as string;
      }

      if (emailSettingsSearchAndFilterOptions?.noState)
        payload.isNoStateAssigned =
          emailSettingsSearchAndFilterOptions.noState as boolean;

      if (emailSettingsSearchAndFilterOptions?.noClassification)
        payload.isNoTaskTypeAssigned =
          emailSettingsSearchAndFilterOptions.noClassification as boolean;

      const res = await fetchAllEmailSettingsList(
        payload,
        cancelTokenSourceForTableData.current,
      );
      const { data } = res;

      setEmailSettingsState(
        produce(draft => {
          draft.totalRowCount = data.count ?? 0;
          draft.networkErrorState = false;
        }),
      );

      return data?.rows ?? [];
    } catch (e: any) {
      const errMessage = getErrorMessage(e);
      if (e.code === ERROR_CONSTANTS.ERR_NETWORK || e.statusCode === 500) {
        setEmailSettingsState(
          produce(draft => {
            draft.networkErrorState = true;
          }),
        );
      }
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setEmailSettingsState(
        produce(draft => {
          draft.isEmailSettingsListLoading = false;
          draft.isCategoryDropdownLoading = false;
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
      cancelTokenSourceForTableData.current = null;
    }
  };

  const cancelTokenSourceForCategoryDropdownOptions =
    useRef<CancelTokenSource | null>(null);

  const fetchCategoryDropdownOptions = async () => {
    cancelTokenSourceForCategoryDropdownOptions.current =
      axios.CancelToken.source();

    try {
      const payload = undefined;
      const response = await getAllTaskTypes(
        payload,
        cancelTokenSourceForCategoryDropdownOptions.current,
      );
      const { data } = response;

      const formattedFlatArray: any[] = [];
      const rowData = data?.rows?.length ? [...data.rows] : [];

      // making the nested dropdown options into flat array
      rowData.map(rowItem => {
        const obj: any = { ...rowItem };
        const taskTypesArray = obj.taskTypes;
        obj.taskTypes = null;
        formattedFlatArray.push(obj);
        if (taskTypesArray) {
          taskTypesArray.map((taskTypeItem: any) => {
            const taskTypeItemObj = { ...taskTypeItem };
            taskTypeItemObj.categoryLabel = rowItem.categoryLabel;
            formattedFlatArray.push(taskTypeItemObj);
          });
        }
      });

      // mapping flat list data into dropdown options format to handle it in select input dropdown
      const formattedData =
        categoryDropdownOptionsFormatter(formattedFlatArray);

      return formattedData;
    } catch (e) {
      setEmailSettingsState(
        produce(draft => {
          draft.isCategoryDropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      cancelTokenSourceForCategoryDropdownOptions.current = null;
    }
  };

  const cancelTokenSourceForStateDropdownOptions =
    useRef<CancelTokenSource | null>(null);

  const fetchStateDropdownOptions = async () => {
    cancelTokenSourceForStateDropdownOptions.current =
      axios.CancelToken.source();

    try {
      const payload = undefined;
      const response = await getAllStates(
        payload,
        cancelTokenSourceForStateDropdownOptions.current,
      );
      const { data } = response;

      const rowData = data?.rows?.length ? [...data.rows] : [];
      let formattedStateOrNSAOptions: any = [];

      // adding NSA dropdown option
      formattedStateOrNSAOptions.push({
        optionId: AdminConstants.USERS.STATENSA,
        optionText: AdminConstants.USERS.NSA,
      });

      // mapping list data into dropdown options format to handle it in select input dropdown
      formattedStateOrNSAOptions = [
        ...formattedStateOrNSAOptions,
        ...stateOrNSADropdownOptionsFormatter(rowData),
      ];

      return formattedStateOrNSAOptions;
    } catch (e) {
      setEmailSettingsState(
        produce(draft => {
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      cancelTokenSourceForStateDropdownOptions.current = null;
    }
  };

  const init = async () => {
    const [categoryDropdownOptionsRes, stateOrNSADropdownOptionsRes] =
      await Promise.all([
        fetchCategoryDropdownOptions(),
        fetchStateDropdownOptions(),
      ]);

    if (categoryDropdownOptionsRes) {
      categoryDropdownOptions = categoryDropdownOptionsRes;
      setEmailSettingsState(
        produce(draft => {
          draft.categoryDropdownOptionsList = categoryDropdownOptionsRes;
        }),
      );
    }

    if (stateOrNSADropdownOptionsRes) {
      stateOrNSADropdownOptions = stateOrNSADropdownOptionsRes;
      setEmailSettingsState(
        produce(draft => {
          draft.stateOrNSADropdownOptionsList = stateOrNSADropdownOptionsRes;
        }),
      );
    }

    setEmailSettingsState(
      produce(draft => {
        draft.isCategoryDropdownLoading = false;
        draft.isStateOrNSADropdownLoading = false;
      }),
    );
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForTableData.current) {
        cancelTokenSourceForTableData.current.cancel();
      }
      if (cancelTokenSourceForCategoryDropdownOptions.current) {
        cancelTokenSourceForCategoryDropdownOptions.current.cancel();
      }
      if (cancelTokenSourceForStateDropdownOptions.current) {
        cancelTokenSourceForStateDropdownOptions.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo<MRTColumnDef<EmailSettingsColumnTypes>[]>(
    () => [
      {
        accessorKey: AdminConstants.EMAIL_SETTINGS.TABLE_COLUMNS.FROM_EMAIL,
        header: t('PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE.FROM'),
        accessorFn: row => {
          return (
            <EmailSettingsCellGrid>
              {row?.fromEmail ?? ''}
            </EmailSettingsCellGrid>
          );
        },
        grow: false,
        size: 240,
      },
      {
        accessorKey: AdminConstants.EMAIL_SETTINGS.TABLE_COLUMNS.BODY,
        header: t('PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE.BODY'),
        accessorFn: row => {
          return (
            <EmailSettingsBodyWrapperGrid>
              <EmailSettingsSubjectTypography display="inline">
                {`${row?.subject ?? ''}`}
              </EmailSettingsSubjectTypography>
              <EmailSettingsBodyTypography display="inline">
                {row?.body ?? ''}
              </EmailSettingsBodyTypography>
            </EmailSettingsBodyWrapperGrid>
          );
        },
        grow: true,
        minSize: 200,
      },
      {
        accessorKey:
          AdminConstants.EMAIL_SETTINGS.TABLE_COLUMNS.CLASSIFICATIONS,
        header: t(
          'PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE.CLASSIFICATIONS',
        ),
        accessorFn: row => {
          if (row !== undefined) {
            return (
              <ClassificationGroupedDropdownGrid>
                {renderDropdownInput(row, true)}
              </ClassificationGroupedDropdownGrid>
            );
          }
        },
        grow: false,
        size: 300,
      },
      {
        accessorKey: AdminConstants.EMAIL_SETTINGS.TABLE_COLUMNS.STATE_OR_NSA,
        header: t('PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE.STATE_OR_NSA'),
        accessorFn: row => {
          if (row !== undefined) {
            return (
              <StateDropdownGrid>
                {renderDropdownInput(row, false)}
              </StateDropdownGrid>
            );
          }
        },
        grow: false,
        size: 200,
      },
      {
        accessorKey: AdminConstants.EMAIL_SETTINGS.TABLE_COLUMNS.RECEIVED_AT,
        header: t('PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_TABLE.RECEIVED_AT'),
        accessorFn: row => {
          if (row?.receivedAt) {
            return (
              <EmailSettingsCellGrid>
                {formatDateTime(row.receivedAt)}
              </EmailSettingsCellGrid>
            );
          }
        },
        grow: false,
        size: 200,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleFilterButtonClick = () => {
    setEmailSettingsState(prevState => ({
      ...prevState,
      shouldDialogOpen: true,
    }));
  };

  const handleBulkEditButtonClick = () => {
    setEmailSettingsState(prevState => ({
      ...prevState,
      shouldBulkEditDialogOpen: true,
    }));
  };

  const dialogClose = () => {
    setEmailSettingsState(prevState => ({
      ...prevState,
      shouldDialogOpen: false,
    }));
  };

  const bulkEditDialogClose = () => {
    setEmailSettingsState(prevState => ({
      ...prevState,
      shouldBulkEditDialogOpen: false,
    }));
  };

  const renderSearchAndFilterDropdownDialog = (
    dialogProps: ISearchAndFilterDialogProps,
  ): ReactElement => {
    return <SearchAndFilterDialog {...dialogProps} />;
  };

  const renderBulkEditDialog = (dialogProps: IBulkEditProps): ReactElement => {
    return <BulkEditDialog {...dialogProps} />;
  };

  const handleApplyFilterBtnClick = (data: {
    [key: string]: string | boolean;
  }) => {
    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }

    dispatch(
      setEmailSettingsSearchAndFilterValues({
        data: {
          searchKey: (data?.searchKey as string) ?? '',
          searchValue: (data?.searchValue as string) ?? '',
          noState: data?.noState ?? false,
          noClassification: data?.noClassification ?? false,
        },
      }),
    );

    const values = Object.values(data);

    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        dispatch(changeEmailSettingsTableName());
        break;
      }
    }

    executeAfterTimeout(() => {
      setEmailSettingsState(prevState => ({
        ...prevState,
        shouldDialogOpen: false,
      }));
    }, 100);
  };

  const handleClearFilterBtnClick = () => {
    if (
      emailSettingsSearchAndFilterOptions?.searchValue ||
      emailSettingsSearchAndFilterOptions?.noState ||
      emailSettingsSearchAndFilterOptions?.noClassification
    ) {
      dispatch(
        setEmailSettingsSearchAndFilterValues({
          data: {
            searchKey: '',
            searchValue: '',
            noState: false,
            noClassification: false,
          },
        }),
      );
      dispatch(changeEmailSettingsTableName());
      setEmailSettingsState(
        produce(draft => {
          draft.isEmailSettingsListLoading = true;
        }),
      );
    }

    executeAfterTimeout(() => {
      setEmailSettingsState(prevState => ({
        ...prevState,
        shouldDialogOpen: false,
      }));
    }, 100);
  };

  useEffect(() => {
    if (isEmailSettingsFilterButtonClicked) {
      const setIsEmailSettingsFilterButtonClickedPayload: SetIsEmailSettingsFilterButtonClickedPayloadType =
        {
          isEmailSettingsFilterButtonClickedPayload: false,
        };
      dispatch(
        setIsEmailSettingsFilterButtonClicked(
          setIsEmailSettingsFilterButtonClickedPayload,
        ),
      );
      handleFilterButtonClick();
    }
  }, [dispatch, isEmailSettingsFilterButtonClicked]);

  useEffect(() => {
    if (isEmailSettingsBulkEditButtonClicked) {
      const setIsEmailSettingsBulkEditButtonClickedPayload: SetIsEmailSettingsBulkEditButtonClickedPayloadType =
        {
          isEmailSettingsBulkEditButtonClickedPayload: false,
        };
      dispatch(
        setIsEmailSettingsBulkEditButtonClicked(
          setIsEmailSettingsBulkEditButtonClickedPayload,
        ),
      );
      handleBulkEditButtonClick();
    }
  }, [dispatch, isEmailSettingsBulkEditButtonClicked]);

  useEffect(() => {
    if (Object.keys(rowSelection).length !== 0) {
      setEmailSettingsState(
        produce(draft => {
          draft.bulkUpdateEmailIds = Object.keys(rowSelection);
        }),
      );
      const setIsBulkEditNeededPayload: SetIsBulkEditNeededPayloadType = {
        isBulkEditNeededPayload: true,
      };
      dispatch(setIsBulkEditNeeded(setIsBulkEditNeededPayload));
    } else {
      setEmailSettingsState(
        produce(draft => {
          draft.bulkUpdateEmailIds = [];
        }),
      );
      const setIsBulkEditNeededPayload: SetIsBulkEditNeededPayloadType = {
        isBulkEditNeededPayload: false,
      };
      dispatch(setIsBulkEditNeeded(setIsBulkEditNeededPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection]);

  const renderDataGrid = (): ReactElement => {
    return emailSettingsState.networkErrorState ? (
      <DataNotFoundDiv>{t('NO_DATA_FOUND')}</DataNotFoundDiv>
    ) : (
      <ServerSideDataGrid
        fetchDataFnQueryKeyName={emailSettingsTableName}
        columns={columns}
        fetchDataFn={fetchData}
        dataFetchSize={50}
        enableRowSelection={emailSettingsState.totalRowCount > 0 ? true : false}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleEmailSettingsRowClick(row?.id ?? '-1'),
        })}
        enableSortingRemoval={false}
        enableSorting={false}
        totalRowsCount={emailSettingsState.totalRowCount}
        getRowId={row => row?.id ?? '-1'}
        onRowSelectionChange={setRowSelection}
        rowSelection={rowSelection}
        state={{ isLoading: emailSettingsState.isEmailSettingsListLoading }}
        enableGlobalFilter
      />
    );
  };

  return (
    <Grid>
      {/* {renderTopBar()} */}

      {renderBulkEditDialog({
        open: emailSettingsState.shouldBulkEditDialogOpen,
        handleCloseBtnClick: bulkEditDialogClose,
        emailIds: emailSettingsState.bulkUpdateEmailIds,
        categoryDropdownOptions: emailSettingsState.categoryDropdownOptionsList,
        stateOrNSADropdownOptions:
          emailSettingsState.stateOrNSADropdownOptionsList,
        handleUpdateBtnClick: () => {
          bulkEditDialogClose();
          setRowSelection({});
        },
        refetchData: () => {
          dispatch(changeEmailSettingsTableName());
        },
      })}

      {renderSearchAndFilterDropdownDialog({
        open: emailSettingsState.shouldDialogOpen,
        configuration: EMAIL_SETTINGS_SCREEN_SEARCH_AND_FILTER_OPTIONS_CONFIG,
        handleCloseBtnClick: dialogClose,
        handleApplyFilterClick: data => handleApplyFilterBtnClick(data),
        handleClearFilterClick: handleClearFilterBtnClick,
      })}

      {renderDataGrid()}
    </Grid>
  );
};

export default EmailSettings;
