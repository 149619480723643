import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { APP } from 'src/styles/variables';

interface IReprocessTasksConfirmationDialog {
  isDialogOpen: boolean;
  onSubmitCloseDialog: () => void;
  onCancel: () => void;
  isReprocessingTasksInProgress: boolean;
  taskType?: string;
}

const ReprocessTasksConfirmationDialog = (
  reprocessTasksConfirmationDialogProps: IReprocessTasksConfirmationDialog,
): ReactElement => {
  const { isDialogOpen, onCancel, onSubmitCloseDialog, taskType } =
    reprocessTasksConfirmationDialogProps;

  const { handleSubmit } = useForm({});

  const titleText =
    t(
      'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.REPROCESS_TASKS_CONFIRMATION_TITLE',
    ) +
    (` - ${taskType}` + ' ?');

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={onCancel}
      titleText={titleText}
      titleFontSize={APP.FONT.FONT_SIZE.H5}
      subTitleText=""
      dialogContent=""
      submitButtonText={t('ADMIN.USERS_TAB.YES')}
      cancelButtonText={t('ADMIN.USERS_TAB.NO')}
      onSubmit={handleSubmit(onSubmitCloseDialog)}
      padding="2px"
    />
  );
};

export default ReprocessTasksConfirmationDialog;
