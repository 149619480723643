import { Dialog } from '@mui/material';
import {
  ActionButtonWrapperGrid,
  OkTextButton,
  FlagDetailsStyledDiv,
  FlagDetailsDialogInfoStyledDiv,
  FlagDetailsStyledTitle,
  FlagDetailsStyledValue,
  TopBarGrid,
  TopBarTypography,
  FlagDetailsDialogMainContainer,
} from './QueueFlagDetailsDialogStyle';
import IconButton from '@components/buttons/icon-button/IconButton';
import { t } from 'i18next';
import CloseIcon from '@assets/images/X.svg';

interface IQueueFlagDetailsDialogProps {
  handleCloseFlagDetailsDialog: () => void;
  isFlagDetailsDialogOpen: boolean;
  flagReason: string;
  flagComment: string;
}

const QueueFlagDetailsDialog = (
  props: IQueueFlagDetailsDialogProps,
): JSX.Element => {
  const {
    flagComment,
    flagReason,
    handleCloseFlagDetailsDialog,
    isFlagDetailsDialogOpen,
  } = props;

  const renderTopBar = () => (
    <TopBarGrid>
      <TopBarTypography>
        {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.FLAG_DETAILS')}
      </TopBarTypography>
      <IconButton onClick={handleCloseFlagDetailsDialog}>
        <img src={CloseIcon} height={15} width={15} alt="..." />
      </IconButton>
    </TopBarGrid>
  );

  const renderFlagDetailsDialogContent = () => (
    <FlagDetailsDialogInfoStyledDiv>
      <FlagDetailsStyledDiv>
        <FlagDetailsStyledTitle>
          {t('QUEUE_TABLE.STATUS_REASON')}
        </FlagDetailsStyledTitle>
        <FlagDetailsStyledTitle>{' : '}</FlagDetailsStyledTitle>
        <FlagDetailsStyledValue>
          {flagReason?.length > 0 ? flagReason : '-'}
        </FlagDetailsStyledValue>
      </FlagDetailsStyledDiv>

      <FlagDetailsStyledDiv>
        <FlagDetailsStyledTitle>
          {t('ADMIN.QUEUE_TABS.FLAG_QUEUE_ITEM.COMMENT')}
        </FlagDetailsStyledTitle>
        <FlagDetailsStyledTitle>{' : '}</FlagDetailsStyledTitle>
        <FlagDetailsStyledValue>
          {flagComment?.length > 0 ? flagComment : '-'}
        </FlagDetailsStyledValue>
      </FlagDetailsStyledDiv>
    </FlagDetailsDialogInfoStyledDiv>
  );

  const renderActionButton = () => (
    <ActionButtonWrapperGrid>
      <OkTextButton onClick={handleCloseFlagDetailsDialog}>
        {t('APP.OK')}
      </OkTextButton>
    </ActionButtonWrapperGrid>
  );

  const renderFlagDetailsDialog = () => (
    <Dialog open={isFlagDetailsDialogOpen}>
      <FlagDetailsDialogMainContainer>
        {renderTopBar()}
        {renderFlagDetailsDialogContent()}
        {renderActionButton()}
      </FlagDetailsDialogMainContainer>
    </Dialog>
  );

  return renderFlagDetailsDialog();
};

export default QueueFlagDetailsDialog;
