import ServiceRequest from 'src/services/ServiceRequest';
import {
  FetchAllDashboardUserListParams,
  FetchAllDashboardUserListResponseData,
  GetAllCategoryTypesByCategoryIdParams,
  GetAllCategoryTypesByCategoryIdResponseData,
  GetAllDashboardCategoryListParams,
  GetAllDashboardCategoryListResponseData,
  GetAllDashboardInfoCardListParams,
  GetAllDashboardInfoCardListResponseData,
  GetCompletedTaskDetailsParams,
  GetCompletedTaskDetailsResponseData,
} from './DashboardServiceMapper';
import {
  getAllCategoryListUrl,
  getAllCategoryTypeByCategoryIdUrl,
  getAllDashboardInfoCardListUrl,
  getAllDashboardUserListUrl,
  getCompletedTaskDetailsUrl,
} from './DashboardApiEndPoints';
import { CancelTokenSource } from 'axios';

export const fetchAllDashboardInfoCardList = async (
  data: GetAllDashboardInfoCardListParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllDashboardInfoCardListResponseData> => {
  return await ServiceRequest.get(
    getAllDashboardInfoCardListUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const fetchAllCategoryListUrlList = async (
  data: GetAllDashboardCategoryListParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllDashboardCategoryListResponseData> => {
  return await ServiceRequest.get(
    getAllCategoryListUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const fetchAllCategoryTypesByCategoryId = async (
  data: GetAllCategoryTypesByCategoryIdParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllCategoryTypesByCategoryIdResponseData> => {
  return await ServiceRequest.get(
    getAllCategoryTypeByCategoryIdUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const fetchAllDashboardUserList = async (
  data: FetchAllDashboardUserListParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<FetchAllDashboardUserListResponseData> => {
  return await ServiceRequest.get(
    getAllDashboardUserListUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const getCompletedTaskDetails = async (
  data: GetCompletedTaskDetailsParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetCompletedTaskDetailsResponseData> => {
  return await ServiceRequest.get(
    getCompletedTaskDetailsUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};
