import FormDialog from '@components/dialog/form-dialog/FormDialog';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { APP } from 'src/styles/variables';

interface IAutomationPauseConfirmationDialog {
  isDialogOpen: boolean;
  onSubmitCloseDialog: () => void;
  onCancel: () => void;
  isPaused: boolean;
  isAutomationInProgress: boolean;
  taskType?: string;
}

const AutomationPauseConfirmationDialog = (
  automationPauseConfirmationDialogProps: IAutomationPauseConfirmationDialog,
): ReactElement => {
  const {
    isDialogOpen,
    onCancel,
    onSubmitCloseDialog,
    isPaused,
    isAutomationInProgress,
    taskType,
  } = automationPauseConfirmationDialogProps;

  const { handleSubmit } = useForm({});

  const titleText = isPaused
    ? t(
        'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.TASK_UNPAUSE_CONFIRMATION_CONTENT',
      ) +
      (` - ${taskType}` + ' ?')
    : t(
        'ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.TASK_PAUSE_CONFIRMATION_CONTENT',
      ) +
      (` - ${taskType}` + ' ?');

  const submitButtonText = isAutomationInProgress
    ? t('ADMIN.SETTINGS_TAB.AUTOMATION_SETTINGS.AUTOMATION_TASK_PAUSE.SAVING')
    : t('ADMIN.USERS_TAB.YES');

  return (
    <FormDialog
      isDialogOpen={isDialogOpen}
      handleCancel={onCancel}
      titleText={titleText}
      titleFontSize={APP.FONT.FONT_SIZE.H5}
      subTitleText=""
      dialogContent=""
      submitButtonText={submitButtonText}
      cancelButtonText={t('ADMIN.USERS_TAB.NO')}
      onSubmit={handleSubmit(onSubmitCloseDialog)}
      padding="2px"
      height={isAutomationInProgress ? '12.5rem' : 'auto'}
    />
  );
};

export default AutomationPauseConfirmationDialog;
