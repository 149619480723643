import { baseUrl } from '../../common/ApiEndPoint';

const tasksBaseUrl = `${baseUrl}/tasks`;
export const getTaskDetailsByIdUrl = `${tasksBaseUrl}/:id`;
export const getArbitByTaskIdUrl = `${tasksBaseUrl}/:id/arbit`;
export const getEmailByTaskIdUrl = `${tasksBaseUrl}/:id/email`;
export const getEmailIdentifiersByTaskIdUrl = `${tasksBaseUrl}/:id/identifiers`;
export const updateIdentifiersByTaskIdUrl = `${tasksBaseUrl}/:id/identifiers`;
export const updateTaskStatusByIdUrl = `${tasksBaseUrl}/:id/status`;
export const getProgressBarUrl = `${tasksBaseUrl}/progress`;
export const getTaskExceptionsByTaskIdUrl = `${tasksBaseUrl}/:id/exceptions`;
export const getDBQueryDataByTaskIdUrl = `${tasksBaseUrl}/:id/db-query`;
export const getReassignUsersListByTaskIdUrl = `${tasksBaseUrl}/:id/reassign-user`;
export const getActionsTakenDetailsByIdUrl = `${tasksBaseUrl}/:id/action-taken`;
export const retryFailedQueueAITaskUrl = `${tasksBaseUrl}/:id/retry`;
export const reprocessAutomatedTasksUrl = `${tasksBaseUrl}/reprocess-automation/type/:typeId`;
