import {
  ReactElement,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import useTranslation from 'i18next';
import { produce } from 'immer';
import {
  MRT_Row as MRTRow,
  MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import {
  DashboardWrapperGrid,
  DashboardInfoCardWrapperGrid,
  DashboardHeadingTypography,
  DashboardTopBarGrid,
  DashboardTableSectionWrapperGrid,
  DashboardTableSectionGrid,
  DashboardTableContainerGrid,
  DashboardTableHeaderGrid,
  DashboardTableHeaderTypography,
  DashboardSectionGridWrapper,
  QueueTypeCellDiv,
  QueueTypeCellTypography,
  UserCellWrapperDiv,
  UserCellImageWrapperDiv,
  UserImageAvatar,
  UserCellContentDiv,
  UserCellStateTypography,
  UserCellNameTypography,
  InfoCardLoadingDiv,
  QueueGridLoadingDiv,
  DefaultCellTypography,
  DefaultCellDiv,
  DashboardFilterGrid,
  UserCellProfilePictureLoadingDiv,
} from './DashboardStyle';

import InfoCard from '@components/info-card/InfoCard';
import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import TabFilter from '@components/tabs/tab-filter/TabFilter';
import DashboardDataGrid from '@components/custom-data-grid/dashboard-datagrid/DashboardDataGrid';
import DashboardServerSideDataGrid from '@components/custom-data-grid/dashboard-datagrid/DashboardServerSideDataGrid';
import Loader from '@components/loaders/Loader';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';

import {
  AdminConstants,
  DashboardConstants,
} from '@constants/admin-constants/AdminConstants';
import { BOOLEAN_TYPES } from '@constants/global-constants/constants';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { generateUniqueKey, mapStateNsaLabel } from '@modules/home/utils/Utils';
import {
  IQueryParamsList,
  setURLWithParams,
} from '@utils/SetURLWithParamsUtils';
import { getErrorMessage } from '@utils/ErrorUtils';

import {
  getCompletedTaskDetails,
  fetchAllCategoryListUrlList,
  fetchAllDashboardInfoCardList,
  fetchAllDashboardUserList,
} from 'src/services/service-handlers/private/dashboard/DashboardPrivateService';

import {
  AssignedType,
  GetAllDashboardInfoCardListRowType,
  RangeType,
  StateNsaType,
  StatusType,
  TaskOwnerType,
} from 'src/services/service-handlers/private/dashboard/DashboardServiceMapper';
import {
  changeTableName,
  setProfilePicsUrlsWithLoaderFlagById,
  setQueueSearchAndFilterOptions,
} from '@modules/home/state/queue/QueueSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getProfilePicture } from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import { ProfilePicRefType } from 'src/types/Types';
import { RootState } from 'src/store/Store';
import { SetProfilePicsUrlsWithLoaderFlagByIdPayloadType } from '@modules/home/state/queue/QueueTypes';
import axios, { CancelTokenSource } from 'axios';

type InfoCardRowType = {
  categoryId: string;
  count: number;
  label: string;
  order?: number;
  nameId: string;
  filterNeeded?: boolean;
  orderIndex?: number;
};

type QueueRowType = {
  id?: string | null;
  isExpandable?: boolean;
  categoryId?: string | null;
  high: number;
  label: string;
  low: number;
  medium: number;
  order: number;
  total: number;
};

const userFilterButtons = [
  { label: 'All', value: 'all' },
  { label: 'NSA', value: 'nsa' },
  { label: 'State', value: 'state' },
];

const UserOrAutomatedFilterButtons = [
  { label: 'User', value: 'manual' },
  { label: 'Automated', value: 'automated' },
];

const Storage = {
  KEYS: {
    ASSIGNED: DashboardConstants.FILTER_CONSTANTS.ASSIGNED,
    TASK_OWNER_TYPE: DashboardConstants.FILTER_CONSTANTS.TASK_OWNER_TYPE,
    STATE_NSA: DashboardConstants.FILTER_CONSTANTS.STATE_NSA,
    TIME_RANGE: DashboardConstants.FILTER_CONSTANTS.TIME_RANGE,
  },
};

interface TopBarFilterInterface {
  stateNSAFilter: string;
  taskOwnerTypeFilter: string;
}

type InfoCardDataType = {
  categoryId?: string;
  count: number | ReactNode;
  label: string;
  order?: number;
  filterNeeded?: boolean;
  nameId?: string;
};

type UserQueueColumnTypes = {
  userId: string;
  userName: string;
  total: number;
  high: number;
  low: number;
  medium: number;
  stateNsa: string;
  userProfilePicId: string | null;
};

type QueueColumnTypes = {
  label: string;
  total: number;
  high: number;
  low: number;
  medium: number;
  categoryId: string;
  order: number;
};

type InfoCardPayloadType = {
  stateNsa?: StateNsaType;
  taskOwnerType?: TaskOwnerType;
};

type UserQueuePayloadType = {
  offset: number;
  limit: number;
  taskOwnerType?: TaskOwnerType;
  stateNsa?: StateNsaType;
  status?: StatusType;
  categoryId?: string;
  range?: RangeType;
};

type QueueGridPayloadType = {
  taskOwnerType?: TaskOwnerType;
  stateNsa?: StateNsaType;
  assigned?: AssignedType;
  status?: StatusType;
  categoryId?: string;
  range?: RangeType;
};

type DashboardTablePriorityType = 'total' | 'high' | 'medium' | 'low';

type NavigationPathParamType = {
  userId?: string;
  stateNsa?: StateNsaType;
  status?: StatusType;
  priority?: DashboardTablePriorityType;
  assigned?: AssignedType;
  categoryId?: string;
  typeId?: string;
  range?: RangeType;
};

interface IState {
  topBarFilter: TopBarFilterInterface;
  selectedInfoCard: InfoCardDataType | null;
  isInfoCardLoading: boolean;
  infoCardData: Array<InfoCardDataType>;
  isQueueGridLoading: boolean;
  queueGridData: Array<QueueColumnTypes>;
  queueGridFilter: string;
  isUserQueueGridLoading: boolean;
  userQueueGridData: Array<UserQueueColumnTypes>;
  userQueueGridFilter: string;
  userQueueFetchSize: number;
  userQueueTotalRowCount: number;
  fetchQueueData: boolean;
  fetchDataFnQueryKeyName: string;
  status: StatusType;
  fetchUserQueueGridOnly: boolean;
  timeRange: RangeType;
}

const Dashboard = (): ReactElement => {
  const { t } = useTranslation;
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedProfilePicRef = useRef<ProfilePicRefType>({});

  const [dashboardState, setDashboardState] = useState<IState>({
    topBarFilter: {
      stateNSAFilter: 'all',
      taskOwnerTypeFilter: 'manual',
    },
    selectedInfoCard: null,
    isInfoCardLoading: true,
    infoCardData: [],
    isQueueGridLoading: true,
    queueGridData: [],
    queueGridFilter: 'all',
    isUserQueueGridLoading: true,
    userQueueGridData: [],
    userQueueGridFilter: 'all',
    userQueueFetchSize: 10,
    userQueueTotalRowCount: 50,
    fetchQueueData: false,
    fetchDataFnQueryKeyName:
      DashboardConstants.USER_TABLE.USER_QUEUE_TABLE_NAME,
    status: 'open',
    fetchUserQueueGridOnly: false,
    timeRange: DashboardConstants.TIME_SPAN.DAY as RangeType,
  });

  const { profilePicsUrlsWithLoaderFlagById } = useSelector(
    (state: RootState) => state.queue,
  );

  const queueColumns = useMemo<MRTColumnDef<QueueColumnTypes>[]>(
    () => [
      {
        header: '',
        accessorKey: DashboardConstants.QUEUE_TABLE.COLUMNS.LABEL,
        grow: true,
        size: 160,
        enableSorting: false,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.QUEUE_TABLE.COLUMN_HEADING.TOTAL'),
        accessorKey: DashboardConstants.QUEUE_TABLE.COLUMNS.TOTAL,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.QUEUE_TABLE.COLUMN_HEADING.HIGH'),
        accessorKey: DashboardConstants.QUEUE_TABLE.COLUMNS.HIGH,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.QUEUE_TABLE.COLUMN_HEADING.MEDIUM'),
        accessorKey: DashboardConstants.QUEUE_TABLE.COLUMNS.MEDIUM,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.QUEUE_TABLE.COLUMN_HEADING.LOW'),
        accessorKey: DashboardConstants.QUEUE_TABLE.COLUMNS.LOW,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderUserRow = (row: {
    userName: any;
    total?: number;
    high?: number;
    low?: number;
    medium?: number;
    stateNsa: string;
    userId: string;
  }) => {
    return (
      <UserCellWrapperDiv>
        <UserCellImageWrapperDiv>
          {profilePicsUrlsWithLoaderFlagById[row?.userId]
            ?.isProfilePicLoading ? (
            <UserCellProfilePictureLoadingDiv>
              <Loader size={20} />
            </UserCellProfilePictureLoadingDiv>
          ) : (
            <UserImageAvatar
              alt={row?.userName ?? ''}
              src={
                profilePicsUrlsWithLoaderFlagById[row?.userId]?.profilePicUrl ??
                ''
              }
            />
          )}
        </UserCellImageWrapperDiv>
        <UserCellContentDiv>
          <UserCellNameTypography>{row?.userName}</UserCellNameTypography>
          <UserCellStateTypography>{row?.stateNsa}</UserCellStateTypography>
        </UserCellContentDiv>
      </UserCellWrapperDiv>
    );
  };

  const userColumns = useMemo<MRTColumnDef<UserQueueColumnTypes>[]>(
    () => [
      {
        header: '',
        accessorKey: DashboardConstants.USER_TABLE.COLUMNS.USER_NAME,
        grow: true,
        size: 160,
        enableSorting: false,
        enableCellActions: true,
        accessorFn: row => renderUserRow(row),
      },
      {
        header: t('PRIVATE.DASHBOARD.USER_TABLE.COLUMN_HEADING.TOTAL'),
        accessorKey: DashboardConstants.USER_TABLE.COLUMNS.TOTAL,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.USER_TABLE.COLUMN_HEADING.HIGH'),
        accessorKey: DashboardConstants.USER_TABLE.COLUMNS.HIGH,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.USER_TABLE.COLUMN_HEADING.MEDIUM'),
        accessorKey: DashboardConstants.USER_TABLE.COLUMNS.MEDIUM,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
      {
        header: t('PRIVATE.DASHBOARD.USER_TABLE.COLUMN_HEADING.LOW'),
        accessorKey: DashboardConstants.USER_TABLE.COLUMNS.LOW,
        minSize: 30,
        size: 45,
        enableHiding: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getScreenURLToNavigate = (navigationPathParamObj: any) => {
    if (navigationPathParamObj.status === DashboardConstants.INFO_CARD.OPEN) {
      if (
        dashboardState.topBarFilter.taskOwnerTypeFilter ===
        UserOrAutomatedFilterButtons[0]?.value
      ) {
        return PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueTable.FullPath;
      } else {
        return PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueQueueAI.FullPath;
      }
    } else if (
      navigationPathParamObj.status === DashboardConstants.INFO_CARD.COMPLETED
    ) {
      if (
        dashboardState.topBarFilter.taskOwnerTypeFilter ===
        UserOrAutomatedFilterButtons[0]?.value
      ) {
        return PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueHistory.FullPath;
      } else {
        return PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueQueueAI.FullPath;
      }
    }
  };

  const tableCellClickHandler = (
    row: MRTRow<any>,
    columnData: MRTColumnDef<any>,
  ) => {
    const navigationPathParamObj: NavigationPathParamType = {};
    // handle user queue table cell click
    if (row.original.userId) {
      navigationPathParamObj.userId = row.original.userId;
      navigationPathParamObj.stateNsa =
        dashboardState.userQueueGridFilter as StateNsaType;
      navigationPathParamObj.status =
        dashboardState.selectedInfoCard?.label.toLowerCase() ===
        DashboardConstants.INFO_CARD.COMPLETED
          ? (DashboardConstants.INFO_CARD.COMPLETED as StatusType)
          : (DashboardConstants.INFO_CARD.OPEN as StatusType);
      if (columnData.id !== DashboardConstants.USER_TABLE.COLUMNS.USER_NAME) {
        navigationPathParamObj.priority =
          columnData.id as DashboardTablePriorityType;
      }
      // handle queue table cell click
    } else {
      navigationPathParamObj.stateNsa = dashboardState.topBarFilter
        .stateNSAFilter as StateNsaType;
      navigationPathParamObj.assigned =
        dashboardState.queueGridFilter.toLowerCase() as AssignedType;
      navigationPathParamObj.status =
        dashboardState.selectedInfoCard?.label.toLowerCase() ===
        DashboardConstants.INFO_CARD.COMPLETED
          ? (DashboardConstants.INFO_CARD.COMPLETED as StatusType)
          : (DashboardConstants.INFO_CARD.OPEN as StatusType);

      // handle top row cell clicks
      if (row.original.typeId === undefined) {
        navigationPathParamObj.categoryId = row.original.id;
        if (columnData.id !== DashboardConstants.QUEUE_TABLE.COLUMNS.LABEL) {
          navigationPathParamObj.priority =
            columnData.id as DashboardTablePriorityType;
        }
      } else {
        // handle sub rows cell clicks
        navigationPathParamObj.categoryId = row.original.categoryId;
        navigationPathParamObj.typeId = row.original.typeId;
        if (columnData.id !== DashboardConstants.QUEUE_TABLE.COLUMNS.LABEL) {
          navigationPathParamObj.priority =
            columnData.id as DashboardTablePriorityType;
        }
      }
    }

    if (
      dashboardState.selectedInfoCard?.label.toLowerCase() ===
      DashboardConstants.INFO_CARD.COMPLETED
    ) {
      navigationPathParamObj.range = dashboardState.timeRange;
    }

    const result: IQueryParamsList[] = [];

    Object.entries(navigationPathParamObj).forEach(([key, value]) => {
      result.push({
        queryParamName: key,
        queryParamValue: value,
      });
    });

    const URL = getScreenURLToNavigate(navigationPathParamObj);

    const isAutomatedTabSelected =
      dashboardState.topBarFilter.taskOwnerTypeFilter ===
      UserOrAutomatedFilterButtons[1]?.value;

    const searchAndFilterPayloadData = {
      taskStatus:
        navigationPathParamObj.assigned === AdminConstants.QUEUE.UN_ASSIGNED
          ? navigationPathParamObj.assigned
          : isAutomatedTabSelected
          ? navigationPathParamObj.status
          : '',
      taskPriority:
        navigationPathParamObj.priority === AdminConstants.QUEUE.TOTAL
          ? ''
          : navigationPathParamObj.priority ?? '',
      nsaState:
        navigationPathParamObj.stateNsa === AdminConstants.QUEUE.ALL
          ? ''
          : navigationPathParamObj.stateNsa ?? '',
      assignedUserId: navigationPathParamObj.userId ?? '',
      taskCategoryId: navigationPathParamObj.categoryId ?? '',
      taskTypeId: navigationPathParamObj.typeId ?? '',
      range: navigationPathParamObj.range ?? '',
    };

    if (navigationPathParamObj) {
      dispatch(
        setQueueSearchAndFilterOptions({
          data: searchAndFilterPayloadData,
        }),
      );
    } else {
      dispatch(setQueueSearchAndFilterOptions({ data: {} }));
    }

    dispatch(changeTableName());

    if (URL) {
      const finalURLWithParams = setURLWithParams(
        null,
        result as IQueryParamsList[],
        URL,
      );
      navigate(finalURLWithParams);
    }
  };

  const cancelTokenSourceForQueueGridData = useRef<CancelTokenSource | null>(
    null,
  );

  const fetchQueueGridData = async (activeInfoCard?: InfoCardDataType) => {
    cancelTokenSourceForQueueGridData.current = axios.CancelToken.source();

    let status: StatusType;
    if (activeInfoCard?.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
      status = DashboardConstants.INFO_CARD.COMPLETED as StatusType;
    } else {
      status = DashboardConstants.INFO_CARD.OPEN as StatusType;
    }
    const payload: QueueGridPayloadType = {
      status: status,
      taskOwnerType: dashboardState.topBarFilter
        .taskOwnerTypeFilter as TaskOwnerType,
      stateNsa: dashboardState.topBarFilter.stateNSAFilter as StateNsaType,
      assigned: dashboardState.queueGridFilter.toLowerCase() as AssignedType,
      range: dashboardState.timeRange as RangeType,
    };

    if (activeInfoCard?.categoryId) {
      payload.categoryId = activeInfoCard?.categoryId;
    }

    const QueueTableAssignedFilter = localStorage.getItem(
      Storage.KEYS.ASSIGNED,
    );
    const TopBarStateNsaFilter = localStorage.getItem(Storage.KEYS.STATE_NSA);
    const TopBarTaskOwnerTypeFilter = localStorage.getItem(
      Storage.KEYS.TASK_OWNER_TYPE,
    );

    const timeRange = localStorage.getItem(Storage.KEYS.TIME_RANGE);

    if (activeInfoCard?.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
      if (timeRange) {
        const timeSpanValue = JSON.parse(timeRange);
        payload.range = timeSpanValue;
      }
    } else {
      delete payload.range;
    }

    if (TopBarStateNsaFilter) {
      const TopBarStateNsaFilterValue = JSON.parse(TopBarStateNsaFilter);
      payload.stateNsa = TopBarStateNsaFilterValue.stateNsa as StateNsaType;
    }

    if (TopBarTaskOwnerTypeFilter) {
      const TopBarTaskOwnerTypeFilterValue = JSON.parse(
        TopBarTaskOwnerTypeFilter,
      );
      payload.taskOwnerType =
        TopBarTaskOwnerTypeFilterValue.taskOwnerType as TaskOwnerType;
    }

    if (QueueTableAssignedFilter) {
      const QueueTableAssignedFilterValue = JSON.parse(
        QueueTableAssignedFilter,
      );
      payload.assigned =
        QueueTableAssignedFilterValue.assigned.toLowerCase() as AssignedType;
    }

    try {
      const response = await fetchAllCategoryListUrlList(
        payload,
        cancelTokenSourceForQueueGridData.current,
      );
      if (response?.data?.rows && response?.data?.rows.length) {
        const queueList = response?.data?.rows ?? [];
        queueList.map((queueItem: QueueRowType) => {
          if (queueItem) {
            queueItem.isExpandable = true;
            queueItem.id = queueItem?.categoryId ?? '';
            queueItem.categoryId = null;
            return queueItem;
          }
        });

        setDashboardState(
          produce(draft => {
            draft.queueGridData = response?.data?.rows ?? [];
            draft.isQueueGridLoading = false;
          }),
        );
      }
    } catch (e) {
      setDashboardState(
        produce(draft => {
          draft.isQueueGridLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setDashboardState(
        produce(draft => {
          draft.isQueueGridLoading = false;
        }),
      );
      cancelTokenSourceForQueueGridData.current = null;
    }
  };

  const handleTableFilterChange = (
    selectedTabFilter: string,
    isQueueTable: boolean,
  ) => {
    if (isQueueTable) {
      setDashboardState(
        produce(draft => {
          draft.queueGridFilter = selectedTabFilter;
          draft.isQueueGridLoading = true;
        }),
      );

      const obj = {
        assigned: selectedTabFilter,
      };
      localStorage.setItem(Storage.KEYS.ASSIGNED, JSON.stringify(obj));
      if (dashboardState.selectedInfoCard)
        fetchQueueGridData(dashboardState.selectedInfoCard);
      return;
    }

    setDashboardState(
      produce(draft => {
        draft.fetchUserQueueGridOnly = true;
        draft.userQueueGridFilter = selectedTabFilter;
        draft.isUserQueueGridLoading = true;
        draft.fetchDataFnQueryKeyName =
          DashboardConstants.USER_TABLE.USER_QUEUE_TABLE_NAME +
          generateUniqueKey();
      }),
    );
    return;
  };

  const setOrderForInfoCardList = (
    rowDataList: GetAllDashboardInfoCardListRowType[],
  ) => {
    const updatedRowDataList = rowDataList.map(
      (rowItem: InfoCardRowType, index: number) => {
        if (rowItem.nameId === DashboardConstants.INFO_CARD.OPEN) {
          rowItem.orderIndex = 0;
          rowItem.filterNeeded = false;
          return rowItem;
        }
        if (rowItem.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
          rowItem.orderIndex = 4;
          rowItem.filterNeeded = true;
          return rowItem;
        }

        if (index + 1 === 4) {
          rowItem.filterNeeded = false;
          rowItem.orderIndex = index + 2;
          return rowItem;
        }

        if (index + 1 === 5) {
          rowItem.filterNeeded = false;
          rowItem.orderIndex = index + 3;
          return rowItem;
        }

        rowItem.filterNeeded = false;
        rowItem.orderIndex = index + 1;
        return rowItem;
      },
    );

    if (updatedRowDataList && updatedRowDataList.length >= 0) {
      updatedRowDataList.sort((a, b) => {
        let x = 0;
        let y = 0;
        if (a?.orderIndex) {
          x = a.orderIndex;
        }

        if (b?.orderIndex) {
          y = b.orderIndex;
        }

        return x - y;
      });
    }

    return updatedRowDataList;
  };

  const cancelTokenSourceForInfoCard = useRef<CancelTokenSource | null>(null);

  const fetchInfoCard = async (activeInfoCard?: InfoCardDataType) => {
    cancelTokenSourceForInfoCard.current = axios.CancelToken.source();

    const payload: InfoCardPayloadType = {
      taskOwnerType: dashboardState.topBarFilter
        .taskOwnerTypeFilter as TaskOwnerType,
      stateNsa: dashboardState.topBarFilter.stateNSAFilter as StateNsaType,
    };

    const TopBarTaskOwnerTypeFilter = localStorage.getItem(
      Storage.KEYS.TASK_OWNER_TYPE,
    );
    const TopBarStateNsaFilter = localStorage.getItem(Storage.KEYS.STATE_NSA);

    if (TopBarStateNsaFilter) {
      const TopBarStateNsaFilterValue = JSON.parse(TopBarStateNsaFilter);
      payload.stateNsa = TopBarStateNsaFilterValue.stateNsa as StateNsaType;
    }

    if (TopBarTaskOwnerTypeFilter) {
      const TopBarTaskOwnerTypeFilterValue = JSON.parse(
        TopBarTaskOwnerTypeFilter,
      );
      payload.taskOwnerType =
        TopBarTaskOwnerTypeFilterValue.taskOwnerType as TaskOwnerType;
    }

    try {
      const response = await fetchAllDashboardInfoCardList(
        payload,
        cancelTokenSourceForInfoCard.current,
      );

      if (response?.data?.rows && response?.data?.rows?.length) {
        const rowData = response.data.rows;

        const infoCardList = setOrderForInfoCardList(rowData);

        if (!activeInfoCard) {
          const defaultSelectedInfoCard = rowData.filter(
            (rowItem: InfoCardRowType) =>
              rowItem.nameId === DashboardConstants.INFO_CARD.OPEN,
          );

          setDashboardState(
            produce(draft => {
              draft.infoCardData = infoCardList;
              draft.isInfoCardLoading = false;
              draft.selectedInfoCard = defaultSelectedInfoCard[0] ?? null;
            }),
          );
        } else if (
          activeInfoCard &&
          activeInfoCard?.nameId !== DashboardConstants.INFO_CARD.COMPLETED
        ) {
          setDashboardState(
            produce(draft => {
              draft.infoCardData = infoCardList;
              draft.isInfoCardLoading = false;
            }),
          );
        }
      }
    } catch (e) {
      setDashboardState(
        produce(draft => {
          draft.isInfoCardLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setDashboardState(
        produce(draft => {
          draft.isInfoCardLoading = false;
        }),
      );
      cancelTokenSourceForInfoCard.current = null;
    }
  };

  const cancelTokenSourceForDataGrid = useRef<CancelTokenSource | null>(null);

  const fetchInfiniteQueryDataFn = async (
    pageParam: number,
    fetchSize: number,
  ) => {
    cancelTokenSourceForDataGrid.current = axios.CancelToken.source();

    try {
      const currentOffset = pageParam * fetchSize;
      const payload: UserQueuePayloadType = {
        offset: currentOffset,
        limit: dashboardState.userQueueFetchSize,
        taskOwnerType: dashboardState.topBarFilter
          ?.taskOwnerTypeFilter as TaskOwnerType,
        stateNsa: dashboardState.fetchUserQueueGridOnly
          ? (dashboardState.userQueueGridFilter as StateNsaType)
          : (dashboardState.topBarFilter.stateNSAFilter as StateNsaType),
        status: dashboardState.status as StatusType,
      };

      if (
        dashboardState.selectedInfoCard?.nameId ===
        DashboardConstants.INFO_CARD.COMPLETED
      ) {
        payload.range = dashboardState.timeRange;
      }

      if (dashboardState.selectedInfoCard?.categoryId) {
        payload.categoryId = dashboardState.selectedInfoCard?.categoryId;
      }

      const response = await fetchAllDashboardUserList(
        payload,
        cancelTokenSourceForDataGrid.current,
      );

      setDashboardState(
        produce(draft => {
          draft.userQueueGridData = response?.data?.rows ?? [];
          draft.userQueueTotalRowCount = response?.data?.count ?? 0;
          draft.isUserQueueGridLoading = false;
        }),
      );

      return response?.data?.rows ?? [];
    } catch (e) {
      setDashboardState(
        produce(draft => {
          draft.isUserQueueGridLoading = false;
          draft.userQueueTotalRowCount = 0;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());

      return [];
    } finally {
      setDashboardState(
        produce(draft => {
          draft.fetchUserQueueGridOnly = false;
          draft.isUserQueueGridLoading = false;
        }),
      );
      cancelTokenSourceForDataGrid.current = null;
    }
  };

  const init = async (activeInfoCard?: InfoCardDataType) => {
    if (!activeInfoCard) {
      await fetchInfoCard();
      await fetchQueueGridData();
    } else {
      if (activeInfoCard?.nameId !== DashboardConstants.INFO_CARD.COMPLETED) {
        await fetchInfoCard(activeInfoCard);
      }
      await fetchQueueGridData(activeInfoCard);
    }
    localStorage.removeItem(Storage.KEYS.ASSIGNED);
    localStorage.removeItem(Storage.KEYS.TASK_OWNER_TYPE);
    localStorage.removeItem(Storage.KEYS.STATE_NSA);
    localStorage.removeItem(Storage.KEYS.TIME_RANGE);
  };

  useEffect(() => {
    localStorage.removeItem(Storage.KEYS.ASSIGNED);
    localStorage.removeItem(Storage.KEYS.TASK_OWNER_TYPE);
    localStorage.removeItem(Storage.KEYS.STATE_NSA);
    localStorage.removeItem(Storage.KEYS.TIME_RANGE);
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfilePictureById = async (
    userId: string,
    profilePictureId: string,
  ) => {
    if (userId.length > 0 && profilePictureId.length > 0) {
      if (profilePicsUrlsWithLoaderFlagById[userId]) {
        return profilePicsUrlsWithLoaderFlagById[userId];
      } else {
        const initialProfilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
          {
            userId,
            profilePicUrl: '',
            isProfilePicLoading: true,
          };
        dispatch(
          setProfilePicsUrlsWithLoaderFlagById(
            initialProfilePicsUrlsWithLoaderFlagByIdPayload,
          ),
        );

        try {
          const getProfilePicResp = await getProfilePicture(userId);
          const { data } = getProfilePicResp;
          if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
            const profilePicData = data.content.data;
            const blobImg = new Blob([new Uint8Array(profilePicData)], {
              type: data.mimeType,
            });
            const profilePicUrl = URL.createObjectURL(blobImg);
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl,
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return profilePicUrl;
          } else {
            const errMessage = t(
              'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
            );
            enqueueSnackbar(errMessage, SnackBarConfig.getError());
            const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
              {
                userId,
                profilePicUrl: '',
                isProfilePicLoading: false,
              };
            dispatch(
              setProfilePicsUrlsWithLoaderFlagById(
                profilePicsUrlsWithLoaderFlagByIdPayload,
              ),
            );
            return '';
          }
        } catch (error) {
          const errMessage = getErrorMessage(error);
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
          const profilePicsUrlsWithLoaderFlagByIdPayload: SetProfilePicsUrlsWithLoaderFlagByIdPayloadType =
            {
              userId,
              profilePicUrl: '',
              isProfilePicLoading: false,
            };
          dispatch(
            setProfilePicsUrlsWithLoaderFlagById(
              profilePicsUrlsWithLoaderFlagByIdPayload,
            ),
          );
          return '';
        }
      }
    }
    return '';
  };

  const fetchProfilePictures = async (userDataList: UserQueueColumnTypes[]) => {
    for (const userData of userDataList) {
      const { userId = '', userProfilePicId = '' } = userData;
      if (
        userId?.length > 0 &&
        userProfilePicId &&
        userProfilePicId?.length > 0 &&
        !fetchedProfilePicRef.current[userId]
      ) {
        fetchedProfilePicRef.current[userId] = true;
        await getProfilePictureById(userId, userProfilePicId);
      }
    }
  };

  useEffect(() => {
    fetchProfilePictures(dashboardState.userQueueGridData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardState.userQueueGridData]);

  const cancelTokenSourceForCompleteTaskDetails =
    useRef<CancelTokenSource | null>(null);

  const fetchCompleteTaskDetails = async (timeRange: string) => {
    cancelTokenSourceForCompleteTaskDetails.current =
      axios.CancelToken.source();

    const payload = {
      taskOwnerType: dashboardState.topBarFilter
        ?.taskOwnerTypeFilter as TaskOwnerType,
      stateNsa: dashboardState.topBarFilter.stateNSAFilter as StateNsaType,
      range: timeRange as RangeType,
    };

    let updatedCompletedInfoCardData = {};
    try {
      const response = await getCompletedTaskDetails(
        payload,
        cancelTokenSourceForCompleteTaskDetails.current,
      );
      const updatedInfoCardList = dashboardState.infoCardData.map(
        infoCardItem => {
          if (infoCardItem.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
            const updatedInfoCardItem = { ...infoCardItem };
            updatedInfoCardItem.count = response?.data?.count ?? 0;
            updatedCompletedInfoCardData = updatedInfoCardItem;
            return updatedInfoCardItem;
          }
          return infoCardItem;
        },
      );

      setDashboardState(
        produce(draft => {
          draft.selectedInfoCard =
            updatedCompletedInfoCardData as InfoCardDataType;
          draft.infoCardData = updatedInfoCardList;
        }),
      );

      return updatedCompletedInfoCardData;
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      const updatedInfoCardList = dashboardState.infoCardData.map(
        infoCardItem => {
          if (infoCardItem.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
            const updatedInfoCardItem = { ...infoCardItem };
            updatedInfoCardItem.count = '-';
            return updatedInfoCardItem;
          }
          return infoCardItem;
        },
      );

      setDashboardState(
        produce(draft => {
          draft.infoCardData = updatedInfoCardList;
        }),
      );

      return null;
    } finally {
      cancelTokenSourceForCompleteTaskDetails.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForInfoCard.current) {
        cancelTokenSourceForInfoCard.current.cancel();
      }
      if (cancelTokenSourceForDataGrid.current) {
        cancelTokenSourceForDataGrid.current.cancel();
      }
      if (cancelTokenSourceForCompleteTaskDetails.current) {
        cancelTokenSourceForCompleteTaskDetails.current.cancel();
      }
      if (cancelTokenSourceForQueueGridData.current) {
        cancelTokenSourceForQueueGridData.current.cancel();
      }
    };
  }, []);

  const handleInfoCardClick = (
    event: React.SyntheticEvent,
    clickedInfoCard?: InfoCardDataType,
  ) => {
    if (
      clickedInfoCard &&
      clickedInfoCard.label !== dashboardState.selectedInfoCard?.label
    ) {
      setDashboardState(
        produce(draft => {
          draft.timeRange = DashboardConstants.TIME_SPAN.DAY as RangeType;
        }),
      );
      const topBarStateNsaFilterValue =
        dashboardState.topBarFilter.stateNSAFilter;
      if (clickedInfoCard.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
        setDashboardState(
          produce(draft => {
            draft.status = DashboardConstants.INFO_CARD.COMPLETED as StatusType;
            draft.userQueueGridFilter = topBarStateNsaFilterValue;
            draft.selectedInfoCard = clickedInfoCard;
            draft.fetchDataFnQueryKeyName =
              DashboardConstants.USER_TABLE.USER_QUEUE_TABLE_NAME +
              generateUniqueKey();
            draft.isUserQueueGridLoading = true;
            draft.isQueueGridLoading = true;
          }),
        );
      } else {
        setDashboardState(
          produce(draft => {
            draft.status = DashboardConstants.INFO_CARD.OPEN as StatusType;
            draft.userQueueGridFilter = topBarStateNsaFilterValue;
            draft.selectedInfoCard = clickedInfoCard;
            draft.fetchDataFnQueryKeyName =
              DashboardConstants.USER_TABLE.USER_QUEUE_TABLE_NAME +
              generateUniqueKey();
            draft.isUserQueueGridLoading = true;
            draft.isQueueGridLoading = true;
          }),
        );
      }

      init(clickedInfoCard);
    }
  };

  const handleTopBarFilterClick = (
    selectedTabFilterOption: StateNsaType | TaskOwnerType,
    isStateNsaFilterChange: boolean,
  ) => {
    if (isStateNsaFilterChange) {
      const obj = {
        stateNsa: selectedTabFilterOption,
      };
      localStorage.setItem(Storage.KEYS.STATE_NSA, JSON.stringify(obj));

      setDashboardState(
        produce(draft => {
          draft.topBarFilter.stateNSAFilter = selectedTabFilterOption;
          draft.userQueueGridFilter = selectedTabFilterOption;
        }),
      );
    } else {
      const obj = {
        taskOwnerType: selectedTabFilterOption,
      };
      localStorage.setItem(Storage.KEYS.TASK_OWNER_TYPE, JSON.stringify(obj));

      setDashboardState(
        produce(draft => {
          draft.topBarFilter.taskOwnerTypeFilter = selectedTabFilterOption;
        }),
      );
    }

    setDashboardState(
      produce(draft => {
        draft.fetchDataFnQueryKeyName =
          DashboardConstants.USER_TABLE.USER_QUEUE_TABLE_NAME +
          generateUniqueKey();
        draft.isInfoCardLoading = true;
        draft.isUserQueueGridLoading = true;
        draft.isQueueGridLoading = true;
      }),
    );

    init();
  };

  const renderTopBar = (): JSX.Element => {
    return (
      <DashboardTopBarGrid>
        <DashboardHeadingTypography>
          {t('PRIVATE.DASHBOARD.DASHBOARD_TITLE')}
        </DashboardHeadingTypography>
        <DashboardFilterGrid>
          <TabFilter
            isDefaultTabFilterOptionsNeeded={false}
            filterOptions={userFilterButtons}
            defaultSelectedTab={dashboardState.topBarFilter.stateNSAFilter}
            onSelectionChange={selectedTabFilter =>
              handleTopBarFilterClick(selectedTabFilter as StateNsaType, true)
            }
          />
          <TabFilter
            filterOptions={UserOrAutomatedFilterButtons}
            defaultSelectedTab={dashboardState.topBarFilter.taskOwnerTypeFilter}
            onSelectionChange={selectedTabFilter =>
              handleTopBarFilterClick(selectedTabFilter as TaskOwnerType, false)
            }
          />
        </DashboardFilterGrid>
      </DashboardTopBarGrid>
    );
  };

  const handleTimeRangeChange = async (timeRange: RangeType) => {
    if (
      dashboardState.selectedInfoCard?.nameId ===
      DashboardConstants.INFO_CARD.COMPLETED
    ) {
      setDashboardState(
        produce(draft => {
          draft.timeRange = timeRange;
        }),
      );
      localStorage.setItem(Storage.KEYS.TIME_RANGE, JSON.stringify(timeRange));
      const updatedInfoCardList = dashboardState.infoCardData.map(
        infoCardItem => {
          if (infoCardItem.nameId === DashboardConstants.INFO_CARD.COMPLETED) {
            const updatedInfoCardItem = { ...infoCardItem };
            updatedInfoCardItem.count = <Loader size={28} />;
            return updatedInfoCardItem;
          }
          return infoCardItem;
        },
      );

      setDashboardState(
        produce(draft => {
          draft.infoCardData = updatedInfoCardList;
        }),
      );

      const activeInfoCard = await fetchCompleteTaskDetails(timeRange);

      setDashboardState(
        produce(draft => {
          draft.fetchDataFnQueryKeyName =
            DashboardConstants.USER_TABLE.USER_QUEUE_TABLE_NAME +
            generateUniqueKey();
          draft.isUserQueueGridLoading = true;
          draft.isQueueGridLoading = true;
        }),
      );

      if (activeInfoCard) {
        init(activeInfoCard as InfoCardDataType);
      }
    }
  };

  const renderInfoCardSection = (): JSX.Element => {
    return (
      <>
        {dashboardState.infoCardData.map(eachCard => {
          return (
            <InfoCard
              key={eachCard.label}
              isactive={
                eachCard?.label === dashboardState.selectedInfoCard?.label
                  ? BOOLEAN_TYPES.YES
                  : BOOLEAN_TYPES.NO
              }
              israngebuttonneeded={
                eachCard?.filterNeeded ? BOOLEAN_TYPES.YES : BOOLEAN_TYPES.NO
              }
              count={eachCard.count}
              label={eachCard.label}
              timeRangeValue={dashboardState.timeRange}
              onTimeRangeChange={timeRange =>
                handleTimeRangeChange(timeRange as RangeType)
              }
              onClick={(e: React.SyntheticEvent, currentCard?: string) =>
                handleInfoCardClick(e, eachCard)
              }
            />
          );
        })}
      </>
    );
  };

  // formatting cells and attaching onClick event listeners
  const columnCellsDefinitions = (columns: any[]): MRTColumnDef<any>[] => {
    return columns.map(column => {
      if (column.accessorKey === DashboardConstants.QUEUE_TABLE.COLUMNS.LABEL) {
        return {
          ...column,
          Cell: ({
            row,
            column: columnData,
          }: {
            row: MRTRow<any>;
            column: MRTColumnDef<any>;
          }) => {
            return (
              <QueueTypeCellDiv
                isRowExpanded={
                  row.depth === 1 ? BOOLEAN_TYPES.YES : BOOLEAN_TYPES.NO
                }
              >
                <QueueTypeCellTypography
                  onClick={() => tableCellClickHandler(row, columnData)}
                >
                  {row.original[column.accessorKey]}
                </QueueTypeCellTypography>
              </QueueTypeCellDiv>
            );
          },
        };
      } else if (
        column.accessorKey === DashboardConstants.USER_TABLE.COLUMNS.USER_NAME
      ) {
        return {
          ...column,
          Cell: ({
            row,
            column: columnData,
          }: {
            row: MRTRow<any>;
            column: MRTColumnDef<any>;
          }) => {
            const { userName = '', stateNsa = '', userId = '' } = row.original;
            return (
              <UserCellWrapperDiv>
                <UserCellImageWrapperDiv>
                  {profilePicsUrlsWithLoaderFlagById[userId]
                    ?.isProfilePicLoading ? (
                    <UserCellProfilePictureLoadingDiv>
                      <Loader size={20} />
                    </UserCellProfilePictureLoadingDiv>
                  ) : (
                    <UserImageAvatar
                      alt={userName}
                      src={
                        profilePicsUrlsWithLoaderFlagById[userId]
                          ?.profilePicUrl ?? ''
                      }
                    />
                  )}
                </UserCellImageWrapperDiv>
                <UserCellContentDiv>
                  <UserCellNameTypography
                    onClick={e => tableCellClickHandler(row, columnData)}
                  >
                    {row.original[column.accessorKey]}
                  </UserCellNameTypography>
                  <UserCellStateTypography>
                    {mapStateNsaLabel(stateNsa)}
                  </UserCellStateTypography>
                </UserCellContentDiv>
              </UserCellWrapperDiv>
            );
          },
        };
      }

      return {
        ...column,
        Cell: ({
          row,
          column: columnData,
        }: {
          row: MRTRow<any>;
          column: MRTColumnDef<any>;
        }) => {
          return (
            <DefaultCellDiv>
              <DefaultCellTypography
                onClick={e => tableCellClickHandler(row, columnData)}
              >
                {row.original[column.accessorKey]}
              </DefaultCellTypography>
            </DefaultCellDiv>
          );
        },
      };
    });
  };

  const getQueryParams = () => {
    let status;
    let range;
    if (
      dashboardState.selectedInfoCard?.nameId ===
      DashboardConstants.INFO_CARD.COMPLETED
    ) {
      status = DashboardConstants.INFO_CARD.COMPLETED as StatusType;
      range = dashboardState.timeRange as RangeType;
    } else {
      status = DashboardConstants.INFO_CARD.OPEN as StatusType;
    }

    const queryParams = {
      status: status as StatusType,
      range: range as RangeType,
      taskOwnerType: dashboardState.topBarFilter
        .taskOwnerTypeFilter as TaskOwnerType,
      stateNsa: dashboardState.topBarFilter.stateNSAFilter as StateNsaType,
      assigned: dashboardState.queueGridFilter.toLowerCase() as AssignedType,
    };

    return queryParams;
  };

  const renderQueueSection = (): JSX.Element => {
    const columnsWithCellDefinitions = columnCellsDefinitions(queueColumns);
    return (
      <DashboardTableSectionGrid>
        <DashboardTableHeaderGrid>
          <DashboardTableHeaderTypography>
            {t('PRIVATE.DASHBOARD.QUEUE_HEADING')}
          </DashboardTableHeaderTypography>
          <TabFilter
            isDefaultTabFilterOptionsNeeded={true}
            onSelectionChange={selectedTabFilter =>
              handleTableFilterChange(selectedTabFilter, true)
            }
          />
        </DashboardTableHeaderGrid>

        {dashboardState.isQueueGridLoading ? (
          <QueueGridLoadingDiv>
            <Loader />
          </QueueGridLoadingDiv>
        ) : (
          <QueryClientProvider client={queryClient}>
            <DashboardDataGrid
              isSubRowsPresent={true}
              columns={columnsWithCellDefinitions}
              data={dashboardState.queueGridData}
              queryParams={getQueryParams()}
              isExpandingEnabled={true}
              isSortingEnabled={false}
              isExpandAllEnabled={false}
              isRowActionsEnabled={false}
              isRowSelectionEnabled={false}
            />
          </QueryClientProvider>
        )}
      </DashboardTableSectionGrid>
    );
  };

  const renderUserQueueSection = (): JSX.Element => {
    const columnsWithCellDefinitions = columnCellsDefinitions(userColumns);
    return (
      <DashboardTableSectionGrid>
        <DashboardTableHeaderGrid>
          <DashboardTableHeaderTypography>
            {t('PRIVATE.DASHBOARD.USER_QUEUE_HEADING')}
          </DashboardTableHeaderTypography>
          <TabFilter
            filterOptions={userFilterButtons}
            onSelectionChange={selectedTabFilter =>
              handleTableFilterChange(selectedTabFilter, false)
            }
            defaultSelectedTab={dashboardState.userQueueGridFilter}
          />
        </DashboardTableHeaderGrid>
        <DashboardServerSideDataGrid
          fetchDataFnQueryKeyName={dashboardState.fetchDataFnQueryKeyName}
          columns={columnsWithCellDefinitions}
          fetchInfiniteQueryDataFn={fetchInfiniteQueryDataFn}
          dataFetchSize={dashboardState.userQueueFetchSize}
          totalRowsCount={dashboardState.userQueueTotalRowCount}
          enableSorting={false}
          state={{ isLoading: dashboardState.isUserQueueGridLoading }}
          isDataLoading={dashboardState.isUserQueueGridLoading}
        />
      </DashboardTableSectionGrid>
    );
  };

  return (
    <DashboardWrapperGrid>
      {/* Top Bar Section */}
      <TopBarWrapperGrid
        alignItems="center"
        justifyContent="center"
        height="5rem"
      >
        {renderTopBar()}
      </TopBarWrapperGrid>

      <DashboardSectionGridWrapper>
        {/* Info Card Section */}
        {dashboardState.isInfoCardLoading ? (
          <InfoCardLoadingDiv>
            <Loader />
          </InfoCardLoadingDiv>
        ) : (
          <DashboardInfoCardWrapperGrid>
            {renderInfoCardSection()}
          </DashboardInfoCardWrapperGrid>
        )}

        <DashboardTableSectionWrapperGrid>
          {/* Queue Section */}
          <DashboardTableContainerGrid>
            {renderQueueSection()}
          </DashboardTableContainerGrid>

          {/* User Queue Section */}
          <DashboardTableContainerGrid>
            {renderUserQueueSection()}
          </DashboardTableContainerGrid>
        </DashboardTableSectionWrapperGrid>
      </DashboardSectionGridWrapper>
    </DashboardWrapperGrid>
  );
};

export default Dashboard;
