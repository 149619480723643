import { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useTranslation from 'i18next';
import { produce } from 'immer';

import {
  EmailSettingsDetailsWrapperGrid,
  EmailSettingsDetailsHeadingSectionGrid,
  EmailSettingsDetailsContentSectionGrid,
  DropDownInputSectionGrid,
  DropDownInputSectionTypography,
  EmailViewerSectionGrid,
  NavigationTypography,
  TopBarContentGrid,
  EmailDetailsLoadingGrid,
  EmailDetailsNoDataGrid,
  EmailDetailsTypography,
  DropDownInputSectionWrapperGrid,
  DropDownInputGrid,
  DropdownLabelTypography,
  DropdownLoaderGrid,
} from './EmailSettingDetailsStyle';
import { APP } from 'src/styles/variables';

import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import Loader from '@components/loaders/Loader';
import EmailRichTextViewer from '@components/email-rich-text-viewer/EmailRichTextViewer';
import GroupedSelectInput from '@components/select-input/GroupedSelectInput';
import SelectInput from '@components/select-input/SelectInput';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { getErrorMessage } from '@utils/ErrorUtils';
import { formatDateTime } from '@modules/home/utils/Utils';

import {
  fetchEmailSettingsDetail,
  updateEmailSettingsDetail,
} from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import { GetEmailSettingsDetailParams } from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import {
  getAllStates,
  getAllTaskTypes,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import {
  DropdownOptionsType,
  GroupedFilterOptionType,
} from 'src/types/adminTypes';
import axios, { CancelTokenSource } from 'axios';

interface IEmailDetails {
  id: string;
  emailSubject: string;
  emailFromValue: string;
  emailToValue: string;
  cc?: string;
  stateNsa: string;
  emailReceivedAt: string;
  emailAttachments?: any[];
  emailBodyValue?: string;
}

interface IEmailSettingsDetailsState {
  isEmailDetailsLoading: boolean;
  emailId: string;
  emailDetails: IEmailDetails;
  shouldDialogOpen: boolean;
  isDialogContentLoading: boolean;
  dialogTitle: string;
  dialogContent: string;
  categoryDropdownOptions: GroupedFilterOptionType[] | DropdownOptionsType[];
  stateOrNSADropdownOptions: DropdownOptionsType[];
  categoryDropdownValue: string;
  categoryDropdownId: string;
  stateOrNSADropdownValue: string;
  stateOrNSADropdownId: string;
  isCategoryDropdownLoading: boolean;
  isStateOrNSADropdownLoading: boolean;
}

type TaskTypesArray = {
  taskId: string;
  taskLabel: string;
  taskName: string;
  taskType: string;
  categoryLabel?: string;
};

type FormattedTaskTypesResponse = {
  categoryId?: string;
  categoryLabel?: string;
  categoryNameId?: string;
  taskTypes?: TaskTypesArray[] | null;
  taskId?: string;
  taskLabel?: string;
  taskName?: string;
  taskType?: string;
};

const EmailSettingDetails = (): ReactElement => {
  const { t } = useTranslation;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { emailID } = useParams();
  const [state, setState] = useState<IEmailSettingsDetailsState>({
    isEmailDetailsLoading: true,
    emailId: '',
    emailDetails: {
      id: '',
      emailSubject: '',
      emailFromValue: '',
      emailToValue: '',
      cc: '',
      stateNsa: '',
      emailReceivedAt: '',
      emailAttachments: [],
      emailBodyValue: '',
    },
    shouldDialogOpen: false,
    isDialogContentLoading: false,
    dialogTitle: '',
    dialogContent: '',
    categoryDropdownValue: '',
    stateOrNSADropdownValue: '',
    categoryDropdownOptions: [],
    stateOrNSADropdownOptions: [],
    isCategoryDropdownLoading: true,
    isStateOrNSADropdownLoading: true,
    categoryDropdownId: '',
    stateOrNSADropdownId: '',
  });

  const cancelTokenSourceForEmailActivity = useRef<CancelTokenSource | null>(
    null,
  );

  const fetchEmailActivity = async (
    emailId: GetEmailSettingsDetailParams,
    categoryDropdownOptions: any[],
    stateOrNSADropdownOptions: any[],
  ) => {
    cancelTokenSourceForEmailActivity.current = axios.CancelToken.source();

    try {
      const response = await fetchEmailSettingsDetail(
        emailId,
        cancelTokenSourceForEmailActivity.current,
      );

      const {
        id = '',
        fromEmail = '',
        toEmail = '',
        receivedAt = '',
        cc = '',
        files = [],
        subject = '',
        body = '',
        taskTypeId = '',
        stateNsa = '',
        stateId = '',
      } = response?.data || {};

      const categoryDropdownOptionsArr = [...categoryDropdownOptions];
      const stateOrNSADropdownOptionsArr = [...stateOrNSADropdownOptions];

      categoryDropdownOptionsArr.map(categoryOptionItem => {
        if (categoryOptionItem.optionId === taskTypeId) {
          if (categoryOptionItem.optionText) {
            setState(
              produce(draft => {
                draft.categoryDropdownId = taskTypeId;
                draft.categoryDropdownOptions = categoryDropdownOptions;
                draft.categoryDropdownValue =
                  categoryOptionItem?.optionText ?? '';
                draft.isCategoryDropdownLoading = false;
              }),
            );
          }
        }
      });

      stateOrNSADropdownOptionsArr.map(stateOrNSAOptionItem => {
        if (stateOrNSAOptionItem.optionId === stateId) {
          if (stateOrNSAOptionItem.optionText) {
            setState(
              produce(draft => {
                draft.stateOrNSADropdownId = stateId;
                draft.stateOrNSADropdownOptions = stateOrNSADropdownOptionsArr;
                draft.stateOrNSADropdownValue = stateOrNSAOptionItem.optionText;
                draft.isStateOrNSADropdownLoading = false;
              }),
            );
          }
        } else if (
          stateId === null &&
          stateNsa &&
          stateNsa.toLowerCase() === AdminConstants.USERS.NSA.toLowerCase()
        ) {
          setState(
            produce(draft => {
              draft.stateOrNSADropdownId = '';
              draft.stateOrNSADropdownOptions = stateOrNSADropdownOptionsArr;
              draft.stateOrNSADropdownValue = AdminConstants.USERS.NSA;
              draft.isStateOrNSADropdownLoading = false;
            }),
          );
        }
      });

      setState(
        produce(draft => {
          draft.emailDetails.id = id;
          draft.emailDetails.emailSubject = subject;
          draft.emailDetails.emailFromValue = fromEmail;
          draft.emailDetails.emailToValue = toEmail;
          draft.emailDetails.emailReceivedAt = receivedAt;
          draft.emailDetails.cc = cc;
          draft.emailDetails.stateNsa = stateNsa;
          draft.emailDetails.emailAttachments = files;
          draft.emailDetails.emailBodyValue = body;
          draft.isEmailDetailsLoading = false;
          draft.isCategoryDropdownLoading = false;
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
    } catch (e) {
      setState(
        produce(draft => {
          draft.isEmailDetailsLoading = false;
          draft.isCategoryDropdownLoading = false;
          draft.isStateOrNSADropdownLoading = false;
        }),
      );

      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      cancelTokenSourceForEmailActivity.current = null;
    }
  };

  const cancelTokenSourceForCategoryDropdownOptions =
    useRef<CancelTokenSource | null>(null);

  const fetchCategoryDropdownOptions = async () => {
    cancelTokenSourceForCategoryDropdownOptions.current =
      axios.CancelToken.source();
    try {
      const payload = undefined;
      const response = await getAllTaskTypes(
        payload,
        cancelTokenSourceForCategoryDropdownOptions.current,
      );
      const { data } = response;

      const formattedFlatArray: FormattedTaskTypesResponse[] = [];
      const rowData = data?.rows ? [...data.rows] : [];

      // making the nested dropdown options into flat array
      rowData.map(rowItem => {
        const obj: FormattedTaskTypesResponse = { ...rowItem };
        const taskTypesArray = obj.taskTypes;
        obj.taskTypes = null;
        formattedFlatArray.push(obj);
        if (taskTypesArray) {
          taskTypesArray.map(taskTypeItem => {
            const taskTypeItemObj = { ...taskTypeItem };
            taskTypeItemObj.categoryLabel = rowItem.categoryLabel;
            formattedFlatArray.push(taskTypeItemObj);
          });
        }
      });

      // mapping flat list data into dropdown options format to handle it in select input dropdown
      const formattedData = formattedFlatArray.map(flatItem => {
        const finalObj: GroupedFilterOptionType = {
          optionId: '',
          optionText: '',
          listHeader: '',
        };

        if (flatItem.categoryId) {
          finalObj.optionId = flatItem.categoryId;
          finalObj.optionText = null;
          finalObj.listHeader = flatItem.categoryLabel;
          return finalObj;
        } else {
          finalObj.optionId = flatItem.taskId;
          finalObj.optionText = flatItem.taskLabel;
          finalObj.listHeader = flatItem.categoryLabel;
          return finalObj;
        }
      });

      setState(
        produce(draft => {
          draft.categoryDropdownOptions = [...formattedData];
        }),
      );

      return formattedData;
    } catch (e) {
      setState(
        produce(draft => {
          draft.isCategoryDropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      cancelTokenSourceForCategoryDropdownOptions.current = null;
    }
  };

  const cancelTokenSourceForStateDropdownOptions =
    useRef<CancelTokenSource | null>(null);

  const fetchStateDropdownOptions = async () => {
    cancelTokenSourceForStateDropdownOptions.current =
      axios.CancelToken.source();
    try {
      const payload = undefined;
      const response = await getAllStates(
        payload,
        cancelTokenSourceForStateDropdownOptions.current,
      );
      const { data } = response;

      const RowData = data?.rows ? [...data.rows] : [];
      const formattedStateOrNSAOptions: any = [];

      // adding NSA dropdown option
      formattedStateOrNSAOptions.push({
        optionId: AdminConstants.USERS.STATENSA,
        optionText: AdminConstants.USERS.NSA,
      });

      // mapping list data into dropdown options format to handle it in select input dropdown
      RowData.map(rowItem => {
        const obj: any = {};
        obj.optionId = rowItem.id;
        obj.optionText = rowItem.label;

        formattedStateOrNSAOptions.push(obj);
      });

      setState(
        produce(draft => {
          draft.stateOrNSADropdownOptions = formattedStateOrNSAOptions;
        }),
      );

      return formattedStateOrNSAOptions;
    } catch (e) {
      setState(
        produce(draft => {
          draft.isStateOrNSADropdownLoading = false;
        }),
      );
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      cancelTokenSourceForStateDropdownOptions.current = null;
    }
  };

  const init = async () => {
    const [categoryDropdownOptionsRes, stateOrNSADropdownOptionsRes] =
      await Promise.all([
        fetchCategoryDropdownOptions(),
        fetchStateDropdownOptions(),
      ]);

    if (emailID && categoryDropdownOptionsRes && stateOrNSADropdownOptionsRes) {
      setState(
        produce(draft => {
          draft.emailId = emailID;
        }),
      );
      await fetchEmailActivity(
        emailID,
        categoryDropdownOptionsRes,
        stateOrNSADropdownOptionsRes,
      );
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForEmailActivity.current) {
        cancelTokenSourceForEmailActivity.current.cancel();
      }
      if (cancelTokenSourceForCategoryDropdownOptions.current) {
        cancelTokenSourceForCategoryDropdownOptions.current.cancel();
      }
      if (cancelTokenSourceForStateDropdownOptions.current) {
        cancelTokenSourceForStateDropdownOptions.current.cancel();
      }
    };
  }, []);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateEmailDetails = async (
    dropdownValue: string | HTMLInputElement | undefined,
  ) => {
    const payload: any = {
      taskTypeId: '',
      stateId: '',
    };

    let isStateOrNSADropdownSelected;
    const foundObject = state.categoryDropdownOptions.find(
      obj => obj.optionText === dropdownValue,
    );

    if (foundObject) {
      isStateOrNSADropdownSelected = false;
    } else {
      isStateOrNSADropdownSelected = true;
    }

    if (!isStateOrNSADropdownSelected) {
      state.categoryDropdownOptions.map(categoryOptionItem => {
        if (categoryOptionItem.optionText === dropdownValue) {
          payload.taskTypeId = categoryOptionItem.optionId;
          delete payload.stateId;
        }
      });
    } else {
      state.stateOrNSADropdownOptions.map((stateOrNSAOptionItem: any) => {
        if (stateOrNSAOptionItem.optionText === dropdownValue) {
          if (dropdownValue === AdminConstants.USERS.NSA) {
            payload.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
            delete payload.taskTypeId;
            delete payload.stateId;
          } else {
            payload.stateNsa = AdminConstants.USERS.STATE.toLowerCase();
            payload.stateId = stateOrNSAOptionItem.optionId;
            delete payload.taskTypeId;
          }
        }
      });
    }

    try {
      const finalPayload = {
        pathParam: state.emailId,
        bodyParams: payload,
      };
      const response = await updateEmailSettingsDetail(finalPayload);
      const message = response.data.message;
      enqueueSnackbar(message, SnackBarConfig.getSuccess());
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const handleDropdownChange = (
    data: string | HTMLInputElement | undefined,
  ) => {
    updateEmailDetails(data);
  };

  const renderTopBar = () => {
    return (
      <TopBarWrapperGrid
        alignItems="center"
        justifyContent="center"
        height="5rem"
      >
        <TopBarContentGrid>
          <NavigationTypography onClick={() => navigate(-1)}>
            {t('PRIVATE.EMAIL_SETTINGS.BACK_TO_EMAILS_SETTINGS')}
          </NavigationTypography>
        </TopBarContentGrid>
      </TopBarWrapperGrid>
    );
  };

  const renderDropDownSelectInputSection = () => {
    return (
      <DropDownInputSectionWrapperGrid>
        <DropDownInputSectionTypography>
          {t(
            'PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_DETAILS.CLASSIFICATION_AND_TASK_DETAILS',
          )}
        </DropDownInputSectionTypography>
        <DropDownInputGrid>
          <DropdownLabelTypography>
            {t(
              'PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_DETAILS.CLASSIFICATION_NAME',
            )}
          </DropdownLabelTypography>
          {state.isCategoryDropdownLoading ? (
            <DropdownLoaderGrid>
              <Loader size={25} />
            </DropdownLoaderGrid>
          ) : (
            <GroupedSelectInput
              groupedFilterOptions={state.categoryDropdownOptions}
              isgroupingstylingneeded={'yes'}
              preSelectedValue={state.categoryDropdownValue ?? ''}
              handleOptionSelect={data => handleDropdownChange(data)}
              backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
              borderRadius={'0'}
              width={'100%'}
            />
          )}
        </DropDownInputGrid>
        <DropDownInputGrid>
          <DropdownLabelTypography>
            {t('PRIVATE.EMAIL_SETTINGS.EMAIL_SETTINGS_DETAILS.STATE_OR_NSA')}
          </DropdownLabelTypography>
          {state.isStateOrNSADropdownLoading ? (
            <DropdownLoaderGrid>
              <Loader size={25} />
            </DropdownLoaderGrid>
          ) : (
            <SelectInput
              dropdownOptions={state.stateOrNSADropdownOptions}
              showSelectedOption={true}
              preSelectedValue={state.stateOrNSADropdownValue ?? ''}
              isgroupingstylingneeded={'yes'}
              backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
              isDefaultValuePresent={true}
              borderRadius={'0'}
              width={'100%'}
              handleChange={data => handleDropdownChange(data)}
            />
          )}
        </DropDownInputGrid>
      </DropDownInputSectionWrapperGrid>
    );
  };

  const renderEmailViewer = () => {
    if (!state.isEmailDetailsLoading && state.emailDetails.emailSubject) {
      return (
        <EmailRichTextViewer
          fromEmail={state.emailDetails.emailFromValue}
          toEmail={state.emailDetails.emailToValue}
          cc={state.emailDetails.cc ?? ''}
          files={state.emailDetails.emailAttachments ?? []}
          subject={state.emailDetails.emailSubject}
          body={state.emailDetails.emailBodyValue ?? ''}
          receivedAt={formatDateTime(state.emailDetails.emailReceivedAt)}
        />
      );
    } else if (state.isEmailDetailsLoading) {
      return (
        <EmailDetailsLoadingGrid>
          <Loader />
        </EmailDetailsLoadingGrid>
      );
    } else {
      return (
        <EmailDetailsNoDataGrid>
          <EmailDetailsTypography>
            {t('PRIVATE.EMAILS.EMAIL_DETAILS.NO_EMAIL_DETAILS')}
          </EmailDetailsTypography>
        </EmailDetailsNoDataGrid>
      );
    }
  };

  return (
    <EmailSettingsDetailsWrapperGrid>
      <EmailSettingsDetailsHeadingSectionGrid>
        {renderTopBar()}
      </EmailSettingsDetailsHeadingSectionGrid>

      <EmailSettingsDetailsContentSectionGrid>
        <EmailViewerSectionGrid>{renderEmailViewer()}</EmailViewerSectionGrid>

        <DropDownInputSectionGrid>
          {renderDropDownSelectInputSection()}
        </DropDownInputSectionGrid>
      </EmailSettingsDetailsContentSectionGrid>
    </EmailSettingsDetailsWrapperGrid>
  );
};

export default EmailSettingDetails;
