import ServiceRequest from 'src/services/ServiceRequest';
import { updateTaskTypeAutomationPauseUrl } from './TaskTypeApiEndPoints';
import { UpdateTaskTypeAutomationPauseResDto } from './TaskTypeServiceMapper';

export const updateTaskTypeAutomationPause = async (
  id = '',
): Promise<UpdateTaskTypeAutomationPauseResDto> => {
  return await ServiceRequest.put(
    updateTaskTypeAutomationPauseUrl.replace(':id', id),
  );
};
