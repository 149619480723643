import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import {
  AutomationSettingsStateType,
  SetIsAutomationSettingsDataLoadingPayloadType,
  SetIsRefetchingNeededPayloadType,
} from './AutomationSettingsTypes';

const initialState: AutomationSettingsStateType = {
  automationSettingsTableName:
    AdminConstants.AUTOMATION_SETTINGS
      .AUTOMATION_SETTINGS_TABLE_NAME_WITHOUT_FILTER,
  isRefetchingNeeded: false,
  isAutomationSettingsDataLoading: false,
};

const AutomationSettingsSlice = createSlice({
  initialState,
  name: 'automationSettings',
  reducers: {
    changeAutomationSettingsTableName(state) {
      state.automationSettingsTableName = generateRandomTableName(
        AdminConstants.AUTOMATION_SETTINGS
          .AUTOMATION_SETTINGS_TABLE_NAME_WITH_FILTERS,
      );
    },
    setIsRefetchingNeeded(
      state,
      action: PayloadAction<SetIsRefetchingNeededPayloadType>,
    ) {
      state.isRefetchingNeeded = action.payload.isRefetchingNeededPayload;
    },
    setIsAutomationSettingsDataLoading(
      state,
      action: PayloadAction<SetIsAutomationSettingsDataLoadingPayloadType>,
    ) {
      state.isAutomationSettingsDataLoading =
        action.payload.isAutomationSettingsDataLoadingPayload;
    },
  },
});

export const {
  changeAutomationSettingsTableName,
  setIsRefetchingNeeded,
  setIsAutomationSettingsDataLoading,
} = AutomationSettingsSlice.actions;

export default AutomationSettingsSlice.reducer;
