import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  ListGridContainer,
  LoaderContainer,
  QueueAssignmentsByTypeBox,
} from './QueueAssignmentsByTypeStyle';
import AssignmentCategory from './assignment-category/AssignmentCategory';
import { produce } from 'immer';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { getAllTaskCategories } from 'src/services/service-handlers/private/task-categories-handlers/TaskCategoriesPrivateService';
import { GetAllTaskCategoriesDataRow } from 'src/services/service-handlers/private/task-categories-handlers/TaskCategoriesServiceMapper';
import { useSnackbar } from 'notistack';
import Loader from '@components/loaders/Loader';
import { ProfilePicRefType } from 'src/types/Types';
import axios, { CancelTokenSource } from 'axios';

interface ITaskCategoriesListItem {
  taskCategoryId: string;
  taskCategoryName: string;
}

interface IQueueAssignmentsByTypeState {
  isAllCategoriesLoading: boolean;
  isTaskCountSummaryByTaskCategoryIdLoading: boolean;
  taskCategoriesList: ITaskCategoriesListItem[];
}

const initialQueueAssignmentsByTypeState: IQueueAssignmentsByTypeState = {
  isAllCategoriesLoading: false,
  isTaskCountSummaryByTaskCategoryIdLoading: false,
  taskCategoriesList: [],
};

const allTaskCategoriesToTaskCategoriesListMapper = (
  allTaskCategories: GetAllTaskCategoriesDataRow[],
): ITaskCategoriesListItem[] => {
  if (allTaskCategories.length > 0)
    return allTaskCategories.map(({ id, label }) => ({
      taskCategoryId: id,
      taskCategoryName: label,
      taskCountSummary: {
        unassigned: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
    }));
  return [];
};

const QueueAssignmentsByType = (): ReactElement => {
  const [queueAssignmentsByTypeState, setQueueAssignmentsByTypeState] =
    useState<IQueueAssignmentsByTypeState>(initialQueueAssignmentsByTypeState);

  const { enqueueSnackbar } = useSnackbar();

  const fetchedProfilePicRef = useRef<ProfilePicRefType>({});

  const setFetchProfilePicRef = (userId: string) => {
    fetchedProfilePicRef.current[userId] = true;
  };

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  // API section starts

  const fetchAllTaskCategories = async () => {
    cancelTokenSource.current = axios.CancelToken.source();

    setQueueAssignmentsByTypeState(
      produce(draft => {
        draft.isAllCategoriesLoading = true;
      }),
    );
    try {
      const payload = undefined;
      const getAllTaskCategoriesResponse = await getAllTaskCategories(
        payload,
        cancelTokenSource.current,
      );
      const formattedTaskCategoriesList =
        allTaskCategoriesToTaskCategoriesListMapper(
          getAllTaskCategoriesResponse?.data?.rows ?? [],
        );

      setQueueAssignmentsByTypeState(
        produce(draft => {
          draft.taskCategoriesList = formattedTaskCategoriesList;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      setQueueAssignmentsByTypeState(
        produce(draft => {
          draft.isAllCategoriesLoading = false;
        }),
      );
      cancelTokenSource.current = null;
    }
  };

  // API section ends

  const init = async () => {
    await fetchAllTaskCategories();
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
    };
  }, []);

  const renderListGridSection = () => {
    return queueAssignmentsByTypeState.taskCategoriesList.map(
      taskCategoriesListItem => {
        return (
          <AssignmentCategory
            key={taskCategoriesListItem.taskCategoryId}
            categoryName={taskCategoriesListItem.taskCategoryName}
            taskCategoryId={taskCategoriesListItem.taskCategoryId}
            fetchedProfilePicRef={fetchedProfilePicRef}
            setFetchProfilePicRef={setFetchProfilePicRef}
          />
        );
      },
    );
  };

  return (
    <QueueAssignmentsByTypeBox>
      <ListGridContainer
        container
        direction={'column'}
        height={'calc(100vh - 5.2rem)'}
      >
        {queueAssignmentsByTypeState.isAllCategoriesLoading ||
        queueAssignmentsByTypeState.isTaskCountSummaryByTaskCategoryIdLoading ? (
          <LoaderContainer>
            <Loader size={60} />
          </LoaderContainer>
        ) : (
          renderListGridSection()
        )}
      </ListGridContainer>
    </QueueAssignmentsByTypeBox>
  );
};
export default QueueAssignmentsByType;
