import {
  // ChangeEvent, SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { produce } from 'immer';
import { t } from 'i18next';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  BulkEditTextButton,
  BulkEditTextButtonForUnClassifiedTab,
  FilterIconButton,
  FilterMenuContainer,
  QueueMainContainer,
  QueueTabBodyContainer,
  RightMenuOptionsContainer,
  // SearchBarStyled,
  // SearchFieldContainer,
  TabFilterWrapper,
  TabItem,
  TabItemText,
  TabsContainer,
} from './QueueStyle';

import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import TabFilter from '@components/tabs/tab-filter/TabFilter';
import SearchAndFilterDialog, {
  ISearchAndFilterDialogProps,
} from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import { QUEUE_SCREEN_TABS } from '@constants/global-constants/constants';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import QUEUE_TABS_SEARCH_AND_FILTER_OPTIONS_CONFIG from './SearchAndFilterConfigurations';
import {
  AdminQueueTabItemType,
  FilterConfigurationType,
} from 'src/types/adminTypes';
import {
  setSearchAndFilterConfiguration,
  // changeTableName,
  // reloadQueueTable,
  resetQueueState,
  // searchByCaseId,
  showBulkChangeTypePopUp,
  showBulkReAssignDialogPopUp,
  setIsRefetchingNeeded,
  setQueueSearchAndFilterOptions,
  changeTableName,
  changeAssignmentByUserTableName,
  setRefreshTaskCountSummaryForCategory,
} from '@modules/home/state/queue/QueueSlice';
import { RootState } from 'src/store/Store';
// import { useDebouncedCallback } from 'use-debounce';
// import { SEARCH_DEBOUNCE_DELAY_IN_MS } from '@constants/global-constants/constants';
import Filter from '@assets/images/Filter.svg';
import { executeAfterTimeout } from '@modules/home/utils/Utils';
import { QueueTaskStatusTypes } from './queue-constants/QueueConstants';

interface ISearchAndFilterDialogState {
  shouldDialogOpen: boolean;
  currentFilterConfiguration: FilterConfigurationType;
}

interface IQueueStateProps {
  assignmentsRoute: boolean;
  isSelectedTaskNotFlagged: boolean | undefined;
}

const Queue = (): JSX.Element => {
  // const [searchText, setSearchText] = useState('');
  const [searchAndFilterDialogState, setSearchAndFilterDialogState] =
    useState<ISearchAndFilterDialogState>({
      shouldDialogOpen: false,
      currentFilterConfiguration: {
        SEARCH_BY_OPTIONS: [],
        FILTER_OPTIONS: [],
      },
    });
  const [queueState, setQueueState] = useState<IQueueStateProps>({
    assignmentsRoute: false,
    isSelectedTaskNotFlagged: true,
  });
  const { assignmentsRoute, isSelectedTaskNotFlagged } = queueState;

  const location = useLocation();

  const locationState = location.state;

  const dispatch = useDispatch();

  const {
    queueSearchAndFilterOptions,
    showBulkActionMenuButton,
    queueRowStatusData,
  } = useSelector((state: RootState) => state.queue);

  const tabsFilter = [
    { label: 'By Type', value: 'By Type' },
    { label: 'By User', value: 'By User' },
  ];

  const [selectedTab, setSelectedTab] = useState(tabsFilter[0].value);

  const AdminQueueTabList: AdminQueueTabItemType[] = [
    {
      name: t('ADMIN.QUEUE_TABS.QUEUE'),
      isActive:
        location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueTable.FullPath ||
        location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
          .Path,
    },
    {
      name: t(`ADMIN.QUEUE_TABS.QUEUE_AI`),
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueQueueAI.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueQueueAI.Path,
    },
    {
      name: t(`ADMIN.QUEUE_TABS.UNCLASSIFIED`),
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.Path,
    },

    {
      name: t(`ADMIN.QUEUE_TABS.HISTORY`),
      isActive:
        location.pathname ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueHistory.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueHistory.Path,
    },
    {
      name: t(`ADMIN.QUEUE_TABS.ASSIGNMENTS`),
      isActive:
        location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.ChildRoutes.QueueAssignmentsByType.FullPath ||
        location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.FullPath,
      navigationURL:
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueAssignments.Path,
    },
  ];

  const [adminQueueTabList, setAdminQueueTabList] = useState(AdminQueueTabList);

  const navigate = useNavigate();

  const setCurrentActiveTab = (menuItemName: string) => {
    const newAdminQueueTabList = adminQueueTabList.map(
      (newAdminQueueTabItem: AdminQueueTabItemType) => ({
        ...newAdminQueueTabItem,
        isActive:
          newAdminQueueTabItem.name.toLowerCase() ===
          menuItemName.toLowerCase(),
      }),
    );
    setAdminQueueTabList([...newAdminQueueTabList]);
  };

  // this is used to reset active queue tab to queue table when queue tab button is clicked
  useEffect(() => {
    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
          .FullPath,
      )
    ) {
      const currentMenuItemName = adminQueueTabList[0].name;
      setCurrentActiveTab(currentMenuItemName);
    }
    setSearchAndFilterDialogState(
      produce(draft => {
        draft.currentFilterConfiguration = {
          SEARCH_BY_OPTIONS: [],
          FILTER_OPTIONS: [],
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleTabChange = (data: string) => {
    data === t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.BY_USER')
      ? navigate(
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.FullPath,
        )
      : navigate(
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.Path,
        );

    dispatch(setQueueSearchAndFilterOptions({ data: {} }));
  };

  const renderAdminQueueTabItem = (
    adminQueueTabItem: AdminQueueTabItemType,
    index: number,
  ) => (
    <TabItem
      key={`${adminQueueTabItem.name}-${index}`}
      isActive={adminQueueTabItem.isActive}
      onClick={() => {
        setCurrentActiveTab(adminQueueTabItem.name);
        dispatch(resetQueueState());
        dispatch(changeTableName());
        // setSearchText('');
        adminQueueTabItem.name.toLowerCase() ===
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueAssignments.Path
          ? setQueueState(
              produce(draft => {
                draft.assignmentsRoute = true;
              }),
            )
          : setQueueState(
              produce(draft => {
                draft.assignmentsRoute = false;
              }),
            );
        navigate(adminQueueTabItem.navigationURL);
      }}
    >
      <TabItemText isActive={adminQueueTabItem.isActive}>
        {adminQueueTabItem.name}
      </TabItemText>
    </TabItem>
  );

  const renderAdminQueueTabs = () => (
    <TabsContainer>
      {adminQueueTabList.map(
        (adminQueueTabItem: AdminQueueTabItemType, index: number) =>
          renderAdminQueueTabItem(adminQueueTabItem, index),
      )}
    </TabsContainer>
  );

  const getSelectedTab = () => {
    if (location.pathname) {
      const splitValues = location.pathname.split('/');
      const lastRouteValue = splitValues[splitValues.length - 1];
      setSelectedTab(
        tabsFilter[
          lastRouteValue === t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.BY_USER.USER')
            ? 1
            : 0
        ].value,
      );
    }
  };

  useEffect(() => {
    renderAdminQueueTabs();
    getSelectedTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      if (
        !location.pathname.includes(
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.FullPath,
        )
      ) {
        dispatch(setQueueSearchAndFilterOptions({ data: {} }));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationState?.queueSelected) {
      setCurrentActiveTab(adminQueueTabList[0].name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  const handleBulkEditButtonClick = (isReassignButton?: boolean) => {
    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
          .FullPath,
      )
    ) {
      const showBulkReAssignDialogPayload = {
        showBulkReAssignDialog: true,
      };
      dispatch(showBulkReAssignDialogPopUp(showBulkReAssignDialogPayload));
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath,
      )
    ) {
      if (isReassignButton) {
        const showBulkReAssignDialogPayload = {
          showBulkReAssignDialog: true,
        };
        dispatch(showBulkReAssignDialogPopUp(showBulkReAssignDialogPayload));
      } else {
        const showBulkChangeTypeDialogPayload = {
          showBulkChangeTypeDialog: true,
        };
        dispatch(showBulkChangeTypePopUp(showBulkChangeTypeDialogPayload));
      }
    }
    // add other cases for different queue tabs
  };

  useEffect(() => {
    const isUnclassifiedTab = location.pathname.includes(
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueUnclassified.FullPath,
    );

    if (queueRowStatusData && isUnclassifiedTab) {
      const isTaskNotFlagged = queueRowStatusData.some(
        item => item.status !== QueueTaskStatusTypes.FLAGGED,
      );

      setQueueState(
        produce(draft => {
          draft.isSelectedTaskNotFlagged = isTaskNotFlagged;
        }),
      );
    }
  }, [queueRowStatusData, location.pathname]);

  const renderBulkActionButton = () => {
    let buttonLabel = '';
    const reassignButtonLabel = t(
      'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.ACTION_BUTTON_LABEL',
    );

    const isUnclassifiedTab = location.pathname.includes(
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueUnclassified.FullPath,
    );

    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
          .FullPath,
      )
    ) {
      buttonLabel = t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_RE_ASSIGN_USER_DIALOG.ACTION_BUTTON_LABEL',
      );
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
          .QueueUnclassified.FullPath,
      )
    ) {
      buttonLabel = t(
        'ADMIN.QUEUE_TABS.BULK_ACTION_DIALOG.BULK_CHANGE_QUEUE_TYPE_DIALOG.ACTION_BUTTON_LABEL',
      );
    }

    return isUnclassifiedTab ? (
      <BulkEditTextButtonForUnClassifiedTab>
        <BulkEditTextButton
          variant="contained"
          onClick={() => handleBulkEditButtonClick()}
        >
          {buttonLabel}
        </BulkEditTextButton>
        {!isSelectedTaskNotFlagged && (
          <BulkEditTextButton
            variant="contained"
            onClick={() => handleBulkEditButtonClick(true)}
          >
            {reassignButtonLabel}
          </BulkEditTextButton>
        )}
      </BulkEditTextButtonForUnClassifiedTab>
    ) : (
      <BulkEditTextButton
        variant="contained"
        onClick={() => handleBulkEditButtonClick()}
      >
        {buttonLabel}
      </BulkEditTextButton>
    );
  };

  const getSearchAndFilterConfiguration = (
    currentTabItem: AdminQueueTabItemType,
  ) => {
    const {
      QUEUE_SEARCH_AND_FILTER_CONFIG,
      QUEUE_AI_SEARCH_AND_FILTER_CONFIG,
      UNCLASSIFIED_SEARCH_AND_FILTER_CONFIG,
      HISTORY_SEARCH_AND_FILTER_CONFIG,
      ASSIGNEE_SEARCH_AND_FILTER_CONFIG,
    } = QUEUE_TABS_SEARCH_AND_FILTER_OPTIONS_CONFIG;
    switch (currentTabItem.name) {
      case QUEUE_SCREEN_TABS.QUEUE:
        return QUEUE_SEARCH_AND_FILTER_CONFIG;
      case QUEUE_SCREEN_TABS.QUEUE_AI:
        return QUEUE_AI_SEARCH_AND_FILTER_CONFIG;
      case QUEUE_SCREEN_TABS.UNCLASSIFIED:
        return UNCLASSIFIED_SEARCH_AND_FILTER_CONFIG;
      case QUEUE_SCREEN_TABS.HISTORY:
        return HISTORY_SEARCH_AND_FILTER_CONFIG;
      case QUEUE_SCREEN_TABS.ASSIGNMENTS:
        return ASSIGNEE_SEARCH_AND_FILTER_CONFIG;
      default:
        return {
          SEARCH_BY_OPTIONS: [],
          FILTER_OPTIONS: [],
        };
    }
  };

  const handleFilterIconButtonClick = () => {
    setSearchAndFilterDialogState(
      produce(draft => {
        draft.shouldDialogOpen = true;
      }),
    );
    adminQueueTabList.map(adminQueueTabItem => {
      if (adminQueueTabItem.isActive === true) {
        const filterAndSearchConfig =
          getSearchAndFilterConfiguration(adminQueueTabItem);
        if (filterAndSearchConfig) {
          dispatch(setSearchAndFilterConfiguration(filterAndSearchConfig));
          setSearchAndFilterDialogState(
            produce(draft => {
              draft.currentFilterConfiguration = filterAndSearchConfig;
            }),
          );
        }
      }
    });
  };

  const dialogClose = () => {
    setSearchAndFilterDialogState(prevState => ({
      ...prevState,
      shouldDialogOpen: false,
    }));
  };

  const renderSearchAndFilterDropdownDialog = (
    dialogProps: ISearchAndFilterDialogProps,
  ) => {
    return <SearchAndFilterDialog {...dialogProps} />;
  };

  const handleApplySearchAndFilterButtonClick = (data: {
    [key: string]: string | boolean;
  }) => {
    if (
      location.pathname ===
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.FullPath
    ) {
      dispatch(changeAssignmentByUserTableName());
      dispatch(setRefreshTaskCountSummaryForCategory({ data: true }));
    }

    if (
      location.pathname ===
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueAssignments.ChildRoutes.QueueAssignmentsByType.FullPath
    ) {
      dispatch(setRefreshTaskCountSummaryForCategory({ data: true }));
    }

    if (!data?.searchValue) {
      delete data?.searchKey;
      delete data?.searchValue;
    }

    const values = Object.values(data);

    for (let i = 0; i < values.length; i++) {
      const valueItem = values[i];
      if (valueItem) {
        dispatch(setQueueSearchAndFilterOptions({ data: data }));
        dispatch(setIsRefetchingNeeded({ data: true }));
        break;
      }
    }

    executeAfterTimeout(() => {
      setSearchAndFilterDialogState(prevState => ({
        ...prevState,
        shouldDialogOpen: false,
      }));
    }, 100);
  };

  const handleSearchAndFilterClearButtonClick = () => {
    dispatch(setQueueSearchAndFilterOptions({ data: {} }));

    if (
      location.pathname ===
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.FullPath
    ) {
      dispatch(changeAssignmentByUserTableName());
    }

    if (
      location.pathname ===
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
        .QueueAssignments.ChildRoutes.QueueAssignmentsByType.FullPath
    ) {
      dispatch(setRefreshTaskCountSummaryForCategory({ data: true }));
    }

    if (
      queueSearchAndFilterOptions !== undefined &&
      Object.entries(queueSearchAndFilterOptions).length !== 0
    ) {
      dispatch(setIsRefetchingNeeded({ data: true }));
    }
    if (location.search) {
      window.history.replaceState({}, document.title, location.pathname);
    }

    executeAfterTimeout(() => {
      setSearchAndFilterDialogState(prevState => ({
        ...prevState,
        shouldDialogOpen: false,
      }));
    }, 100);
  };

  const renderFilterMenu = () => {
    return (
      <FilterMenuContainer>
        <FilterIconButton onClick={handleFilterIconButtonClick}>
          <img src={Filter} height={36} width={36} alt="..." />
        </FilterIconButton>
        {renderSearchAndFilterDropdownDialog({
          open: searchAndFilterDialogState.shouldDialogOpen,
          configuration: searchAndFilterDialogState.currentFilterConfiguration,
          handleCloseBtnClick: dialogClose,
          handleApplyFilterClick: data =>
            handleApplySearchAndFilterButtonClick(data),
          handleClearFilterClick: handleSearchAndFilterClearButtonClick,
        })}
      </FilterMenuContainer>
    );
  };

  const renderSearchAndFilterMenu = () => {
    return <>{renderFilterMenu()}</>;
  };

  const renderRightMenuOptions = () => (
    <RightMenuOptionsContainer>
      {(locationState?.queueSelected ||
        assignmentsRoute === locationState?.queueSelected ||
        location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.ChildRoutes.QueueAssignmentsByType.FullPath ||
        location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueAssignments.ChildRoutes.QueueAssignmentsByUser.FullPath) && (
        <TabFilterWrapper>
          <TabFilter
            filterOptions={tabsFilter}
            onSelectionChange={handleTabChange}
            defaultSelectedTab={selectedTab}
          />
        </TabFilterWrapper>
      )}
      {showBulkActionMenuButton
        ? renderBulkActionButton()
        : renderSearchAndFilterMenu()}
    </RightMenuOptionsContainer>
  );

  const isQueueDetailsScreen = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    return pathParts.includes('details');
  };

  return (
    <QueueMainContainer>
      {!isQueueDetailsScreen() && (
        <TopBarWrapperGrid
          height="5rem"
          alignItems="center"
          justifyContent="space-between"
        >
          {renderAdminQueueTabs()}
          {renderRightMenuOptions()}
        </TopBarWrapperGrid>
      )}
      <QueueTabBodyContainer>
        <Outlet />
      </QueueTabBodyContainer>
    </QueueMainContainer>
  );
};

export default Queue;
