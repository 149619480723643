import { ReactElement, useEffect, useState } from 'react';

import HalomdLogoImage from '@images/LogoIcon.png';
import HalomdWhiteLogoImage from '@images/HalomdlogoWhite.png';

import { ReactComponent as DashboardIcon } from '@images/NavHome.svg';
import { ReactComponent as QueueIcon } from '@images/NavQueue.svg';
import { ReactComponent as UsersIcon } from '@images/NavUsers.svg';
import { ReactComponent as EmailsIcon } from '@images/NavEmails.svg';
import { ReactComponent as Settings } from '@images/NavSettings.svg';

import { ReactComponent as DashboardIconActive } from '@images/NavHomeActive.svg';
import { ReactComponent as QueueIconActive } from '@images/NavQueueActive.svg';
import { ReactComponent as UsersIconActive } from '@images/NavUsersActive.svg';
import { ReactComponent as EmailsIconActive } from '@images/NavEmailsActive.svg';
import { ReactComponent as SettingsActive } from '@images/NavSettingsActive.svg';

import { t } from 'i18next';
import {
  LogoContainer,
  MainContainer,
  NavMenuBodyContainer,
  MenuItemContainer,
  MenuItemsContainer,
  ProfileMenuItemContainer,
  SideNavBarMenuContainer,
  HMDLogoImage,
  LoadingGridForProfilePic,
} from './SideNavBarStyle';

import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { SideNavBarMenuItemType } from 'src/types/adminTypes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import AuthUtils from '@utils/AuthUtils';
import { USER_TYPES } from '@constants/global-constants/constants';
import {
  setIsProfileDetailsLoading,
  setIsProfilePictureLoading,
  setProfileData,
  setProfilePicture,
} from '@modules/home/state/profile-details/ProfileDetailsSlice';
import {
  getProfileDetails,
  getProfilePicture,
} from 'src/services/service-handlers/private/profile-details-handlers/ProfileDetailsPrivateService';
import { getErrorMessage } from '@utils/ErrorUtils';
import { useSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import Loader from '@components/loaders/Loader';
import { setQueueSearchAndFilterOptions } from '@modules/home/state/queue/QueueSlice';
import { setEmailSettingsSearchAndFilterValues } from '@modules/home/state/email-settings/EmailSettingsSlice';
import { setEmailSearchAndFilterValues } from '@modules/home/state/email/EmailSlice';
import { setUserSearchAndFilterValues } from '@modules/home/state/users/UsersSlice';

const sideNavBarMenuItemsList: SideNavBarMenuItemType[] = [
  // dashboard
  {
    name: AdminConstants.SIDE_NAV_BAR_MENU_ITEMS.DASHBOARD,
    icon: DashboardIcon,
    activeIcon: DashboardIconActive,
    navigationPath: PrivateNavigationRoutes.Admins.ChildRoutes.Dashboard.Path,
    isActive: true,
  },
  // queue
  {
    name: AdminConstants.SIDE_NAV_BAR_MENU_ITEMS.QUEUE,
    icon: QueueIcon,
    activeIcon: QueueIconActive,
    navigationPath: PrivateNavigationRoutes.Admins.ChildRoutes.Queue.Path,
    isActive: false,
  },
  // users
  {
    name: AdminConstants.SIDE_NAV_BAR_MENU_ITEMS.USERS,
    icon: UsersIcon,
    activeIcon: UsersIconActive,
    navigationPath: PrivateNavigationRoutes.Admins.ChildRoutes.Users.Path,
    isActive: false,
  },
  // emails
  {
    name: AdminConstants.SIDE_NAV_BAR_MENU_ITEMS.EMAILS,
    icon: EmailsIcon,
    activeIcon: EmailsIconActive,
    navigationPath: PrivateNavigationRoutes.Admins.ChildRoutes.Emails.Path,
    isActive: false,
  },
  // settings
  {
    name: AdminConstants.SIDE_NAV_BAR_MENU_ITEMS.SETTINGS,
    icon: Settings,
    activeIcon: SettingsActive,
    navigationPath: PrivateNavigationRoutes.Admins.ChildRoutes.Settings.Path,
    isActive: false,
  },
];

const SideNavBar = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    id,
    profilePicId,
    profilePic,
    isProfilePictureLoading,
    isProfileDetailsLoading,
  } = useSelector((state: RootState) => state.profile);
  const userType = AuthUtils.getUserType();

  const getProfilePictureById = async (userId: string | null) => {
    if (userId) {
      dispatch(setIsProfilePictureLoading(true));
      try {
        const getProfilePicResp = await getProfilePicture(id);
        const { data } = getProfilePicResp;
        if (data?.mimeType?.length > 0 && data?.content?.data?.length > 0) {
          const profilePicData = data.content.data;
          const blobImg = new Blob([new Uint8Array(profilePicData)], {
            type: data.mimeType,
          });
          const profilePicUrl = URL.createObjectURL(blobImg);
          dispatch(setProfilePicture(profilePicUrl));
        } else {
          const errMessage = t(
            'ADMIN.PROFILE_PICTURE_VALIDATION.PROFILE_PICTURE_NOT_FOUND',
          );
          enqueueSnackbar(errMessage, SnackBarConfig.getError());
        }
      } catch (error) {
        const errMessage = getErrorMessage(error);
        enqueueSnackbar(errMessage, SnackBarConfig.getError());
      } finally {
        dispatch(setIsProfilePictureLoading(false));
      }
    }
  };

  const fetchProfileDetails = async () => {
    dispatch(setIsProfileDetailsLoading(true));
    try {
      // Fetch profile details
      const response = await getProfileDetails();
      const { data } = response;
      // Update profile data in Redux store
      dispatch(setProfileData(data));
    } catch (errorResponse) {
      // Handle error
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      dispatch(setIsProfileDetailsLoading(false));
    }
  };
  const init = async () => {
    await fetchProfileDetails();
  };

  const [renderedSideNavBarMenuItems, setRenderedSideNavBarMenuItems] =
    useState(sideNavBarMenuItemsList);

  const setCurrentActiveMenuItem = (menuItemName: string) => {
    const newSideNavBarMenuItemsList = renderedSideNavBarMenuItems.map(
      (newSideNavBarMenuItem: SideNavBarMenuItemType) => ({
        ...newSideNavBarMenuItem,
        isActive:
          newSideNavBarMenuItem.name?.toLowerCase() ===
          menuItemName?.toLowerCase(),
      }),
    );
    setRenderedSideNavBarMenuItems(newSideNavBarMenuItemsList);
  };

  // TODO: Fix the comment
  useEffect(() => {
    const currentPath = location.pathname.split('/')[2];
    setCurrentActiveMenuItem(currentPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    init();
    if (profilePicId) {
      getProfilePictureById(profilePicId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePicId]);

  const renderProfileMenuItem = () => {
    return (
      <ProfileMenuItemContainer
        onClick={() => {
          setCurrentActiveMenuItem('');
          navigate(PrivateNavigationRoutes.Admins.ChildRoutes.Profile.Path);
        }}
      >
        {isProfilePictureLoading || isProfileDetailsLoading ? (
          <LoadingGridForProfilePic>
            <Loader size={30} />
          </LoadingGridForProfilePic>
        ) : (
          <Avatar
            alt=""
            src={profilePic}
            sx={{ width: '3rem', height: '3rem' }}
          />
        )}
      </ProfileMenuItemContainer>
    );
  };

  const renderMenuItem = (menuItem: SideNavBarMenuItemType, index: number) => {
    const Icon = menuItem.icon;
    const ActiveIcon = menuItem.activeIcon;

    return (
      <MenuItemContainer
        key={`${menuItem.name}-${index}`}
        isActive={menuItem.isActive}
        onClick={() => {
          setCurrentActiveMenuItem(menuItem.name);
          navigate(menuItem.navigationPath);
          dispatch(setQueueSearchAndFilterOptions({ data: {} }));
          dispatch(setUserSearchAndFilterValues({ data: {} }));
          dispatch(setEmailSearchAndFilterValues({ data: {} }));
          dispatch(setEmailSettingsSearchAndFilterValues({ data: {} }));
        }}
      >
        {menuItem.isActive ? <ActiveIcon /> : <Icon />}
      </MenuItemContainer>
    );
  };

  return (
    <MainContainer>
      <SideNavBarMenuContainer userType={userType}>
        <LogoContainer
          onClick={() => {
            navigate(PrivateNavigationRoutes.Home.Path);
          }}
        >
          <HMDLogoImage
            src={
              userType == USER_TYPES.USER
                ? HalomdLogoImage
                : HalomdWhiteLogoImage
            }
          />
        </LogoContainer>

        <MenuItemsContainer>
          {renderedSideNavBarMenuItems.map(
            (renderedSidebarMenuItem, index: number) =>
              renderMenuItem(renderedSidebarMenuItem, index),
          )}
        </MenuItemsContainer>
        {renderProfileMenuItem()}
      </SideNavBarMenuContainer>

      <NavMenuBodyContainer>
        <Outlet />
      </NavMenuBodyContainer>
    </MainContainer>
  );
};

export default SideNavBar;
