import { useEffect, useState } from 'react';
import { Dialog, SxProps } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import useTranslation from 'i18next';
import { produce } from 'immer';
import { useLocation } from 'react-router-dom';

import {
  ActionButtonWrapperDiv,
  ApplyFilterButton,
  ClearFilterButton,
  FilterDropdownLabel,
  SearchBarStyled,
  SearchDropdownInputsWrapperDiv,
  SearchDropdownSectionLabel,
  SectionDividerHR,
  FilterLoaderDiv,
  FilterOptionsWrapperDiv,
  SearchAndFilterSectionWrapperDiv,
  SearchOptionsWrapperDiv,
  TopSectionDiv,
  HeadingTypography,
  CloseIconImg,
} from './SearchAndFilterDialogStyle';

import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import Loader from '@components/loaders/Loader';
import Checkbox from '@components/checkbox/Checkbox';
import GroupedSelectInput from '@components/select-input/GroupedSelectInput';
import SelectInput from '@components/select-input/SelectInput';
import { getErrorMessage } from '@utils/ErrorUtils';

import {
  getAllStates,
  getAllTaskTypes,
} from 'src/services/service-handlers/private/ref-data-handlers/RefDataPrivateService';
import {
  getAllRoles,
  getAllTitles,
} from 'src/services/service-handlers/private/roles-handlers/RolesPrivateService';
import { fetchAllUsersFromOurSystem } from 'src/services/service-handlers/private/users-handlers/UsersPrivateService';
import { APP } from 'src/styles/variables';
import {
  DropdownOptionsType,
  FilterConfigurationType,
  FilterOptionsType,
  FilterSearchOptionsType,
  GroupedFilterOptionType,
} from 'src/types/adminTypes';
import { GetAllUsersFromOurSystemQueryParam } from 'src/services/service-handlers/private/users-handlers/UsersServiceMapper';
import {
  GetAllUsersFromOurSystemQueryParamFormatEnum,
  StateNsaAllStateEnum,
} from 'src/enums/admin-enums/AdminEnums';
import CloseIcon from 'src/assets/images/CloseIcon.svg';
import {
  AVAILABLE_FILTER_DROPDOWN_OPTIONS,
  FETCHING_AVAILABLE_FOR_FILTER_OPTIONS,
  FILTER_OPTION_FIELD_TYPES,
  QueueAITaskStatusDropdownOptions,
  QueueTaskStatusDropdownOptions,
  UserQueueTaskStatusDropdownOptions,
  staticPriorityDropdownOptionsList,
  staticStatusDropdownOptionsList,
} from '@constants/global-constants/constants';
import { GetAllTaskTypesRowData } from 'src/services/service-handlers/private/ref-data-handlers/RefDataServiceMapper';
import { DropdownOption } from 'src/types/Types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { isUserPortal } from '@modules/home/utils/Utils';

export interface ISearchAndFilterDialogProps {
  open: boolean;
  handleCloseBtnClick: () => void;
  handleApplyFilterClick: (data: { [key: string]: string | boolean }) => void;
  handleClearFilterClick: () => void;
  configuration: FilterConfigurationType;
  paperProps?: {
    elevation?: number;
    sx?: SxProps;
  };
  sx?: SxProps;
  topDistance?: string;
  rightDistance?: string;
  leftDistance?: string;
  isLeftPosition?: boolean;
  dialogTitle?: string;
}

interface IPreSelectedDropdownValues {
  [key: string]: string | boolean;
}

interface ISearchAndFilterDialogState {
  selectedSearchDropdownValue: string;
  preSelectedSearchDropdownValue: string;
  textInputValue: string;
  // searchQuery: string;
  isFilterOptionsLoading: boolean;
  assigneeDropdownOptions: DropdownOptionsType[];
  priorityDropdownOptions: DropdownOptionsType[];
  taskCategoryDropdownOptions: DropdownOptionsType[];
  taskTypeDropdownOptions: DropdownOptionsType[];
  statusDropdownOptions: DropdownOptionsType[];
  stateNsaDropdownOptions: DropdownOptionsType[];
  preSelectedDropdownValues: IPreSelectedDropdownValues;
  selectedFilterValues: IPreSelectedDropdownValues;
  titleOptions: DropdownOptionsType[];
  roleOptions: DropdownOptionsType[];
}

const stateOrNSADropdownOptionsFormatter = (rowDataArray: any[]) => {
  return rowDataArray.map(rowItem => {
    const obj: DropdownOptionsType = {
      optionId: rowItem.id,
      optionText: rowItem.label,
    };
    return obj;
  });
};

const categoryAndTaskDropdownOptionsFormatter = (
  rowDataArray: GetAllTaskTypesRowData[],
  isCategoryListOnly = true,
) => {
  if (isCategoryListOnly) {
    return rowDataArray.map(rowItem => {
      const finalObj: DropdownOptionsType = {
        optionId: '',
        optionText: '',
      };

      if (rowItem.categoryId) {
        finalObj.optionId = rowItem.categoryId;
        finalObj.optionText = rowItem.categoryLabel;
        return finalObj;
      } else {
        finalObj.optionId = rowItem.taskId as string;
        finalObj.optionText = rowItem.taskLabel as string;
        return finalObj;
      }
    });
  } else {
    return rowDataArray.map(flatItem => {
      const finalObj: GroupedFilterOptionType = {
        optionId: '',
        optionText: '',
        listHeader: '',
      };
      if (flatItem.categoryId) {
        finalObj.optionId = flatItem.categoryId;
        finalObj.optionText = null;
        finalObj.listHeader = flatItem.categoryLabel;
        return finalObj;
      } else {
        finalObj.optionId = flatItem.taskId;
        finalObj.optionText = flatItem.taskLabel;
        finalObj.listHeader = flatItem.categoryLabel;
        return finalObj;
      }
    });
  }
};

const SearchAndFilterDialog = (
  props: ISearchAndFilterDialogProps,
): JSX.Element => {
  const { t } = useTranslation;
  const location = useLocation();
  const { queueSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.queue,
  );
  const { stateNsa } = useSelector((state: RootState) => state.profile);

  const { userSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.users,
  );

  const { emailSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.email,
  );

  const { emailSettingsSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.emailSettings,
  );

  const {
    open = false,
    handleCloseBtnClick,
    handleApplyFilterClick,
    handleClearFilterClick,
    configuration = { SEARCH_BY_OPTIONS: [], FILTER_OPTIONS: [] },
    topDistance,
    rightDistance,
    leftDistance,
    isLeftPosition = false,
    sx = {
      width: '25rem',
      padding: '1.25rem',
      border: '1px solid ' + APP.PALETTE.GREY.SHADE_300,
      borderRadius: '0',
      boxShadow: '0px 4px 4px 0px ' + APP.PALETTE.GREY.BOX_SHADOW.GRAY,
      position: 'absolute',
      top: topDistance ?? '1.812rem',
      right: !isLeftPosition ? rightDistance ?? '-0.937rem' : '',
      left: isLeftPosition ? leftDistance ?? '-0.937rem' : '',
    },
    dialogTitle,
  } = props;
  const [searchAndFilterDialogState, setSearchAndFilterDialogState] =
    useState<ISearchAndFilterDialogState>({
      selectedSearchDropdownValue: AdminConstants.EMAIL_SETTINGS.ALL,
      preSelectedSearchDropdownValue: AdminConstants.EMAIL_SETTINGS.ALL,
      textInputValue: '',
      // searchQuery: '',
      isFilterOptionsLoading: true,
      assigneeDropdownOptions: [],
      priorityDropdownOptions: staticPriorityDropdownOptionsList,
      taskCategoryDropdownOptions: [],
      taskTypeDropdownOptions: [],
      statusDropdownOptions: staticStatusDropdownOptionsList,
      stateNsaDropdownOptions: [],
      preSelectedDropdownValues: {},
      selectedFilterValues: {},
      titleOptions: [],
      roleOptions: [],
    });

  const paperProps = {
    elevation: 0,
    sx: sx,
  };

  const handleSearchDropdownChange = (
    data?: string | HTMLInputElement | undefined,
  ) => {
    if (data) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedSearchDropdownValue = data.toString();
        }),
      );
    }
  };

  const handleFilterDropdownChange = (
    filterKey: string,
    data: string | HTMLInputElement | undefined,
  ) => {
    if (data) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedFilterValues[filterKey] = data.toString();
        }),
      );
    }
  };

  const handleFilterCheckboxChange = (filterKey: string, data: boolean) => {
    setSearchAndFilterDialogState(
      produce(draft => {
        draft.selectedFilterValues[filterKey] = data;
      }),
    );
  };

  const fetchTaskAndCategoryDropdownOptions = async (taskCategory = false) => {
    try {
      const response = await getAllTaskTypes();
      const { data } = response;

      const taskTypeAndTaskCategoryList: GetAllTaskTypesRowData[] = [];
      const categoryTypesList: GetAllTaskTypesRowData[] = [];
      const rowData = [...data.rows];

      // making the nested dropdown options into flat array
      rowData.map(rowItem => {
        const obj: GetAllTaskTypesRowData = { ...rowItem };
        const taskTypesArray = obj.taskTypes;
        obj.taskTypes = null;
        categoryTypesList.push(obj);
        taskTypeAndTaskCategoryList.push(obj);
        if (taskTypesArray) {
          taskTypesArray.map((taskTypeItem: any) => {
            const taskTypeItemObj = { ...taskTypeItem };
            taskTypeItemObj.categoryLabel = rowItem.categoryLabel;
            taskTypeAndTaskCategoryList.push(taskTypeItemObj);
          });
        }
      });

      if (taskCategory) {
        return categoryAndTaskDropdownOptionsFormatter(categoryTypesList, true);
      } else {
        return categoryAndTaskDropdownOptionsFormatter(
          taskTypeAndTaskCategoryList,
          false,
        );
      }
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const fetchStateOrNSADropdownOptions = async () => {
    try {
      const response = await getAllStates();
      const { data } = response;

      const rowData = [...data.rows];
      let formattedStateOrNSAOptions: DropdownOptionsType[] = [];

      // adding NSA dropdown option
      formattedStateOrNSAOptions.push(
        {
          optionId: AdminConstants.USERS.NSA.toLowerCase(),
          optionText: AdminConstants.USERS.NSA,
        },
        {
          optionId: AdminConstants.USERS.STATE.toLowerCase(),
          optionText: AdminConstants.USERS.ALL_STATES,
        },
      );

      // mapping list data into dropdown options format to handle it in select input dropdown
      formattedStateOrNSAOptions = [
        ...formattedStateOrNSAOptions,
        ...stateOrNSADropdownOptionsFormatter(rowData),
      ];

      return formattedStateOrNSAOptions;
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const fetchAssigneeDropdownOptions = async () => {
    try {
      const payLoadData: GetAllUsersFromOurSystemQueryParam = {
        isActive: true,
        format: GetAllUsersFromOurSystemQueryParamFormatEnum.BASIC,
        limit: 0,
        offset: 0,
      };
      const response = await fetchAllUsersFromOurSystem(payLoadData);

      const formattedAssigneeOptions = response.data.rows.map(rowItem => {
        const obj = {
          optionId: rowItem.id,
          optionText: rowItem.name,
        };
        return obj;
      });
      return formattedAssigneeOptions;
    } catch (e) {
      const errMessage = getErrorMessage(e);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    }
  };

  const fetchAllTitles = async () => {
    try {
      const getAllTitlesResponse = await getAllTitles();
      const allTitles = getAllTitlesResponse.data.rows;
      const formattedAllTitlesOptions = allTitles.map(title => {
        const formattedTitle = {
          optionId: title.id,
          optionText: title.label,
        };
        return formattedTitle;
      });
      return formattedAllTitlesOptions;
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    }
  };

  const fetchAllRoles = async () => {
    try {
      const getAllRolesResponse = await getAllRoles();
      const allRoles = getAllRolesResponse.data.rows;
      const formattedAllRolesOptions = allRoles.map(role => {
        const formattedRole = {
          optionId: role.id,
          optionText: role.label,
        };
        return formattedRole;
      });
      return formattedAllRolesOptions;
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    }
  };

  const init = async () => {
    setSearchAndFilterDialogState(
      produce(draft => {
        draft.isFilterOptionsLoading = true;
      }),
    );
    const dropdownOptionsToBeFetched: string[] = [];
    configuration.FILTER_OPTIONS.map((filterOptionItem: FilterOptionsType) => {
      FETCHING_AVAILABLE_FOR_FILTER_OPTIONS.map(availableFetchingOptionItem => {
        if (filterOptionItem.value === availableFetchingOptionItem) {
          dropdownOptionsToBeFetched.push(availableFetchingOptionItem);
          setSearchAndFilterDialogState(
            produce(draft => {
              draft.selectedFilterValues[availableFetchingOptionItem] = '';
            }),
          );
        }
      });
    });

    type FetchFunction = () => Promise<any>;
    const fetchFunctions: Record<DropdownOption, FetchFunction> = {
      assignedUserId: fetchAssigneeDropdownOptions,
      taskCategoryId: () => fetchTaskAndCategoryDropdownOptions(true),
      taskTypeId: () => fetchTaskAndCategoryDropdownOptions(false),
      nsaState: fetchStateOrNSADropdownOptions,
      title: fetchAllTitles,
      role: fetchAllRoles,
    };
    const promises: Promise<any>[] = [];
    const resultsMap: Partial<Record<DropdownOption, number>> = {};

    (dropdownOptionsToBeFetched as DropdownOption[]).forEach(option => {
      if (fetchFunctions[option]) {
        promises.push(fetchFunctions[option]());
        resultsMap[option] = promises.length - 1;
      }
    });
    const results = await Promise.all(promises);
    const mappedResults: Partial<Record<DropdownOption, any>> =
      Object.fromEntries(
        Object.entries(resultsMap).map(([key, index]) => [
          key,
          results[index as number],
        ]),
      );

    const assigneeOptions = mappedResults?.assignedUserId ?? null;
    const taskCategoryOptions = mappedResults?.taskCategoryId ?? null;
    const taskTypeOptions = mappedResults?.taskTypeId ?? null;
    const formattedStateOrNSAOptions = mappedResults?.nsaState ?? null;
    const titleOptions = mappedResults?.title ?? null;
    const roleOptions = mappedResults?.role ?? null;

    if (assigneeOptions) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.assigneeDropdownOptions = assigneeOptions;
        }),
      );
    }

    if (taskCategoryOptions) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.taskCategoryDropdownOptions = taskCategoryOptions;
        }),
      );
    }

    if (taskTypeOptions) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.taskTypeDropdownOptions = taskTypeOptions;
        }),
      );
    }

    if (formattedStateOrNSAOptions) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.stateNsaDropdownOptions = formattedStateOrNSAOptions;
        }),
      );
    }

    if (titleOptions) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.titleOptions = titleOptions;
        }),
      );
    }
    if (roleOptions) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.roleOptions = roleOptions;
        }),
      );
    }

    setSearchAndFilterDialogState(
      produce(draft => {
        draft.isFilterOptionsLoading = false;
      }),
    );
  };

  const prePopulateQueueTabSearchAndFilterSelections = () => {
    if (
      queueSearchAndFilterOptions !== undefined &&
      Object.entries(queueSearchAndFilterOptions).length === 0
    ) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedFilterValues = {};
          draft.preSelectedDropdownValues = {};
          draft.selectedSearchDropdownValue = AdminConstants.EMAIL_SETTINGS.ALL;
          draft.textInputValue = '';
        }),
      );
    } else {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedSearchDropdownValue =
            queueSearchAndFilterOptions?.searchKey as string;
          draft.textInputValue =
            queueSearchAndFilterOptions?.searchValue as string;
          draft.preSelectedDropdownValues =
            queueSearchAndFilterOptions as IPreSelectedDropdownValues;
        }),
      );
    }
  };

  const prePopulateUserScreenSearchAndFilterSelections = () => {
    if (
      userSearchAndFilterOptions !== undefined &&
      Object.entries(userSearchAndFilterOptions).length > 0
    ) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedSearchDropdownValue =
            userSearchAndFilterOptions?.searchKey as string;
          draft.textInputValue =
            userSearchAndFilterOptions?.searchValue as string;
          draft.preSelectedDropdownValues = {
            searchKey: userSearchAndFilterOptions?.searchKey ?? '',
            searchValue: userSearchAndFilterOptions?.searchValue ?? '',
            title: userSearchAndFilterOptions?.title ?? '',
            role: userSearchAndFilterOptions?.role ?? '',
          };
        }),
      );
    } else {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedFilterValues = {};
          draft.preSelectedDropdownValues = {};
          draft.selectedSearchDropdownValue = AdminConstants.EMAIL_SETTINGS.ALL;
          draft.textInputValue = '';
        }),
      );
    }
  };

  const prePopulateEmailScreenSearchAndFilterSelections = () => {
    if (
      Object.entries(
        emailSearchAndFilterOptions !== undefined &&
          Object.entries(emailSearchAndFilterOptions).length > 0,
      )
    ) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedSearchDropdownValue =
            emailSearchAndFilterOptions?.searchKey as string;
          draft.textInputValue =
            emailSearchAndFilterOptions?.searchValue as string;
        }),
      );
    } else {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedFilterValues = {};
          draft.preSelectedDropdownValues = {};
          draft.preSelectedSearchDropdownValue =
            AdminConstants.EMAIL_SETTINGS.ALL;
          draft.textInputValue = '';
        }),
      );
    }
  };

  const prePopulateEmailSettingsScreenSearchAndFilterSelections = () => {
    if (
      emailSettingsSearchAndFilterOptions !== undefined &&
      Object.entries(emailSettingsSearchAndFilterOptions).length > 0
    ) {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedFilterValues =
            emailSettingsSearchAndFilterOptions as any;
          draft.selectedSearchDropdownValue =
            emailSettingsSearchAndFilterOptions?.searchKey as any;
          draft.textInputValue =
            emailSettingsSearchAndFilterOptions?.searchValue as any;
        }),
      );
    } else {
      setSearchAndFilterDialogState(
        produce(draft => {
          draft.selectedFilterValues = {};
          draft.preSelectedDropdownValues = {};
          draft.preSelectedSearchDropdownValue =
            AdminConstants.EMAIL_SETTINGS.ALL;
          draft.textInputValue = '';
        }),
      );
    }
  };

  useEffect(() => {
    if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Queue.FullPath,
      ) ||
      location.pathname.includes(
        PrivateNavigationRoutes.User.ChildRoutes.Queue.FullPath,
      ) ||
      location.pathname.includes(
        PrivateNavigationRoutes.User.ChildRoutes.History.FullPath,
      )
    ) {
      prePopulateQueueTabSearchAndFilterSelections();
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Users.FullPath,
      )
    ) {
      prePopulateUserScreenSearchAndFilterSelections();
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Emails.ChildRoutes
          .EmailsTable.FullPath,
      )
    ) {
      prePopulateEmailScreenSearchAndFilterSelections();
    } else if (
      location.pathname.includes(
        PrivateNavigationRoutes.Admins.ChildRoutes.Settings.ChildRoutes
          .EmailSettingsTable.FullPath,
      )
    ) {
      prePopulateEmailSettingsScreenSearchAndFilterSelections();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queueSearchAndFilterOptions,
    userSearchAndFilterOptions,
    emailSearchAndFilterOptions,
    emailSettingsSearchAndFilterOptions,
  ]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  const renderSearchOptionsSection = (
    SearchOptions: FilterSearchOptionsType[] = [],
  ) => {
    const formattedOptions = SearchOptions.map(SearchOptionsItem => {
      const obj = {
        optionId: SearchOptionsItem.value,
        optionText: SearchOptionsItem.label,
      };
      return obj;
    });

    return (
      <div style={{ width: '100%' }}>
        <SearchDropdownSectionLabel>
          {t('ADMIN.COMMON.SEARCH')}
        </SearchDropdownSectionLabel>
        <SearchDropdownInputsWrapperDiv>
          <SelectInput
            key={searchAndFilterDialogState.preSelectedSearchDropdownValue}
            dropdownOptions={formattedOptions}
            showSelectedOption={true}
            preSelectedValue={
              searchAndFilterDialogState.preSelectedSearchDropdownValue ?? ''
            }
            isgroupingstylingneeded={'yes'}
            backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
            isDefaultValuePresent={true}
            width={'100%'}
            borderRadius={'0.25rem'}
            value={searchAndFilterDialogState.selectedSearchDropdownValue}
            isindentationneeded={'no'}
            handleChange={data => handleSearchDropdownChange(data)}
            isIdAsValueRequired={true}
          />
          {(searchAndFilterDialogState.selectedSearchDropdownValue ||
            searchAndFilterDialogState.preSelectedSearchDropdownValue) && (
            <SearchBarStyled
              label={''}
              size="small"
              height={1}
              value={searchAndFilterDialogState.textInputValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchAndFilterDialogState(
                  produce(draft => {
                    draft.textInputValue = event.target.value;
                  }),
                );
              }}
            />
          )}
        </SearchDropdownInputsWrapperDiv>
      </div>
    );
  };

  const filterStateNsaDropdownOptions = () => {
    if (
      location.pathname ===
        PrivateNavigationRoutes.User.ChildRoutes.Queue.FullPath ||
      location.pathname ===
        PrivateNavigationRoutes.User.ChildRoutes.History.FullPath
    ) {
      switch (stateNsa) {
        case StateNsaAllStateEnum.STATE:
          return searchAndFilterDialogState.stateNsaDropdownOptions.filter(
            option =>
              option.optionText !== AdminConstants.USERS.NSA &&
              option.optionText !== AdminConstants.USERS.ALL_STATES,
          );
        case StateNsaAllStateEnum.NSA:
          return searchAndFilterDialogState.stateNsaDropdownOptions.filter(
            option => option.optionText === AdminConstants.USERS.NSA,
          );
        case StateNsaAllStateEnum.ALL_STATE:
          return searchAndFilterDialogState.stateNsaDropdownOptions.filter(
            option => option.optionText !== AdminConstants.USERS.NSA,
          );
        case null:
          return [];
        default:
          return searchAndFilterDialogState.stateNsaDropdownOptions;
      }
    }

    return searchAndFilterDialogState.stateNsaDropdownOptions;
  };

  const getFilterDropdownOptions = (selectInput: string) => {
    switch (selectInput) {
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.ASSIGNEE:
        return searchAndFilterDialogState.assigneeDropdownOptions;
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.STATUS:
        if (
          location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueTable.FullPath
        ) {
          const queueScreenStatusDropdownOptionsList =
            staticStatusDropdownOptionsList.filter(option =>
              QueueTaskStatusDropdownOptions.includes(option.optionId),
            );
          return queueScreenStatusDropdownOptionsList;
        } else if (
          location.pathname ===
          PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes
            .QueueQueueAI.FullPath
        ) {
          const queueAIScreenStatusDropdownOptionsList =
            staticStatusDropdownOptionsList.filter(option =>
              QueueAITaskStatusDropdownOptions.includes(option.optionId),
            );

          return queueAIScreenStatusDropdownOptionsList;
        } else if (
          location.pathname ===
          PrivateNavigationRoutes.User.ChildRoutes.Queue.FullPath
        ) {
          const userQueueScreenStatusDropdownOptionsList =
            staticStatusDropdownOptionsList.filter(option =>
              UserQueueTaskStatusDropdownOptions.includes(option.optionId),
            );

          return userQueueScreenStatusDropdownOptionsList;
        }

        return searchAndFilterDialogState.statusDropdownOptions;
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.PRIORITY:
        return searchAndFilterDialogState.priorityDropdownOptions;
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.TASK_CATEGORY:
        return searchAndFilterDialogState.taskCategoryDropdownOptions;
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.TASK_TYPE:
        return searchAndFilterDialogState.taskTypeDropdownOptions;
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.NSA_OR_STATE:
        return filterStateNsaDropdownOptions();
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.TITLE:
        return searchAndFilterDialogState.titleOptions;
      case AVAILABLE_FILTER_DROPDOWN_OPTIONS.ROLE:
        return searchAndFilterDialogState.roleOptions;
      default:
        return [];
    }
  };

  const renderPreSelectValue = (filterItemKeyValue: string): string => {
    let assignedUserToRender = '';
    if (filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.ASSIGNEE) {
      searchAndFilterDialogState.assigneeDropdownOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.assignedUserId
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (
      filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.NSA_OR_STATE
    ) {
      searchAndFilterDialogState.stateNsaDropdownOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.nsaState
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (
      filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.PRIORITY
    ) {
      searchAndFilterDialogState.priorityDropdownOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.taskPriority
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (
      filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.STATUS
    ) {
      searchAndFilterDialogState.statusDropdownOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.taskStatus
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (
      filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.TASK_CATEGORY
    ) {
      searchAndFilterDialogState.taskCategoryDropdownOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.taskCategoryId
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (
      filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.TASK_TYPE
    ) {
      searchAndFilterDialogState.taskTypeDropdownOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.taskTypeId
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.TITLE) {
      searchAndFilterDialogState.titleOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.title
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    } else if (filterItemKeyValue === AVAILABLE_FILTER_DROPDOWN_OPTIONS.ROLE) {
      searchAndFilterDialogState.roleOptions.map(optionItem => {
        if (
          optionItem.optionId ===
          searchAndFilterDialogState.preSelectedDropdownValues?.role
        ) {
          assignedUserToRender = optionItem.optionId as string;
        }
      });
    }

    return assignedUserToRender;
  };

  const renderFilterDropdown = (filterItem: FilterOptionsType): JSX.Element => {
    if (filterItem.value === AVAILABLE_FILTER_DROPDOWN_OPTIONS.TASK_TYPE) {
      return (
        <div key={filterItem.value}>
          <FilterDropdownLabel>{filterItem.label}</FilterDropdownLabel>
          <GroupedSelectInput
            key={
              searchAndFilterDialogState.selectedFilterValues[filterItem.value]
                ? searchAndFilterDialogState.selectedFilterValues[
                    filterItem.value
                  ].toString()
                : filterItem.value
            }
            groupedFilterOptions={getFilterDropdownOptions(filterItem.value)}
            isgroupingstylingneeded={'yes'}
            backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
            width={'100%'}
            borderRadius={'0.25rem'}
            isIdAsValueRequired={true}
            value={
              (searchAndFilterDialogState.selectedFilterValues[
                filterItem.value
              ] as string) || renderPreSelectValue(filterItem.value)
            }
            handleOptionSelect={(data: string | HTMLInputElement | undefined) =>
              handleFilterDropdownChange(filterItem.value, data)
            }
          />
        </div>
      );
    }

    return (
      <div key={filterItem.value}>
        <FilterDropdownLabel>{filterItem.label}</FilterDropdownLabel>
        <SelectInput
          key={
            searchAndFilterDialogState.selectedFilterValues[filterItem.value]
              ? searchAndFilterDialogState.selectedFilterValues[
                  filterItem.value
                ].toString()
              : filterItem.value
          }
          dropdownOptions={getFilterDropdownOptions(filterItem.value)}
          showSelectedOption={true}
          isgroupingstylingneeded={'yes'}
          backgroundColor={APP.PALETTE.BACKGROUND.PAPER}
          isDefaultValuePresent={true}
          width={'100%'}
          borderRadius={'0.25rem'}
          isindentationneeded={'no'}
          isIdAsValueRequired={true}
          value={
            (searchAndFilterDialogState.selectedFilterValues[
              filterItem.value
            ] as string) || renderPreSelectValue(filterItem.value)
          }
          handleChange={data =>
            handleFilterDropdownChange(filterItem.value, data)
          }
        />
      </div>
    );
  };

  const renderFilterCheckbox = (filterItem: FilterOptionsType) => {
    return (
      <Checkbox
        key={
          searchAndFilterDialogState.selectedFilterValues[
            filterItem.value
          ] as string
        }
        checked={
          !!searchAndFilterDialogState.selectedFilterValues[filterItem.value]
        }
        size={'medium'}
        sx={{
          color: APP.PALETTE.GREY.GRAY_COLUMN,
          '&.Mui-checked': {
            color: APP.PALETTE.SECONDARY.MAIN,
          },
        }}
        label={filterItem.label}
        onChange={(value: boolean) => {
          handleFilterCheckboxChange(filterItem.value, value);
        }}
      />
    );
  };

  const renderFilterOptionsSection = (filterOptions: FilterOptionsType[]) => {
    return filterOptions.map((filterOptionsItem: FilterOptionsType) => {
      if (filterOptionsItem.type === FILTER_OPTION_FIELD_TYPES.DROPDOWN) {
        return (
          <div key={filterOptionsItem.label}>
            {renderFilterDropdown(filterOptionsItem)}
          </div>
        );
      } else if (
        filterOptionsItem.type === FILTER_OPTION_FIELD_TYPES.CHECKBOX
      ) {
        return (
          <div key={filterOptionsItem.label}>
            {renderFilterCheckbox(filterOptionsItem)}
          </div>
        );
      }
    });
  };

  const renderSearchAndFilterSections = (): JSX.Element => {
    return (
      <SearchAndFilterSectionWrapperDiv>
        <SearchOptionsWrapperDiv>
          {configuration.SEARCH_BY_OPTIONS &&
            renderSearchOptionsSection(configuration.SEARCH_BY_OPTIONS)}
        </SearchOptionsWrapperDiv>

        {configuration.SEARCH_BY_OPTIONS &&
        configuration?.SEARCH_BY_OPTIONS?.length > 0 ? (
          <SectionDividerHR />
        ) : null}

        {searchAndFilterDialogState.isFilterOptionsLoading ? (
          <FilterLoaderDiv>
            <Loader />
          </FilterLoaderDiv>
        ) : (
          <FilterOptionsWrapperDiv>
            {configuration.FILTER_OPTIONS &&
              renderFilterOptionsSection(configuration.FILTER_OPTIONS)}
          </FilterOptionsWrapperDiv>
        )}
      </SearchAndFilterSectionWrapperDiv>
    );
  };

  const handleApplyFilterButtonClick = () => {
    let selectedValues = {
      ...searchAndFilterDialogState.selectedFilterValues,
    };
    configuration.FILTER_OPTIONS.map(filterItem => {
      if (selectedValues !== undefined && selectedValues[filterItem.value]) {
        selectedValues = {
          ...selectedValues,
          [filterItem.value]: selectedValues[filterItem.value],
        };
      } else if (
        queueSearchAndFilterOptions !== undefined &&
        queueSearchAndFilterOptions[filterItem.value]
      ) {
        selectedValues = {
          ...selectedValues,
          [filterItem.value]: queueSearchAndFilterOptions[filterItem.value],
        };
      }
    });

    // including search dropdown key and search input value
    selectedValues.searchKey =
      searchAndFilterDialogState.selectedSearchDropdownValue === undefined
        ? searchAndFilterDialogState.preSelectedSearchDropdownValue
        : searchAndFilterDialogState?.selectedSearchDropdownValue;
    selectedValues.searchValue = searchAndFilterDialogState?.textInputValue;

    if (handleApplyFilterClick) {
      handleApplyFilterClick(selectedValues);
    }
  };

  const renderActionButtons = () => {
    return (
      <ActionButtonWrapperDiv>
        <ClearFilterButton
          variant="outlined"
          sx={{ height: 'inherit', width: 'inherit' }}
          onClick={() => {
            setSearchAndFilterDialogState(
              produce(draft => {
                draft.selectedSearchDropdownValue =
                  AdminConstants.EMAIL_SETTINGS.ALL;
                draft.textInputValue = '';
                draft.selectedFilterValues = {};
              }),
            );
            if (handleClearFilterClick) handleClearFilterClick();
          }}
        >
          {t('ADMIN.COMMON.RESET')}
        </ClearFilterButton>
        <ApplyFilterButton
          variant="contained"
          sx={{ height: 'inherit', width: 'inherit' }}
          onClick={handleApplyFilterButtonClick}
        >
          {t('ADMIN.COMMON.APPLY')}
        </ApplyFilterButton>
      </ActionButtonWrapperDiv>
    );
  };

  const renderTopSection = () => {
    return (
      <TopSectionDiv>
        <HeadingTypography>
          {dialogTitle ?? t('ADMIN.COMMON.FILTER')}
        </HeadingTypography>
        <CloseIconImg
          onClick={handleCloseBtnClick}
          src={CloseIcon}
          alt="..."
          height={17}
          width={17}
        />
      </TopSectionDiv>
    );
  };

  const searchAndFilterDialogMaxHeightForUsersPortal = isUserPortal()
    ? '85vh'
    : 'none';

  return (
    <Dialog
      open={open}
      onClose={handleCloseBtnClick}
      PaperProps={paperProps}
      hideBackdrop={false}
      sx={{
        maxHeight: searchAndFilterDialogMaxHeightForUsersPortal,
      }}
    >
      {renderTopSection()}
      {renderSearchAndFilterSections()}
      {configuration.FILTER_OPTIONS.length > 0 ? <SectionDividerHR /> : null}
      {renderActionButtons()}
    </Dialog>
  );
};

export default SearchAndFilterDialog;
