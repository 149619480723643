import ServiceRequest from 'src/services/ServiceRequest';
import {
  addUsersUnderTaskCategoryByTaskCategoryIdUrl,
  archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryIdUrl,
  distributeUnassignedQueueItemsUrl,
  getAllNonAssignedUsersByTaskCategoryIdUrl,
  getAllQueueHistoryListUrl,
  getAllQueueListUrl,
  getAllQueueUnclassifiedListUrl,
  getAssignableUsersInCategoryByTaskCategoryIdUrl,
  getMaxTaskAssignmentsByTaskCategoryIdUrl,
  getTaskAssignmentsSummaryByUserUrl,
  getTasksCountForUsersSummaryByTaskCategoryIdUrl,
  getTasksCountSummaryByTaskCategoryIdUrl,
  queueAIBaseUrl,
  updateAssignedUserUrl,
  updateFlagTaskUrl,
  updateMaxTaskAssignmentsByTaskCategoryIdUrl,
  updateTaskTypeUrl,
  updateUnFlagTaskUrl,
} from './QueueApiEndPoints';
import {
  AddUsersUnderTaskCategoryByTaskCategoryIdPayloadDto,
  AddUsersUnderTaskCategoryByTaskCategoryIdResDto,
  ArchiveUnclassifiedUnFlaggedQueueItemByTaskCategoryIdDto,
  DistributeUnassignedQueueItemsResDto,
  GetAllNonAssignedUsersByTaskCategoryIdResDto,
  GetAssignableUsersInCategoryByTaskCategoryIdResDto,
  GetMaxAssignmentsByTaskCategoryIdResDto,
  GetTaskAssignmentsSummaryByUserParams,
  GetTaskAssignmentsSummaryByUserResDto,
  GetTasksCountSummaryByTaskCategoryIdResDto,
  GetTasksCountSummaryByUsersForTaskCategoryIdQueryParams,
  GetTasksCountSummaryByUsersForTaskCategoryIdResDto,
  QueueAIParams,
  QueueAIResponseData,
  QueueParams,
  QueueResponseData,
  UpdateAssignedUserPayloadDto,
  UpdateAssignedUserResDto,
  UpdateFlagItemResDto,
  UpdateFlagTaskPayloadDto,
  UpdateMaxAssignmentsByTaskCategoryIdPayloadDto,
  UpdateTaskTypeParams,
  UpdateTaskTypeResDto,
  UpdateUnFlagItemResDto,
} from './QueueServiceMapper';
import { CancelTokenSource } from 'axios';

export const getAllQueueList = async (
  data?: QueueParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<QueueResponseData> => {
  return await ServiceRequest.get(
    getAllQueueListUrl,
    { payload: data },
    cancelTokenSource,
  );
};

export const getAllQueueAIList = async (
  data?: QueueAIParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<QueueAIResponseData> => {
  return await ServiceRequest.get(
    queueAIBaseUrl,
    { payload: data },
    cancelTokenSource,
  );
};

export const getAllQueueHistoryList = async (
  data?: QueueParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<QueueResponseData> => {
  return await ServiceRequest.get(
    getAllQueueHistoryListUrl,
    { payload: data },
    cancelTokenSource,
  );
};

export const getAllQueueUnclassifiedList = async (
  data?: QueueParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<QueueResponseData> => {
  return await ServiceRequest.get(
    getAllQueueUnclassifiedListUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const updateAssignedUser = async (
  data: UpdateAssignedUserPayloadDto,
): Promise<UpdateAssignedUserResDto> => {
  return await ServiceRequest.put(updateAssignedUserUrl, {
    payload: data,
  });
};

export const updateTaskType = async (
  data: UpdateTaskTypeParams,
): Promise<UpdateTaskTypeResDto> => {
  return await ServiceRequest.put(updateTaskTypeUrl, {
    payload: data,
  });
};

export const updateFlagTask = async (
  id: string,
  data: UpdateFlagTaskPayloadDto,
): Promise<UpdateFlagItemResDto> => {
  return await ServiceRequest.put(updateFlagTaskUrl.replace(':id', id), {
    payload: data,
  });
};

export const updateUnflagTask = async (
  id: string,
): Promise<UpdateUnFlagItemResDto> => {
  return await ServiceRequest.put(updateUnFlagTaskUrl.replace(':id', id));
};

// queue assignments API calls

// get Max Assignments by task category id

export const getMaxAssignmentsByTaskCategoryId = async (
  taskCategoryId = '',
  cancelTokenSource?: CancelTokenSource,
): Promise<GetMaxAssignmentsByTaskCategoryIdResDto> => {
  const payload = undefined;
  return await ServiceRequest.get(
    getMaxTaskAssignmentsByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
    payload,
    cancelTokenSource,
  );
};

// update Max Assignments by task category id API call

export const updateMaxAssignmentsByTaskCategoryId = async (
  taskCategoryId = '',
  data: UpdateMaxAssignmentsByTaskCategoryIdPayloadDto,
): Promise<GetMaxAssignmentsByTaskCategoryIdResDto> => {
  return await ServiceRequest.put(
    updateMaxTaskAssignmentsByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
    { payload: data },
  );
};

// get tasks count summary by task category API call

export const getTasksCountSummaryByTaskCategoryId = async (
  taskCategoryId = '',
  cancelTokenSource?: CancelTokenSource,
): Promise<GetTasksCountSummaryByTaskCategoryIdResDto> => {
  const payload = undefined;
  return await ServiceRequest.get(
    getTasksCountSummaryByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
    payload,
    cancelTokenSource,
  );
};

// get tasks count summary by user for a category API call

export const getTasksCountSummaryByUsersForTaskCategoryId = async (
  taskCategoryId = '',
  params: GetTasksCountSummaryByUsersForTaskCategoryIdQueryParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetTasksCountSummaryByUsersForTaskCategoryIdResDto> => {
  const { limit = 10, offset = 50, userId, stateId, stateNsa } = params;
  const payload: {
    limit: number;
    offset: number;
    userId?: string;
    stateId?: string;
    stateNsa?: string;
  } = {
    limit,
    offset,
  };

  if (userId && userId.length > 2) {
    payload.userId = userId;
  }

  if (stateId && stateId.length > 2) {
    payload.stateId = stateId;
  }

  if (stateNsa && stateNsa.length > 0) {
    payload.stateNsa = stateNsa;
  }

  return await ServiceRequest.get(
    getTasksCountForUsersSummaryByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
    { payload },
    cancelTokenSource,
  );
};

// get all non assigned users list for that task category

export const getAllNonAssignedUsersForTaskCategoryByTaskCategoryId = async (
  id = '',
): Promise<GetAllNonAssignedUsersByTaskCategoryIdResDto> => {
  return ServiceRequest.get(
    getAllNonAssignedUsersByTaskCategoryIdUrl.replace(':taskCategoryId', id),
  );
};

// get assignable users list for that task category

export const getAssignableUsersForTaskCategoryByTaskCategoryId = async (
  id = '',
): Promise<GetAssignableUsersInCategoryByTaskCategoryIdResDto> => {
  return ServiceRequest.get(
    getAssignableUsersInCategoryByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      id,
    ),
  );
};

// add users under task category API call

export const addUsersUnderTaskCategoryByTaskCategoryId = async (
  taskCategoryId = '',
  data: AddUsersUnderTaskCategoryByTaskCategoryIdPayloadDto,
): Promise<AddUsersUnderTaskCategoryByTaskCategoryIdResDto> => {
  return await ServiceRequest.put(
    addUsersUnderTaskCategoryByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
    { payload: data },
  );
};

// Gets task assignments by user API call

export const getTaskAssignmentsSummaryByUser = async (
  data: GetTaskAssignmentsSummaryByUserParams,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetTaskAssignmentsSummaryByUserResDto> => {
  return await ServiceRequest.get(
    getTaskAssignmentsSummaryByUserUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

// Distribute Unassigned Queue Items API call

export const distributeUnassignedQueueItems = async (
  taskCategoryId = '',
): Promise<DistributeUnassignedQueueItemsResDto> => {
  return await ServiceRequest.post(
    distributeUnassignedQueueItemsUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
    {
      payload: {},
    },
  );
};

export const archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryId = async (
  taskCategoryId = '',
): Promise<ArchiveUnclassifiedUnFlaggedQueueItemByTaskCategoryIdDto> => {
  return await ServiceRequest.put(
    archiveUnclassifiedUnFlaggedQueueItemByTaskCategoryIdUrl.replace(
      ':taskCategoryId',
      taskCategoryId,
    ),
  );
};
