import ServiceRequest from 'src/services/ServiceRequest';

import {
  getAllTaskCategoriesUrl,
  getAllAssignmentLevelsUrl,
} from './TaskCategoriesApiEndPoints';

import {
  GetAllTaskCategoriesQueryParam,
  GetAllTaskCategoriesResDto,
  GetAllAssignmentLevelsResDto,
} from './TaskCategoriesServiceMapper';
import { CancelTokenSource } from 'axios';

export const getAllTaskCategories = async (
  data?: GetAllTaskCategoriesQueryParam,
  cancelTokenSource?: CancelTokenSource,
): Promise<GetAllTaskCategoriesResDto> => {
  return await ServiceRequest.get(
    getAllTaskCategoriesUrl,
    {
      payload: data,
    },
    cancelTokenSource,
  );
};

export const getAllAssignmentLevels =
  async (): Promise<GetAllAssignmentLevelsResDto> => {
    return await ServiceRequest.get(getAllAssignmentLevelsUrl);
  };
