import { useMemo, ReactElement, useState, useEffect, useRef } from 'react';
import {
  type MRT_RowSelectionState as MRTRowSelectionState,
  type MRT_ColumnDef as MRTColumnDef,
} from 'material-react-table';
import { Box, Grid } from '@mui/material';
import {
  BodySectionContainer,
  BodyTextStyle,
  DataNotFoundDiv,
  DescriptionSpan,
  EmailNameTextDiv,
  EmailTextDiv,
  StyledSpan,
} from './EmailsTableStyle';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { useNavigate } from 'react-router-dom';
import TopBarWrapperGrid from '@components/top-bar-wrapper-grid/TopBarWrapperGrid';
import { t } from 'i18next';
import ServerSideDataGrid from '@components/custom-data-grid/ServerSideDataGrid';
import FilterDropdownWithBadgeCount from '@components/filter-dropdown-with-badge-count/FilterDropdownWithBadgeCount';
import { getAllEmails } from 'src/services/service-handlers/private/email-handlers/EmailPrivateService';
import { getErrorMessage } from '@utils/ErrorUtils';
import { enqueueSnackbar } from 'notistack';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import {
  GetAllEmailQueryParams,
  GetAllEmailsDataRow,
} from 'src/services/service-handlers/private/email-handlers/EmailServiceMapper';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import { ERROR_CONSTANTS } from '@constants/global-constants/constants';
import { executeAfterTimeout, formatDateTime } from '@modules/home/utils/Utils';
import { produce } from 'immer';
import SearchAndFilterDialog from '@components/dialog/search-and-filter-dialog/SearchAndFilterDialog';
import { generateRandomTableName } from '@utils/miscellaneousUtils';
import { setEmailSearchAndFilterValues } from '@modules/home/state/email/EmailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import axios, { CancelTokenSource } from 'axios';

// example data type
type EmailsColumnTypes = {
  fromEmail: string;
  received_at: string;
  body: string;
  subject: string;
  id: string;
};

const mapEmailDataToEmailsColumnsType = (email: GetAllEmailsDataRow) => {
  const formattedEmailRowData: EmailsColumnTypes = {
    fromEmail: email?.fromEmail ?? '',
    received_at: formatDateTime(email?.receivedAt ?? new Date()),
    body: email?.body ?? '',
    subject: email?.subject ?? '',
    id: email?.id ?? '-1',
  };
  return formattedEmailRowData;
};

type EmailsTableStateType = {
  isLoading: boolean;
  showSearchAndFilterDropdown: boolean;
  fetchEmailsDataFnQueryKeyName: string;
};

const initialEmailsTableState: EmailsTableStateType = {
  isLoading: false,
  showSearchAndFilterDropdown: false,
  fetchEmailsDataFnQueryKeyName: AdminConstants.EMAIL.EMAIL_TABLE_NAME,
};

const EMAILS_TABLE_SEARCH_AND_FILTER_OPTIONS_CONFIG = {
  SEARCH_BY_OPTIONS: [
    { label: 'All', value: 'all' },
    { label: 'Subject', value: 'subject' },
    { label: 'From Email', value: 'from' },
    { label: 'Case Id', value: 'caseId' },
  ],
  FILTER_OPTIONS: [],
};

const EmailsTable = (): ReactElement => {
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({});
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [isFetchingEmails, setIsFetchingEmails] = useState(false);
  const [networkErrorState, setNetworkErrorState] = useState(false);
  const [emailsTableState, setEmailsTableState] =
    useState<EmailsTableStateType>(initialEmailsTableState);

  const { emailSearchAndFilterOptions } = useSelector(
    (state: RootState) => state.email,
  );
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);

  const caseIdQueryValue = queryParams.get(
    AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.CASE_ID,
  );

  const columns = useMemo<MRTColumnDef<EmailsColumnTypes>[]>(
    () => [
      {
        id: AdminConstants.EMAIL.TABLE_COLUMNS.FROM_EMAIL,
        accessorKey: AdminConstants.EMAIL.TABLE_COLUMNS.FROM_EMAIL,
        header: t('PRIVATE.EMAILS.QUEUE_TABLE.FROM'),
        accessorFn: row => (
          <EmailNameTextDiv>{row?.fromEmail ?? ''}</EmailNameTextDiv>
        ),
        grow: false,
        size: 240,
      },
      {
        id: AdminConstants.EMAIL.TABLE_COLUMNS.BODY,
        header: t('PRIVATE.EMAILS.QUEUE_TABLE.BODY'),
        accessorFn: row => (
          <BodySectionContainer>
            <StyledSpan>{`${row?.subject ?? ''} `}</StyledSpan>
            <DescriptionSpan>{row?.body ?? ''}</DescriptionSpan>
          </BodySectionContainer>
        ),
        grow: true,
      },
      {
        id: AdminConstants.EMAIL.TABLE_COLUMNS.RECEIVED_AT,
        accessorKey: AdminConstants.EMAIL.TABLE_COLUMNS.RECEIVED_AT,
        header: t('PRIVATE.EMAILS.QUEUE_TABLE.RECEIVED_AT'),
        accessorFn: row => (
          <BodyTextStyle>{row?.received_at ?? ''}</BodyTextStyle>
        ),
        grow: false,
        size: 200,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const changeEmailsTableName = () => {
    const newEmailsTableName = generateRandomTableName(
      AdminConstants.EMAIL.EMAIL_TABLE_NAME,
    );
    setEmailsTableState(
      produce(draft => {
        draft.fetchEmailsDataFnQueryKeyName = newEmailsTableName;
      }),
    );
  };

  const cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const fetchData = async (pageParam: number, fetchSize: number) => {
    cancelTokenSource.current = axios.CancelToken.source();

    setIsFetchingEmails(true);
    try {
      const currentOffset = pageParam * fetchSize;
      const payload: GetAllEmailQueryParams = {
        limit: 50,
        offset: currentOffset,
      };

      if (caseIdQueryValue) {
        payload.searchKey = AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.CASE_ID;
        payload.searchValue = caseIdQueryValue;
        dispatch(
          setEmailSearchAndFilterValues({
            data: {
              searchKey: AdminConstants.QUEUE_AI.QUEUE_AI_TABLE.CASE_ID,
              searchValue: caseIdQueryValue,
            },
          }),
        );
      }

      if (
        emailSearchAndFilterOptions !== undefined &&
        Object.entries(emailSearchAndFilterOptions).length > 0
      ) {
        payload.searchKey = emailSearchAndFilterOptions.searchKey as string;
        payload.searchValue = emailSearchAndFilterOptions.searchValue as string;
      }

      const getAllEmailsApiResponse = await getAllEmails(
        payload,
        cancelTokenSource.current,
      );
      if (
        getAllEmailsApiResponse?.data?.count &&
        totalRowsCount !== getAllEmailsApiResponse.data.count
      )
        setTotalRowsCount(getAllEmailsApiResponse.data.count);
      const formattedEmails = getAllEmailsApiResponse.data?.rows.map(row =>
        mapEmailDataToEmailsColumnsType(row),
      );
      setNetworkErrorState(false);
      return formattedEmails;
    } catch (errorResponse: any) {
      const errorMessage = getErrorMessage(errorResponse);
      if (
        errorResponse.code === ERROR_CONSTANTS.ERR_NETWORK ||
        errorResponse.statusCode === 500
      ) {
        setNetworkErrorState(true);
      }
      enqueueSnackbar(errorMessage, SnackBarConfig.getError());
    } finally {
      setIsFetchingEmails(false);
      cancelTokenSource.current = null;
    }
  };

  useEffect(() => {
    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel();
      }
    };
  }, []);

  const navigateToEmailDetailsWithId = (emailId = '-1') => {
    const emailDetailsNavigationURL =
      PrivateNavigationRoutes.Admins.ChildRoutes.Emails.ChildRoutes.EmailDetails.FullPath.replace(
        ':emailMessageId',
        emailId,
      );
    navigate(emailDetailsNavigationURL);
  };

  // search filter dropdown starts

  const handleFilterIconClick = () => {
    setEmailsTableState(
      produce(draft => {
        draft.showSearchAndFilterDropdown = !draft.showSearchAndFilterDropdown;
      }),
    );
  };

  const handleSearchAndFilterDialogCloseBtnClick = () => {
    setEmailsTableState(
      produce(draft => {
        draft.showSearchAndFilterDropdown = false;
      }),
    );
  };

  const handleSearchAndFilterDialogApplyBtnClick = (data: any) => {
    if (caseIdQueryValue) {
      navigate(
        PrivateNavigationRoutes.Admins.ChildRoutes.Emails.ChildRoutes
          .EmailsTable.FullPath,
      );
    }
    if (data.searchValue) {
      dispatch(setEmailSearchAndFilterValues({ data: data }));
      changeEmailsTableName();
    }

    executeAfterTimeout(() => {
      setEmailsTableState(
        produce(draft => {
          draft.showSearchAndFilterDropdown =
            !draft.showSearchAndFilterDropdown;
        }),
      );
    }, 100);
  };

  const handleSearchAndFilterDialogClearBtnClick = () => {
    if (caseIdQueryValue) {
      navigate(
        PrivateNavigationRoutes.Admins.ChildRoutes.Emails.ChildRoutes
          .EmailsTable.FullPath,
      );
    }
    if (
      emailSearchAndFilterOptions !== undefined &&
      emailSearchAndFilterOptions?.searchValue
    ) {
      dispatch(setEmailSearchAndFilterValues({ data: {} }));
      changeEmailsTableName();
    }

    executeAfterTimeout(() => {
      setEmailsTableState(
        produce(draft => {
          draft.showSearchAndFilterDropdown =
            !draft.showSearchAndFilterDropdown;
        }),
      );
    }, 100);
  };

  const renderSearchAndFilterDialog = () => {
    const dialogTitle = t('ADMIN.EMAIL.SEARCH_AND_FILTER_DIALOG_TITLE');
    return (
      <SearchAndFilterDialog
        open={emailsTableState.showSearchAndFilterDropdown}
        dialogTitle={dialogTitle}
        handleCloseBtnClick={handleSearchAndFilterDialogCloseBtnClick}
        handleApplyFilterClick={data =>
          handleSearchAndFilterDialogApplyBtnClick(data)
        }
        handleClearFilterClick={handleSearchAndFilterDialogClearBtnClick}
        configuration={EMAILS_TABLE_SEARCH_AND_FILTER_OPTIONS_CONFIG}
      />
    );
  };

  // search and filter dropdown ends

  return (
    <Box>
      <TopBarWrapperGrid height="5rem">
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={1.5}>
            <EmailTextDiv>{t('PRIVATE.EMAILS.EMAILS')}</EmailTextDiv>
          </Grid>
          <Grid
            container
            item
            xs={1}
            justifyContent={'flex-end'}
            onClick={handleFilterIconClick}
          >
            <FilterDropdownWithBadgeCount isBadgeCountRequired={false} />
          </Grid>
        </Grid>
      </TopBarWrapperGrid>

      {networkErrorState ? (
        <DataNotFoundDiv>{t('NO_DATA_FOUND')}</DataNotFoundDiv>
      ) : (
        <ServerSideDataGrid
          fetchDataFnQueryKeyName={
            emailsTableState.fetchEmailsDataFnQueryKeyName
          }
          columns={columns}
          fetchDataFn={fetchData}
          dataFetchSize={AdminConstants.EMAIL.EMAIL_TABLE_DATA_LIMIT}
          enableRowSelection={false}
          enableSortingRemoval={false}
          enableSorting={false} // Disable sort icons from column header
          totalRowsCount={totalRowsCount}
          getRowId={row => row?.id}
          muiTableBodyRowProps={({ row }) => ({
            // implement row selection click events manually
            onClick: () => {
              navigateToEmailDetailsWithId(row?.id);
            },
          })}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection, isLoading: isFetchingEmails }}
          enableGlobalFilter
        />
      )}
      {renderSearchAndFilterDialog()}
    </Box>
  );
};

export default EmailsTable;
