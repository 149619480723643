import {
  CancelButtonStyled,
  SaveButtonStyled,
} from '@components/dialog/form-dialog/FormDialogStyle';
import styled from '@emotion/styled';
import { DialogActions } from '@mui/material';
export const SaveButtonWidthStyled = styled(SaveButtonStyled)`
  width: 15.5rem;
`;
export const CancelButtonWidthStyled = styled(CancelButtonStyled)`
  width: 15.5rem;
`;
export const DialogActionsContainerStyled = styled(DialogActions)`
  justify-content: center;
  padding-top: 1rem;
`;
export const TextArea = styled.textarea`
  width: 31.5rem;
  resize: vertical;
  overflow: auto;
  min-height: 3.5rem;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 0.3rem;
`;
export const FlagReasonDropdownLoaderContainerDivStyled = styled('div')`
  position: absolute;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
