import { ReactElement, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import {
  ActionButtonGroupStyled,
  ActionIconButtonStyled,
  ButtonGroupContainerStyled,
  ButtonGroupStyled,
  ButtonSubTextStyled,
  ButtonTextStyled,
  DividerStyled,
  DotStyled,
  GridColumnStyled,
  LeftButtonGroupDiv,
  LoaderContainer,
  NoUserFound,
  RightButtonGroupDiv,
  TextButtonStyled,
  TypeHeaderStyled,
} from './AssignmentCategoryStyle';
import SettingsSvgIcon from '@images/AssignmentSettings.svg';
import PlusIcon from '@images/Plus.svg';

import { QueuePriorityTypes } from '@constants/global-constants/constants';
import { APP } from 'src/styles/variables';
import QueuePUBucketAddUsers from '../../queue-pu-bucket-add-users/QueuePUBucketAddUsers';
import QueuePUBucketSettings from '../../queue-pu-bucket-settings/QueuePUBucketSettings';
import { produce } from 'immer';
import {
  GetTasksCountSummaryByUsersForTaskCategoryIdQueryParams,
  GetTasksCountSummaryByUsersForTaskCategoryIdRow,
} from 'src/services/service-handlers/private/queue-handlers/QueueServiceMapper';
import { getErrorMessage } from '@utils/ErrorUtils';
import SnackBarConfig from '@components/snackbar/SnackbarConfig';
import { useSnackbar } from 'notistack';
import {
  getTasksCountSummaryByTaskCategoryId,
  getTasksCountSummaryByUsersForTaskCategoryId,
} from 'src/services/service-handlers/private/queue-handlers/QueuePrivateService';
import { AdminConstants } from '@constants/admin-constants/AdminConstants';
import CustomUserDataCard from './custom-user-data-card/CustomUserDataCard';
import Loader from '@components/loaders/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/Store';
import { setURLWithParams } from '@utils/SetURLWithParamsUtils';
import { PrivateNavigationRoutes } from '@constants/RouteConstants';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  setRefreshTaskCountSummaryForCategory,
  setQueueSearchAndFilterOptions,
} from '@modules/home/state/queue/QueueSlice';
import {
  FormattedSearchAndFilterOptionsType,
  ProfilePicRefType,
} from 'src/types/Types';
import axios, { CancelTokenSource } from 'axios';

interface AssignmentCategoryProps {
  categoryName: string;
  taskCategoryId: string;
  fetchedProfilePicRef: React.MutableRefObject<ProfilePicRefType>;
  setFetchProfilePicRef: (userId: string) => void;
}

interface IAssignmentsCategoryState {
  isTaskCountSummaryByUsersForCategoryLoading: boolean;
  isMoreTaskCountSummaryByUsersForCategoryLoading: boolean;
  isTaskCountSummaryLoading: boolean;
  taskCountSummaryByUsersForCategoryList: GetTasksCountSummaryByUsersForTaskCategoryIdRow[];
  usersTaskCountSummaryPageParam: number;
  usersTaskCountSummaryTotalCount: number;
  taskCountSummary: {
    unassigned: number;
    high: number;
    medium: number;
    low: number;
  };
}

const initialAssignmentsCategoryState: IAssignmentsCategoryState = {
  isTaskCountSummaryByUsersForCategoryLoading: false,
  isMoreTaskCountSummaryByUsersForCategoryLoading: false,
  isTaskCountSummaryLoading: false,
  taskCountSummaryByUsersForCategoryList: [],
  usersTaskCountSummaryPageParam: 0,
  usersTaskCountSummaryTotalCount: 0,
  taskCountSummary: {
    unassigned: 0,
    high: 0,
    medium: 0,
    low: 0,
  },
};

const AssignmentCategory = (props: AssignmentCategoryProps): ReactElement => {
  const {
    categoryName,
    taskCategoryId,
    fetchedProfilePicRef,
    setFetchProfilePicRef,
  } = props;
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [assignmentsCategoryState, setAssignmentsCategoryState] =
    useState<IAssignmentsCategoryState>(initialAssignmentsCategoryState);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { queueSearchAndFilterOptions, refreshTaskCountSummaryForCategory } =
    useSelector((state: RootState) => state.queue);

  let cancelTokenSourceForTaskCountSummaryByCategoryId: CancelTokenSource | null =
    null;

  // API section starts

  const getTaskCountSummaryByCategoryId = async () => {
    cancelTokenSourceForTaskCountSummaryByCategoryId =
      axios.CancelToken.source();

    setAssignmentsCategoryState(
      produce(draft => {
        draft.isTaskCountSummaryLoading = true;
      }),
    );
    try {
      const getTaskCountSummaryByTaskCategoryIdResponse =
        await getTasksCountSummaryByTaskCategoryId(
          taskCategoryId,
          cancelTokenSourceForTaskCountSummaryByCategoryId,
        );
      const taskCountSummaryFromAPI =
        getTaskCountSummaryByTaskCategoryIdResponse?.data ?? {
          categoryId: '',
          unassigned: 0,
          high: 0,
          medium: 0,
          low: 0,
        };
      setAssignmentsCategoryState(
        produce(draft => {
          draft.taskCountSummary = taskCountSummaryFromAPI;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
      return undefined;
    } finally {
      setAssignmentsCategoryState(
        produce(draft => {
          draft.isTaskCountSummaryLoading = false;
        }),
      );
      cancelTokenSourceForTaskCountSummaryByCategoryId = null;
    }
  };

  let cancelTokenSourceForTaskCountSummaryByUserForCategory: CancelTokenSource | null =
    null;

  const getTaskCountSummaryByUsersForCategory = async (
    isBeginning = false,
    resetOffset = false,
  ) => {
    cancelTokenSourceForTaskCountSummaryByUserForCategory =
      axios.CancelToken.source();

    isBeginning
      ? setAssignmentsCategoryState(
          produce(draft => {
            draft.isTaskCountSummaryByUsersForCategoryLoading = true;
          }),
        )
      : null;
    try {
      const payloadData: GetTasksCountSummaryByUsersForTaskCategoryIdQueryParams =
        {
          limit: AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT,
          offset: resetOffset
            ? 0
            : assignmentsCategoryState.usersTaskCountSummaryPageParam *
              AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT,
        };
      if (
        typeof queueSearchAndFilterOptions?.assignedUserId === 'string' &&
        queueSearchAndFilterOptions?.assignedUserId?.length > 2
      ) {
        payloadData.userId = queueSearchAndFilterOptions.assignedUserId;
      }

      if (
        queueSearchAndFilterOptions?.nsaState ===
        AdminConstants.USERS.NSA.toLowerCase()
      ) {
        payloadData.stateNsa = AdminConstants.USERS.NSA.toLowerCase();
      } else if (
        typeof queueSearchAndFilterOptions?.nsaState === 'string' &&
        queueSearchAndFilterOptions?.nsaState === 'state'
      ) {
        payloadData.stateNsa =
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE;
      } else if (
        typeof queueSearchAndFilterOptions?.nsaState === 'string' &&
        queueSearchAndFilterOptions?.nsaState !== 'state' &&
        queueSearchAndFilterOptions?.nsaState !==
          AdminConstants.USERS.NSA.toLowerCase() &&
        queueSearchAndFilterOptions?.nsaState !== ''
      ) {
        payloadData.stateId = queueSearchAndFilterOptions.nsaState;
        payloadData.stateNsa =
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE;
      }

      const getTaskCountSummaryByUsersForCategoryResponse =
        await getTasksCountSummaryByUsersForTaskCategoryId(
          taskCategoryId,
          payloadData,
          cancelTokenSourceForTaskCountSummaryByUserForCategory ?? undefined,
        );

      setAssignmentsCategoryState(
        produce(draft => {
          draft.taskCountSummaryByUsersForCategoryList =
            getTaskCountSummaryByUsersForCategoryResponse?.data?.rows ?? [];
          draft.usersTaskCountSummaryTotalCount =
            getTaskCountSummaryByUsersForCategoryResponse?.data?.count ?? 0;
          draft.usersTaskCountSummaryPageParam += 1;
        }),
      );
    } catch (errorResponse) {
      const errMessage = getErrorMessage(errorResponse);
      enqueueSnackbar(errMessage, SnackBarConfig.getError());
    } finally {
      isBeginning
        ? setAssignmentsCategoryState(
            produce(draft => {
              draft.isTaskCountSummaryByUsersForCategoryLoading = false;
            }),
          )
        : null;
      cancelTokenSourceForTaskCountSummaryByUserForCategory = null;
    }
  };

  const loadMoreDataForTaskCountSummaryByUserForCategory = async () => {
    const offset =
      assignmentsCategoryState.usersTaskCountSummaryPageParam *
      AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.USERS_DATA_LIMIT;
    if (offset >= assignmentsCategoryState.usersTaskCountSummaryTotalCount) {
      return null;
    } else {
      setAssignmentsCategoryState(
        produce(draft => {
          draft.isMoreTaskCountSummaryByUsersForCategoryLoading = true;
        }),
      );
      await getTaskCountSummaryByUsersForCategory();
      setAssignmentsCategoryState(
        produce(draft => {
          draft.isMoreTaskCountSummaryByUsersForCategoryLoading = false;
        }),
      );
    }
  };

  // API section ends

  const init = async () => {
    await getTaskCountSummaryByCategoryId();
    if (refreshTaskCountSummaryForCategory) {
      setAssignmentsCategoryState(
        produce(draft => {
          draft.usersTaskCountSummaryPageParam = 0;
        }),
      );
      dispatch(setRefreshTaskCountSummaryForCategory({ data: false }));
      await getTaskCountSummaryByUsersForCategory(true, true);
    } else {
      await getTaskCountSummaryByUsersForCategory(true);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueSearchAndFilterOptions]);

  useEffect(() => {
    return () => {
      if (cancelTokenSourceForTaskCountSummaryByCategoryId) {
        cancelTokenSourceForTaskCountSummaryByCategoryId.cancel();
      }
      if (cancelTokenSourceForTaskCountSummaryByUserForCategory) {
        cancelTokenSourceForTaskCountSummaryByUserForCategory.cancel();
      }
    };
  }, [
    cancelTokenSourceForTaskCountSummaryByCategoryId,
    cancelTokenSourceForTaskCountSummaryByUserForCategory,
  ]);

  const refreshTaskCountSummaryByUserForCategory = async () => {
    await getTaskCountSummaryByCategoryId();
    await getTaskCountSummaryByUsersForCategory(true, true);
  };

  useEffect(() => {
    if (refreshTaskCountSummaryForCategory) {
      setAssignmentsCategoryState(
        produce(draft => {
          draft.usersTaskCountSummaryPageParam = 0;
        }),
      );
      dispatch(setRefreshTaskCountSummaryForCategory({ data: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTaskCountSummaryForCategory]);

  // Footer loader for virtuoso
  const Footer = () => {
    return assignmentsCategoryState.isMoreTaskCountSummaryByUsersForCategoryLoading ? (
      <LoaderContainer>
        <Loader size={30} />
      </LoaderContainer>
    ) : null;
  };

  const handlePriorityButtonClick = (priority: string) => {
    const queryParamsList = [];

    if (priority === AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.UNASSIGNED) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATUS,
        queryParamValue: priority,
      });
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .TASK_CATEGORY,
        queryParamValue: taskCategoryId,
      });
    }

    if (priority !== AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.UNASSIGNED) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .TASK_PRIORITY,
        queryParamValue: priority,
      });
    }

    if (taskCategoryId) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .TASK_CATEGORY,
        queryParamValue: taskCategoryId,
      });
    }

    if (
      queueSearchAndFilterOptions?.assignedUserId &&
      typeof queueSearchAndFilterOptions?.assignedUserId === 'string'
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES
            .ASSIGNED_USER,
        queryParamValue: queueSearchAndFilterOptions.assignedUserId,
      });
    }

    if (
      queueSearchAndFilterOptions?.nsaState ===
      AdminConstants.USERS.NSA.toLowerCase()
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE_NSA,
        queryParamValue: queueSearchAndFilterOptions.nsaState,
      });
    } else if (
      typeof queueSearchAndFilterOptions?.nsaState === 'string' &&
      queueSearchAndFilterOptions?.nsaState === 'state'
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE_NSA,
        queryParamValue: queueSearchAndFilterOptions.nsaState,
      });
    } else if (
      typeof queueSearchAndFilterOptions?.nsaState === 'string' &&
      queueSearchAndFilterOptions?.nsaState !== 'state' &&
      queueSearchAndFilterOptions?.nsaState !==
        AdminConstants.USERS.NSA.toLowerCase() &&
      queueSearchAndFilterOptions?.nsaState !== ''
    ) {
      queryParamsList.push({
        queryParamName:
          AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.QUERY_PARAM_NAMES.STATE_NSA,
        queryParamValue: queueSearchAndFilterOptions.nsaState,
      });
    }

    const formattedSearchAndFilterOptions: { [key: string]: string | boolean } =
      queryParamsList.reduce((acc, queryParam) => {
        acc[queryParam.queryParamName] = queryParam.queryParamValue;
        return acc;
      }, {} as FormattedSearchAndFilterOptionsType);

    dispatch(
      setQueueSearchAndFilterOptions({
        data: formattedSearchAndFilterOptions,
      }),
    );

    const newUrl = setURLWithParams(
      null,
      queryParamsList,
      PrivateNavigationRoutes.Admins.ChildRoutes.Queue.ChildRoutes.QueueTable
        .FullPath,
    );

    navigate(newUrl);
  };

  const renderPriorityButtons = () => {
    return (
      <ButtonGroupContainerStyled>
        <LeftButtonGroupDiv>
          <ButtonGroupStyled aria-label="Basic button group" color="inherit">
            <TextButtonStyled
              onClick={() =>
                handlePriorityButtonClick(
                  AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.UNASSIGNED,
                )
              }
            >
              <ButtonTextStyled>
                {QueuePriorityTypes.UN_ORDERED}
              </ButtonTextStyled>
              <ButtonSubTextStyled>
                {assignmentsCategoryState.isTaskCountSummaryLoading ? (
                  <Loader size={10} />
                ) : (
                  assignmentsCategoryState.taskCountSummary.unassigned
                )}
              </ButtonSubTextStyled>
            </TextButtonStyled>
            <DividerStyled orientation="vertical" />
            <TextButtonStyled
              onClick={() =>
                handlePriorityButtonClick(
                  AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.HIGH,
                )
              }
            >
              <ButtonTextStyled>{QueuePriorityTypes.HIGH}</ButtonTextStyled>
              <ButtonSubTextStyled>
                {assignmentsCategoryState.isTaskCountSummaryLoading ? (
                  <Loader size={10} />
                ) : (
                  assignmentsCategoryState.taskCountSummary.high
                )}
              </ButtonSubTextStyled>
            </TextButtonStyled>
            <DividerStyled orientation="vertical" />
            <TextButtonStyled
              onClick={() =>
                handlePriorityButtonClick(
                  AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID
                    .MEDIUM,
                )
              }
            >
              <ButtonTextStyled>{QueuePriorityTypes.MEDIUM}</ButtonTextStyled>
              <ButtonSubTextStyled>
                {assignmentsCategoryState.isTaskCountSummaryLoading ? (
                  <Loader size={10} />
                ) : (
                  assignmentsCategoryState.taskCountSummary.medium
                )}
              </ButtonSubTextStyled>
            </TextButtonStyled>
            <DividerStyled orientation="vertical" />
            <TextButtonStyled
              onClick={() =>
                handlePriorityButtonClick(
                  AdminConstants.QUEUE.QUEUE_ASSIGNMENTS.TABLE_HEADERS_ID.LOW,
                )
              }
            >
              <ButtonTextStyled>{QueuePriorityTypes.LOW}</ButtonTextStyled>
              <ButtonSubTextStyled>
                {assignmentsCategoryState.isTaskCountSummaryLoading ? (
                  <Loader size={10} />
                ) : (
                  assignmentsCategoryState.taskCountSummary.low
                )}
              </ButtonSubTextStyled>
            </TextButtonStyled>
          </ButtonGroupStyled>
        </LeftButtonGroupDiv>
        <RightButtonGroupDiv>
          <ActionButtonGroupStyled aria-label="Basic button group">
            <ActionIconButtonStyled
              style={{
                backgroundColor: showSettingsDialog
                  ? APP.PALETTE.TABS.MAIN
                  : 'transparent',
              }}
              onClick={() => {
                setShowSettingsDialog(true);
              }}
            >
              <img src={SettingsSvgIcon} alt="Settings" />
            </ActionIconButtonStyled>
            <ActionIconButtonStyled
              style={{
                backgroundColor: showAddUserDialog
                  ? APP.PALETTE.TABS.MAIN
                  : 'transparent',
              }}
              onClick={() => {
                setShowAddUserDialog(true);
              }}
            >
              <img src={PlusIcon} alt="plus" />
            </ActionIconButtonStyled>
          </ActionButtonGroupStyled>
        </RightButtonGroupDiv>
      </ButtonGroupContainerStyled>
    );
  };

  const handleCancel = () => {
    setShowSettingsDialog(false);
    setShowAddUserDialog(false);
  };

  const renderSettingsDialog = () => {
    return (
      <QueuePUBucketSettings
        isDialogOpen={showSettingsDialog}
        handleCancel={handleCancel}
        categoryName={categoryName}
        taskCategoryId={taskCategoryId}
        refreshTaskCountSummaryByUserForCategory={
          refreshTaskCountSummaryByUserForCategory
        }
      />
    );
  };

  const renderAddUserDialog = () => {
    return (
      <QueuePUBucketAddUsers
        isDialogOpen={showAddUserDialog}
        handleCancel={handleCancel}
        taskCategoryId={taskCategoryId}
        refreshTaskCountSummaryByUserForCategory={
          refreshTaskCountSummaryByUserForCategory
        }
      />
    );
  };

  const renderUserDataCard = (
    userData: GetTasksCountSummaryByUsersForTaskCategoryIdRow,
    index: number,
  ) => {
    return (
      <CustomUserDataCard
        key={`${userData?.userId ?? index}`}
        userData={userData}
        taskCategoryId={taskCategoryId}
        fetchedProfilePicRef={fetchedProfilePicRef}
        setFetchProfilePicRef={setFetchProfilePicRef}
      />
    );
  };

  const renderVirtualizedUserDataCards = () => {
    return assignmentsCategoryState.taskCountSummaryByUsersForCategoryList
      .length > 0 ? (
      <Virtuoso
        style={{ height: 'calc(100vh - 12.5rem)' }}
        data={assignmentsCategoryState.taskCountSummaryByUsersForCategoryList}
        endReached={loadMoreDataForTaskCountSummaryByUserForCategory}
        overscan={200}
        itemContent={(index, userData) => {
          return renderUserDataCard(userData, index);
        }}
        components={{ Footer }}
      />
    ) : (
      <NoUserFound>
        {t('ADMIN.QUEUE_TABS.ASSIGNMENTS_TAB.NO_USER_FOUND')}
      </NoUserFound>
    );
  };

  const renderAssignmentColumnBody = () => {
    return (
      <>
        {renderPriorityButtons()}
        {assignmentsCategoryState.isTaskCountSummaryByUsersForCategoryLoading ||
        assignmentsCategoryState.isTaskCountSummaryLoading ? (
          <LoaderContainer>
            <Loader size={30} />
          </LoaderContainer>
        ) : (
          renderVirtualizedUserDataCards()
        )}
      </>
    );
  };

  return (
    <GridColumnStyled item xs={12} sm={12} md={12} lg={12} xl={12}>
      <TypeHeaderStyled>
        <DotStyled />
        {categoryName}
      </TypeHeaderStyled>

      {renderAssignmentColumnBody()}

      {showSettingsDialog && renderSettingsDialog()}
      {showAddUserDialog && renderAddUserDialog()}
    </GridColumnStyled>
  );
};

export default AssignmentCategory;
